import React, { Component } from 'react'
import { Card, Row, Col } from 'react-bootstrap'

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <React.Fragment>
                <footer>
                    <Card >
                        <Card.Body>
                            <Row style={{ marginBottom: 5 }}>
                                <Col>
                                    <p className='text-14 mb-0'>
                                        Copyright &copy; 2020 PT. Wilsum Kapital Global - <a style={{color: '#000'}} rel='noreferrer' target='_blank' href='#'>Persyaratan</a> dan <a style={{color: '#000'}} rel='noreferrer' target='_blank' href='#'>Kebijakan privasi &amp; cookie</a>
                                    </p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </footer>
            </React.Fragment>
        );
    }
}

export default index;