const defaultState = {
  registAuthenticated: false,
  registRequest: false,
  error: "",
};
  
  const authReducer = (state = defaultState, action) => {
    switch (action.type) {
      case "REGIST_REQUEST":
        return {
          registAuthenticated: false,
          registRequest: true
        };
  
      case "REGIST_REQUEST_FAILED":
        return {
          registAuthenticated: false,
          error: action.payload.message
        };
  
      case "REGIST_REQUEST_SUCCESS":
        return {
          registAuthenticated: true,
          ...action.payload
        };
      case "AUTH_LOGOUT":
        return {
            defaultState
        };
  
      default:
        return state;
    }
  };
  
  export default authReducer;
  