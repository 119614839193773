const defaultState = {
    isLoading: false,
    error: "",
    isLogout: true
  };
  
  const myCourseReducer = (state = defaultState, action) => {
    switch (action.type) {
      case "MY_COURSE_REQUEST":
        return {
          isLoading: false,
        };
  
      case "MY_COURSE_REQUEST_FAILED":
        return {
          isLoading: false,
          error: action.payload.message
        };
  
      case "MY_COURSE_REQUEST_SUCCESS":
        return {
          isLoading: true,
          data: action.payload.data.data,
          pagination: action.payload.data
        };
      case "AUTH_LOGOUT":
        return {
            defaultState
        };
      break;
      default:
        return state;
    }
  };
  
  export default myCourseReducer;
  