const defaultState = {
    isAuthenticated: false,
    authRequest: false,
    error: "",
    isLogout: true
  };
  
  const authReducer = (state = defaultState, action) => {
    switch (action.type) {
      case "AUTH_REQUEST":
        return {
          isAuthenticated: false,
          authRequest: true
        };
  
      case "AUTH_REQUEST_FAILED":
        return {
          isAuthenticated: false,
          error: action.payload
        };
  
      case "AUTH_REQUEST_SUCCESS":
        return {
          isAuthenticated: true,
          ...action.payload
        };
  
        case "AUTH_LOGOUT":
        return {
            defaultState
        };
  
      default:
        return state;
    }
  };
  
  export default authReducer;
  