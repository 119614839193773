import React, { Component } from 'react';
import { Image, Accordion, Card, Form } from 'react-bootstrap';
import ChevronUp from '../../../assets/icon/chevron-up-black.png'
import { Container, Row, Col, Input, FormGroup, Label, Spinner } from 'reactstrap'; 
import ModuleItem from './ModuleItem'

const CourseModule = (props) => {
    const {details} = props  
    return (
        <Col sm="4" style={{ padding: 0 }}>
            <div className="content-crs-title">Evaluasi Kursus</div>
            <Accordion
                defaultActiveKey={
                    details.sections ? details.sections.length > 0 ? (
                        details.sections[0]._id
                    ) : (
                        ''
                    ) : (
                        ''
                    )
                }
            >
                {details.sections ? (
                    details.sections.map((sec, id) => (
                         <ModuleItem details={details} id={id} key={id} {...props} data={sec}/>
                    ))
                ) : null}
            </Accordion>
            {
                details.is_done == true && details.link_certificate &&
                <a href={details.link_certificate} target="_BLANK">
                    <button className='btn-warning btn mt-4' style={{width : '80%'}}>
                        Print Certificate
                    </button>
                </a>
            }
     
        </Col>
    )
}

export default CourseModule