import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Container, Row, Col, Card, Form, Image, Button} from 'react-bootstrap'
import { Input, Spinner } from 'reactstrap'
import swal from 'sweetalert';
import qs from 'qs'
import moment from 'moment'
import 'moment/locale/id'

import success from '../assets/icon/ic_success.png'
import Loading from '../components/Modal/loading'
import cancel from '../assets/icon/ic_cancel.png'
import clock from '../assets/icon/ic_clock.png'
import { authLogout } from '../store/actions/auth'
import numberWithComma from '../utils/numberWithComma'
import { getProfile, changeProfile } from '../store/actions/profile'
import { getTransactionDetail} from '../store/actions/checkout'
import Nav from '../components/nav'


moment.locale('id')

class HistoryDetail extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        this.state = { 
            transaction: {},
            vouchers: null
         };
    }

    componentDidMount() {
        let { cookies, match: {params}, history } = this.props
        let cookie = cookies.getAll()
        this.setState({loading: true})
        if( cookie.hasOwnProperty('access_token')) {
            this.props.getTransactionDetail(params.id, {Authorization: 'Bearer ' + cookie.access_token})
                .then(res => {
                    console.log(res, 'transaction');
                    if(res.data) {
                        this.setState({transaction: res.data.data.transaction, vouchers: res.data.data.voucher, loading: false})
                    } else {
                        swal({
                            title: 'Failed',
                            text: 'please check your connection or try again later',
                            icon: 'error'
                        })
                        .then(res => {
                            history.push('/')
                        })
                    }
                    
                })
        } else {
            history.push('/')
        }
    }
    render() {
        let { transaction, vouchers, loading } = this.state
        return (
            <> 
                <Nav/>
                <React.Fragment>
                    <Loading isOpen={loading}/>
                    <div className='container mt-2 mb-2'>
                        <div style={{ backgroundImage: `url(${require('../assets/images/background.png')})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                            <Card style={{ borderRadius: '15px', padding: 0 }} className='bq-light'>
                                <Row style={{margin: 0}}>
                                    <Col xs='12' style={{textAlign: 'left', color: '#4A4A4A'}}>
                                        <h3 className='mt-3' style={{ color: '#4A4A4A', marginBottom: '1em' }}>Detail Transaksi - {transaction.created_at ? moment(transaction.created_at).format('LL') : ''}</h3>
                                        <Row style={{margin: 0, display:'flex', flexWrap: 'wrap', alignItems: 'flex-end'}}>
                                            <Col sm='5'>
                                                <span style={{color: '#a2afc5', fontSize: '.7em', fontWeight: 'bold'}}>TANGGAL TRANSAKSI</span>
                                                <p style={{color: '#000', fontSize: '.9em', marginBottom: 0}}>{transaction.created_at ? moment(transaction.created_at).format('llll') : ''}</p>
                                            </Col>

                                            <Col sm='3'>
                                                <span style={{color: '#a2afc5', fontSize: '.7em', fontWeight: 'bold'}}>KODE TRANSAKSI</span>
                                                <p style={{color: '#000', fontSize: '.9em', marginBottom: 0}}>{transaction.trans_code ? transaction.trans_code : transaction._id}</p>
                                            </Col>
                                            {
                                                vouchers !== null ? (
                                                    <Col sm='4'>
                                                        <span style={{color: '#a2afc5', fontSize: '.7em', fontWeight: 'bold'}}>VOUCHER</span>
                                                        <p style={{color: '#000', fontSize: '.9em', marginBottom: 0}}>{vouchers[0].voucher_code}</p>
                                                    </Col>
                                                ) : null
                                            }
                                            
                                            <Col xs='12'>
                                                <hr style={{backgroundColor: '#E8EBF0', marginTop: '2em'}}/>
                                            </Col>
                                        </Row>
                                        <Row style={{margin: 0, display:'flex', flexWrap: 'wrap', alignItems: 'flex-end', marginBottom: '2em'}}>
                                            <Col xs='12' style={{color: '#7B828F', fontSize: '.9em'}}>Payment</Col>
                                            <Col sm='5'>
                                                <span style={{color: '#a2afc5', fontSize: '.7em', fontWeight: 'bold'}}>METODE PEMBAYARAN</span>
                                                <p style={{color: '#000', fontSize: '.9em', marginBottom: 0}}>{transaction.payment_type} {transaction.bank ? (transaction.bank !== "" ? `(${transaction.bank})` : (transaction.bank !== null ? `(${transaction.bank})` : '')) : ''}</p>
                                            </Col>

                                            <Col sm='3'>
                                                <span style={{color: '#a2afc5', fontSize: '.7em', fontWeight: 'bold'}}>TOTAL PEMBAYARAN</span>
                                                <p style={{color: '#000', fontSize: '.9em', marginBottom: 0}}>Rp {numberWithComma(transaction.total_paid ? transaction.total_paid : 0)}</p>
                                            </Col>
                                            <Col sm='4'>
                                                <span style={{color: '#a2afc5', fontSize: '.7em', fontWeight: 'bold'}}>BATAS PEMBAYARAN</span>
                                                <p style={{color: '#000', fontSize: '.9em', marginBottom: 0}}>{moment(transaction.time_limit ? transaction.time_limit : new Date()).format('llll')}</p>
                                            </Col>
                                        </Row>
                                        <Row style={{margin: 0, padding: '1em 0', display: 'flex', alignItems:'flex-end', backgroundColor: '#edf0f5'}}>
                                            <Col sm='6' xs='6'>
                                                <p style={{color: '#7B828F', fontSize: '.8em', marginBottom: 0, fontWeight: 'bold'}}>PAYMENT STATUS</p>                                        
                                            </Col>
                                            <Col sm='6' xs='6' style={{textAlign: 'right'}}>
                                                <p style={{color: '#000', fontSize: '12px', marginBottom: 0, verticalAlign: 'middle'}}>
                                                    <img src={transaction.status ? (
                                                        transaction.status == 'success' ? success : (
                                                            transaction.status == 'awaiting payment' ? clock : cancel
                                                        )
                                                    ) : ''} style={{width: '5%', marginRight: '5px'}}/> {transaction.status}
                                                </p>
                                            </Col>
                                        </Row>

                                        <h5 style={{ color: '#4A4A4A', marginBottom: '1em', lineHeight:'1em', marginTop: '2em' }}>Detail Pesanan</h5>
                                        {
                                            transaction.details ? (
                                                transaction.details.map((trans,id) => (
                                                    <Row style={{margin: 0, display:'flex', flexWrap: 'wrap', alignItems: 'flex-end'}}>
                                                        <Col sm='2'>
                                                            <img src={trans.courses.course_image_small} className="w-100 mb-3"/>
                                                        </Col>
                                                        <Col sm='5'>
                                                            <p style={{color: '#000', marginBottom: 0}}>{trans.courses.course_title}</p>
                                                            <span style={{color: '#a2afc5', fontSize: '.8em', fontWeight: 'bold'}}>By {trans.courses.authors_id.details.firstname + trans.courses.authors_id.details.lastname}</span>
                                                        </Col>
                                                        <Col sm='5' style={{textAlign: 'right'}}>
                                                            <p style={{color: '#000',fontSize: '1.1em', marginBottom: 0, fontWeight: 'bold'}}>Rp {numberWithComma(trans.courses.course_price)}</p>
                                                        </Col>
                                                        <Col sm='12'>
                                                            <hr style={{backgroundColor: '#E8EBF0', marginTop: '1em'}}/>
                                                        </Col>
                                                    </Row>
                                                ))
                                            ) : null
                                        }
                                        

                                        <Row style={{margin: 0, padding: '.5em 0', display: 'flex', alignItems:'flex-end', marginBottom: '2em'}}>
                                            {/* Subtotal */}
                                            <Col xs='6'>
                                                <p style={{color: '#7B828F', fontSize: '.9em', marginBottom: 0}}>Subtotal</p>                                        
                                            </Col>
                                            <Col xs='6' style={{textAlign: 'right'}}>
                                                <p style={{color: '#000',fontSize: '1.1em', marginBottom: 0, fontWeight: 'bold'}}>Rp {numberWithComma(transaction.total_price ? transaction.total_price : 0)}</p>
                                            </Col>

                                            {/* Total Fee */}
                                            <Col xs='6'>
                                                <p style={{color: '#7B828F', fontSize: '.9em', marginBottom: 0}}>Total Fee</p>                                        
                                            </Col>
                                            <Col xs='6' style={{textAlign: 'right'}}>
                                                <p style={{color: '#000',fontSize: '1.1em', marginBottom: 0, fontWeight: 'bold'}}>Rp {numberWithComma(transaction.total_fee ? transaction.total_fee : 0)}</p>
                                            </Col>

                                            {/* Total Discount */}
                                            <Col xs='6'>
                                                <p style={{color: '#7B828F', fontSize: '.9em', marginBottom: 0}}>Total Discount</p>                                        
                                            </Col>
                                            <Col xs='6' style={{textAlign: 'right'}}>
                                                <p style={{color: '#000',fontSize: '1.1em', marginBottom: 0, fontWeight: 'bold'}}>Rp {numberWithComma(transaction.total_discount ? transaction.total_discount : 0)}</p>
                                            </Col>

                                            <Col xs='12'>
                                                <hr style={{backgroundColor: '#E8EBF0', marginTop: '1em'}}/>
                                            </Col>

                                            {/* Total */}
                                            <Col xs='6'>
                                                <p style={{color: '#7B828F', marginBottom: 0}}>Total</p>                                        
                                            </Col>
                                            <Col xs='6' style={{textAlign: 'right'}}>
                                                <p style={{color: '#000',fontSize: '1.1em', marginBottom: 0, fontWeight: 'bold'}}>Rp {numberWithComma(transaction.total_paid ? transaction.total_paid : 0)}</p>
                                            </Col>


                                                    
                                        {
                                            transaction.status == 'awaiting payment' &&
                                            <Col>
                                                <button class="buy-btn float-right mb-4">Lanjutkan Pembayaran</button> 
                                            </Col>
                                        }
                                        </Row> 

                                        <hr/>
                                        <p style={{fontSize: '.8em'}}>Jika Anda memiliki pertanyaan mengenai tanda terima ini, silakan hubungi <span style={{color: '#FF5216'}}>tim dukungan kami.</span></p>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    </div>
                </React.Fragment>
            </>
        );
    }
}

const WrappedCookies = withCookies(HistoryDetail)

const mapStateToProps = state => {
  return {
    Profile: state.Profile
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getTransactionDetail }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedCookies);