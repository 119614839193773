import React, { Component } from 'react'
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { bindActionCreators } from "redux";
import { instanceOf } from 'prop-types';
import swal from 'sweetalert'
import { withCookies, Cookies } from 'react-cookie';
import { Container, Row, Col, Input , Button} from 'reactstrap'; 

import numberWithComma from '../../utils/numberWithComma'
import { getCourse } from '../../store/actions/course'
import { getMyCourse } from '../../store/actions/my-course' 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/style/pages/homepage.css'; 
import HttpCourse from '../../stores/course/httpCall'
import Nav from '../../components/nav'
import Skeleton from 'react-loading-skeleton'

class index extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            page : 1, 
            per_page : 10,
            search : '',
            list : null,
            pagination : null,
            price_range:null,
            price_start : null,
            price_end : null,
            loading : false,
            price_options : [
                {
                    "index" : 0,
                    "name" : "Free",
                    "price_start" : 0,
                    "price_end" : 0
                },
                {
                    "index" : 1,
                    "name" : "50.000 - 500.000",
                    "price_start" : 50000,
                    "price_end" : 500000
                },
                {
                    "index" : 2,
                    "name" : "500.000 - 1.000.000",
                    "price_start" : 500000,
                    "price_end" : 1000000
                },
                {
                    "index" : 3,
                    "name" : "> 1.000.000",
                    "price_start" : 1000000,
                    "price_end" : 1000000000000
                }
            ]
        }
    }

    componentDidMount(){
        this.getCourse()
    }
    
    getCourse = async () => {
        this.setState({loading : true})
        const {search,page,per_page,price_start,price_end} = this.state 
        const params = {}
        params.search = search
        params.page = page
        params.per_page = per_page

        if(price_start != null) params.price_start = price_start
        if(price_end != null)   params.price_end = price_end

        const call = await HttpCourse.httpGetCourse(params)
        if(call && call.success && call.success && call.success.data.status == 200){
            this.setState({
                list : call.success.data.data.course,
                pagination : call.success.data.data.pagination,
            })
        }
        this.setState({loading : false}) 
    }

    onChangePriceRange = async (item) => {
        this.setState({
            price_start : item.price_start ,
            price_end : item.price_end,
            price_range : item
        },()=>{
            this.getCourse()
        })
    }

    makeSlug = (id, title) => {
        let titleArr = title.split(' ')
        let titleStr = titleArr.join('_')
        let slug = `${id}-${titleStr}`
        return slug
    }

    renderCourseItem(item){
        return(<>
                    <div className="text-left rounded"
                        onClick={() => this.props.history.push(`/course/${this.makeSlug(item._id, item.course_title)}`)}
                        style={{
                            border : '0.3px solid #F8F8F8'
                        }}
                    >
                        {/* <div className='rounded' alt='course' width={'100%'} src={item.course_image_medium} />  */}
                        <div className='rounded' style={{
                              backgroundImage: `url("${item.course_image_medium}")`,
                              minHeight : 150,
                              backgroundPosition: 'center',
                              backgroundSize: 'cover'
                        }}/>
                        <div className='p-2'>
                            <div className='mt-2'>
                                <p className="text-bold">{item.course_title}</p>
                                <p className='card-little-text' style={{marginTop:0, paddingTop: 0}}>by {item.authors_id && `${item.authors_id.details.firstname} ${item.authors_id.details.lastname}`}</p> 
                            </div>
                            <div className='text-right'>
                                <p className="mb-0">{item.is_free_access ? 'Free' : 'Rp ' + numberWithComma(item.course_price)}</p>
                                <Link to={`/course/${this.makeSlug(item._id, item.course_title)}`}>
                                    <button className='btn btn-warning mt-4 btn-block'>{item.is_free_access ? 'Klaim sekarang' : 'Beli sekarang'}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
            </>)
    }

    generatePagination(pagination){
        const {page} = this.state
        const list = []
        
        for(var i = 1; i<= pagination.last_page ; i++){
            let _page = i
            list.push(<li onClick={()=>this.setState({page : _page},() => this.getCourse())} class="page-item"><a class={`page-link ${page == _page ? 'bg-warning' : 'bg-light' }`} href="#">{_page}</a></li> )
        }

        return(
            <ul className="mb-4 pagination" style={{position : 'absolute', bottom : 0, width : '80%'}}>
                {
                    pagination && pagination.current_page > 1 &&
                        <li class="page-item">
                            <a onClick={()=>this.setState({page : page-1},() => this.getCourse())} class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span>
                            </a>
                        </li> 
                }
             
                {
                    list
                }
                {
                      pagination && pagination.last_page > pagination.current_page  &&
                        <li class="page-item">   
                            <a onClick={()=>this.setState({page : page+1},() => this.getCourse())} class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Next</span>
                            </a> 
                        </li>
                }
                
            </ul> 
        )
    }

    render() { 
        const {list,search,price_options,price_range,loading,pagination} = this.state

        return (
            <>
                <Nav/>
                <React.Fragment> 
                    <Container style={{marginTop : 100}}>
                        <Row className='mb-4'>
                            <h2 className='text-left p-0 m-0 block w-100'>Cari kursus berkualitas denga mudah</h2> 
                            {
                                search &&  <p className='mt-2'>Hasil pencarian untuk <strong>"{search}"</strong></p>
                            }
                            
                        </Row>
                        <Row className='mt-4'>
                            <div className='col-md-3 p-0' style={{borderRight : '1px solid #F2F2F2'}}>
                                <div className='rounded pr-4'>
                                    <div className='d-flex justify-content'>
                                        <div class="input-group mb-3">
                                            <input onChange={(dat) => this.setState({search  : dat.target.value })} type="text" class="form-control mr-1" placeholder="Search course..." aria-describedby="button-addon2"/>
                                            <button onClick={()=>this.getCourse()} class="btn btn-outline-secondary" type="button" id="button-addon2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>  
                                    <hr/>
                                    <h3 className='text-left'>Price</h3>
                                    <div className='pl-4'>
                                        <div class="form-group text-left" onClick={()=>this.setState({price_range : null,price_start : null, price_range : null},()=>this.getCourse())}>
                                            <input class="form-check-input" type="radio" checked={price_range == null ? true : false} value={'all'} name={'price_range'}/>
                                            <label class="form-check-label" for="flexCheckDefault">
                                               All
                                            </label>
                                        </div>
                                        {
                                            price_options && price_options.map((item,index) =>{
                                                return(
                                                    <div class="form-group text-left" onClick={()=>this.onChangePriceRange(item)}>
                                                        <input class="form-check-input" type="radio" checked={price_range && price_range.index == index ? true : false} value={index} name={'price_range'}/>
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            {item.name}
                                                        </label>
                                                    </div>
                                                )
                                            })
                                        } 
                                    </div>
                                    <hr/>
                                    <h3 className='text-left'>Author</h3>
                                    <div className='pl-4'> 
                                        <div class="form-group text-left">
                                            <input class="form-check-input" checked={true} type="radio" name={'author'} value="1" id=""/>
                                            <label class="form-check-label"  >
                                                All author
                                            </label>
                                        </div> 
                                        <div class="form-group text-left" onClick={()=>alert('Coming soon')}>
                                            <input disabled class="form-check-input" type="radio" name={'author'} value="" id=""/>
                                            <label class="form-check-label text-grey" for="flexCheckDefault">
                                                Verified author
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-9 overflow-auto' style={{height : '100vh'}}>
 
                                 <div className='row'>

                                     {
                                         loading && 
                                           <>
                                           {
                                               [1,2,3,4,5,6].map(()=>(
                                                    <Col md={4} className='mb-4'>
                                                        <Skeleton
                                                            speed={2}
                                                            width={'100%'}
                                                            height={250}
                                                            viewBox="0 0 400 460"
                                                            backgroundColor="#f3f3f3"
                                                            foregroundColor="#ecebeb" 
                                                            >
                                                            <circle cx="31" cy="31" r="15" /> 
                                                            <rect x="58" y="18" rx="2" ry="2" width="140" height="10" /> 
                                                            <rect x="58" y="34" rx="2" ry="2" width="140" height="10" /> 
                                                            <rect x="0" y="60" rx="2" ry="2" width="400" height="400" />
                                                        </Skeleton>
                                                    </Col>
                                               ))
                                           }
                                           </>
                                     }
                                  
                                    {
                                        !loading && list && list.map((item,index) => {
                                            return <Col md={4} className='mb-4' key={index}>{this.renderCourseItem(item)}</Col> 
                                        })
                                    } 
                                 </div>
                                    
                                    {
                                        pagination && this.generatePagination(pagination)
                                    }
                                     
                            </div>
                        </Row>
                    </Container>
                </React.Fragment>
            </>

        );
    }
}

const WrappedCookies = withCookies(index)

const mapStateToProps = state => {
  return {
    Auth: state.Auth,
    Course: state.Course,
    MyCourse: state.MyCourse,
    Profile: state.Profile
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getCourse, getMyCourse }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedCookies)
