import axios from "axios";

const api_url = process.env.REACT_APP_API_URL;

export const getProvince = params => async dispatch => {
  dispatch(LocationOnRequest());

  try {
    const request = await axios.get(api_url + "/api/province", {params});
    
    return dispatch(ProvinceRequestSuccess(request.data));
  } catch (err) {
    return dispatch(LocationRequestFailed(err));
  }
};

export const getCity = (params, props) => async dispatch => {
    dispatch(LocationOnRequest());
  
    try {
      const request = await axios.get(api_url + "/api/city", {params});
      console.log(props);
      return dispatch(CityRequestSuccess({...props, city: request.data.data}));
    } catch (err) {
      return dispatch(LocationRequestFailed(err));
    }
  };

export const getDistrict = (params, props) => async dispatch => {
    dispatch(LocationOnRequest());

    try {
        const request = await axios.get(api_url + "/api/district", {params});
        
        
        return dispatch(districtRequestSuccess({...props, district: request.data.data}));
    } catch (err) {
        return dispatch(LocationRequestFailed(err));
    }
};


export const LocationOnRequest = () => {
    return {
      type: "LOCATION_REQUEST",
    };
  };
  
export const LocationRequestFailed = err => ({
    type: "COURSE_REQUEST_FAILED",
    payload: err,
});
  
export const ProvinceRequestSuccess = payload => ({
    type: "PROVINCE_REQUEST_SUCCESS",
    payload,
});

export const CityRequestSuccess = payload => ({
    type: "CITY_REQUEST_SUCCESS",
    payload,
});

export const districtRequestSuccess = payload => ({
    type: "DISTRICT_REQUEST_SUCCESS",
    payload,
});