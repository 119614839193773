import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Container, Row, Col, Card, Form, Image, Button} from 'react-bootstrap'
import { Input, Spinner } from 'reactstrap'
import swal from 'sweetalert';
import qs from 'qs'

import Menu from '../components/Auth/menu'
import Loading from '../components/Modal/loading'
import { authLogout } from '../store/actions/auth'
import Background from '../assets/images/background.png'
import DefaultImg from '../assets/images/default.png'
import { getProfile, changeProfile } from '../store/actions/profile'
import { getProvince, getCity, getDistrict } from '../store/actions/location'
import Nav from '../components/nav'

class ProfileStatic extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        this.state = { 
            loading: false
         };
    }

    componentDidMount(){
        let { Profile, getProvince, cookies, history } = this.props
        let cookie = cookies.getAll();
        if( cookie.hasOwnProperty('access_token')) {
            this.setState({loading: true})
        } else {
            history.push('/')
        }
        if(Profile.data) {
            this.setState({loading: false})
        }
        
    }

    componentDidUpdate(prevProps) {
        let { Profile } = this.props
        if(Profile.doneGet !== prevProps.Profile.doneGet) { 
            if(Profile.data) {
                this.setState({loading: false})
            }
        }
    }
    render() {
        let { Profile} = this.props
        return (
            <>
                <Nav/>
                <React.Fragment>
                    <Loading isOpen={this.state.loading}/>
                    <Container className="mt-2">
                        <Row>
                            <Col sm='12'>
                                <div style={{ backgroundColor: '#fff', backgroundImage: `url(${Background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundBlendMode: 'saturation'}}>
                                    <Card style={{ borderRadius: '15px', padding: 0 }} className='bq-light'>
                                        <Row style={{margin: 0}}>
                                            <Menu/>
                                            <Col sm='8' className="text-left pt-3">
                                                <Row style={{margin: 0}}>
                                                    <Col sm='3' xs='2' >
                                                        <Image className="profile-picture" src={Profile.data ? (Profile.data.details.profile_picture_path ? Profile.data.details.profile_picture_path : DefaultImg) : DefaultImg} roundedCircle />
                                                    </Col>
                                                    <Col sm='9' xs='10'>
                                                        <h3 className="text-bold text-18">{Profile.data ? (Profile.data.details.firstname +  ' ' + Profile.data.details.lastname) : '-'}</h3>
                                                        {
                                                            Profile.data ? (
                                                                <div className="badge-wrapper" style={{
                                                                    width: '100%',
                                                                    display: 'flex',
                                                                    flexWrap: 'wrap',
                                                                    alignItems: 'center'
                                                                }}>
                                                                    {
                                                                        Profile.data.details.job_title ? (
                                                                            <div className="badge-profile">
                                                                                {Profile.data.details.job_title}
                                                                            </div>
                                                                        ) : null
                                                                    }
                                                                    {
                                                                        Profile.data.details.language ? (
                                                                            <div className="badge-profile">
                                                                                {Profile.data.details.language}
                                                                            </div>
                                                                        ) : null
                                                                    }
                                                                    {
                                                                        Profile.data.details.expertise ? (
                                                                            <div className="badge-profile">
                                                                                {Profile.data.details.expertise}
                                                                            </div>
                                                                        ) : null
                                                                    }
                                                                    
                                                                </div>
                                                            ) : null
                                                        }
                                                        
                                                        <div style={{margin: '1em 0 .3em 0'}}>
                                                            <p style={{fontSize: '.9em'}}>{Profile.data ? Profile.data.details.profile_description : '-'}</p>
                                                        </div>
                                                        <button style={{
                                                            backgroundColor: '#009194',
                                                            color: '#fff',
                                                            border: 0,
                                                            borderRadius: '50px',
                                                            boxShadow: '0  17px 10px -10px rgba(0,0,0,0.1)',
                                                            padding: '.5em 0',
                                                            width: '150px',
                                                            marginTop: '.2em'
                                                        }} onClick={() => this.props.history.push('/profile/edit')}>
                                                            Edit
                                                        </button>
                                                    </Col>
                                                    <Col xs='12'>
                                                        <hr/>
                                                    </Col>
                                                    <Col xs='3' >
                                                        <p>Phone</p>
                                                        <p>Email</p>
                                                    </Col>
                                                    <Col xs='9' >
                                                        <p>{Profile.data ? Profile.data.details.phone : '08XXXXXXXX'}</p>
                                                        <p>{Profile.data ? Profile.data.email : 'xxxxxxx@mail.com'}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </React.Fragment>
            </>
        );
    }
}

const WrappedCookies = withCookies(ProfileStatic)

const mapStateToProps = state => {
  return {
    Profile: state.Profile,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getProfile }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedCookies);