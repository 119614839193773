import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { instanceOf } from 'prop-types';
import { withRouter } from 'react-router-dom'
import { withCookies, Cookies } from 'react-cookie';
import { Modal, ModalBody, Input, Spinner, InputGroup } from 'reactstrap';

import ForgotPass from './ForgotPass'
import { doAuth  } from "../../../store/actions/auth";
import { getMyCourse  } from "../../../store/actions/my-course";
import { getProfile  } from "../../../store/actions/profile";
import eye from '../../../assets/icon/eye.png'
import eye_slash from '../../../assets/icon/eye-slash.png'
import logo from './../../../assets/images/logo.png'

class Signin extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = { 
            email: '',
            password: '',
            message: '',
            loading: false,
            forgotPass: false,
            errorList: [],
            passValid: true,
            seePass: false
        };
    }

    componentDidUpdate(prevProps) {
        const { Auth } = this.props;
        if (prevProps.Auth.authRequest !== Auth.authRequest) {
            this.setState({loading: true})
        }

        if (prevProps.Auth.isAuthenticated !== Auth.isAuthenticated ) {
            this.setState({loading: false})
            const { cookies, history } = this.props;
            if(Auth.data) {
                cookies.set('access_token', Auth.data.access_token, { expires: new Date(Auth.data.expires_in)});
                this.props.getMyCourse({
                    Authorization: `Bearer ${Auth.data.access_token}`
                })
                this.props.getProfile({
                    Authorization: `Bearer ${Auth.data.access_token}`
                })
                .then(res => {
                    
                    
                    if(res.type == 'PROFILE_REQUEST_FAILED') {
                        cookies.remove('access_token')
                    } else {
                        let profile = res.payload.data
                        if(profile.status.name == "Verified") {
                            this.props.history.push('/profile/edit')
                        }
                    }
                })
            }
            this.props.toggle()
        }
        if (!prevProps.Auth.error && Auth.error) {
            let error = Auth.error.response
            if(error.data.data === 'User Not Verify'){
                this.setState({
                    message: error.data.data,
                    loading: false
                });
            } else {
                this.setState({
                    message: "Email atau Password salah!",
                    loading: false
                });
            }
            
        }
        
    }

    signin = event => {
        event.preventDefault()
        let { email, password, errorList } = this.state
        this.setState({message: ''})
        let reqBody = {
            email,
            password
        }
        if(email !== "" && password !== '') {
            if(password.length >= 8) {
                this.props.doAuth(reqBody)
                .then(res => {
                    
                })
            } else {
                let pass = errorList
                pass.push('password')
                this.setState({errorList: pass, passValid: false})
            }
        } else {
            let list = errorList
            if(email == "") {errorList.push('email')}
            if(password == "" || password.length < 8) {errorList.push('password')}
            if(password.length < 8) {this.setState({passValid: true})}
            this.setState({errorList: list})
        }
        
    }

    onChangeInput = event => {
        this.setState({
          [event.target.name]: event.target.value,
        });
    };

    forgotToggle = event => {
        if(event) {
            event.preventDefault()
            this.setState({forgotPass: !this.state.forgotPass})
        } else {
            this.setState({forgotPass: false})
        }
    }

    openSignin = () => {

        this.setState({forgotPass: false}, () => this.props.openSigninonly())
    }

    render() {
        let { isOpen, toggle, signUp} = this.props
        let { email, password, message, forgotPass, errorList, passValid, seePass } = this.state
        return (
            <React.Fragment>
                <ForgotPass isOpen={forgotPass} toggle={this.forgotToggle} openingSignin={this.openSignin}/>
                <Modal isOpen={isOpen} toggle={toggle} size='md' centered>
                    <ModalBody>
                        <p className='text-center pt-4 pb-3'><img src={logo} alt='kerjah' /></p>
                        <p className='text-center text-24'>Sign in</p>
                        <div>
                            <p className='text-danger text-center'>{message}</p>
                            <form className='auth-form login-form' onSubmit={this.signin}>
                                <div style={{marginBottom: '1em', width: '85%'}}>
                                    <Input 
                                        name='email'
                                        placeholder='Email' 
                                        type='text'
                                        style={{ 
                                            border: `1px solid ${errorList.includes('email') ? 'red' : '#4A4A4A' }` , 
                                            width: '100%'}} 
                                        value={email}
                                        onChange={this.onChangeInput}/>                                    
                                        {
                                            errorList.includes('email') ? (<span className='validation-message'>Email field is required</span>) : null
                                        }
                                </div>
                                
                                <div style={{marginBottom: '1em', width: '85%'}}>
                                    <div
                                        className='has-border-bottom'
                                        style={{
                                        display: 'flex',
                                        width: '100%',
                                        alignItems: 'center',
                                        borderBottom: `1px solid ${errorList.includes('password') ? 'red' : 'none' }`}}>
                                      <Input 
                                        name='password'
                                        placeholder='Password' 
                                        type={seePass ? 'text' : 'password'} 
                                        style={{
                                            marginBottom: '-1px',
                                            width: '100%', 
                                            padding: '.2em 1em',
                                            backgroundColor: 'transparent'}}
                                        value={password}
                                        onChange={this.onChangeInput}/>
                                        <img alt='see password' src={!seePass ? eye_slash : eye} style={{width: '20px'}} onClick={() => this.setState({seePass: !seePass})}/>  
                                    </div>
                                    
                                    {
                                        errorList.includes('password') ? ( 
                                        passValid ? (<span style={{color: 'red', fontSize: '.8em'}}>Password field is required</span>) : (<span style={{color: 'red', fontSize: '.8em'}}>Password length must be 8 or more</span>)
                                        ) : null
                                    }
                                </div>
                                
                                <button className='login-button' type='submit' disabled={this.state.loading}>Masuk
                                    {
                                        this.state.loading ? (
                                            <>
                                                <Spinner className='loading-spinner' type="grow" color="light" />
                                                <Spinner className='loading-spinner' type="grow" color="light" />
                                                <Spinner className='loading-spinner' type="grow" color="light" />
                                            </>
                                        ) : null
                                    }
                                </button>
                            </form>
                            

                            <p className='text-center py-3 mb-0'>atau <span style={{color: '#009194', cursor: 'pointer'}} onClick={() => this.setState({forgotPass: !forgotPass}, () => toggle())}>Lupa Sandi</span></p>
                        </div>
                        <p className='text-center'>Tidak punya akun? <span style={{color: '#009194', fontWeight: 'bold', cursor: 'pointer'}} onClick={signUp}>Daftar</span></p>
                    </ModalBody>
                </Modal>
            </React.Fragment>
            
        );
    }
}

const WrappedCookies = withCookies(Signin)

const mapStateToProps = state => {
  return {
    Auth: state.Auth,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ doAuth, getProfile, getMyCourse }, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedCookies));
