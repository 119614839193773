import React, {
    Component
} from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Container, Row, Col, Card, Form, Image, Button} from 'react-bootstrap'
import { Input, Spinner } from 'reactstrap'
import swal from 'sweetalert';
import qs from 'qs'

import Menu from '../components/Auth/menu'
import Loading from '../components/Modal/loading'
import { authLogout } from '../store/actions/auth'
import { getProfile, changeProfile } from '../store/actions/profile'
import { getProvince, getCity, getDistrict } from '../store/actions/location'
import Nav from '../components/nav'

class myprofile extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    
    constructor(props) {
        super(props);
        this.state = {
            salutation: 'Mr',
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            province: '',
            city: '',
            district: '',
            zip: '',
            language: '',
            expertise: '',
            job: '',
            description: '',
            photo: '',
            loading: false,
            validationErrors : []
        };
    }

    componentDidMount() {
        let { Profile, getProvince, cookies, history } = this.props
        let cookie = cookies.getAll();
        this.setState({loading: true})
        if( cookie.hasOwnProperty('access_token')) {
             getProvince()
             .then(res => {
                 this.setState({loading: false})
             })
        } else {
            history.push('/')
        }
        if(Profile.data) {
            this.setState({
                email: Profile.data.email,
                salutation: Profile.data.details.salutation,
                firstname: Profile.data.details.firstname,
                lastname: Profile.data.details.lastname,
                phone: Profile.data.details.phone,
                province: Profile.data.details.provinces_id ? Profile.data.details.provinces_id : '',
                city: Profile.data.details.cities_id ? Profile.data.details.cities_id : '',
                district: Profile.data.details.districts_id ? Profile.data.details.districts_id : '',
                zip: Profile.data.details.zip_code ? Profile.data.details.zip_code : '',
                language: Profile.data.details.language ? Profile.data.details.language : '',
                expertise: Profile.data.details.expertise ? Profile.data.details.expertise : '',
                job: Profile.data.details.job_title ? Profile.data.details.job_title : '',
                description: Profile.data.details.profile_description ? Profile.data.details.profile_description : '',
                photo: Profile.data.details.profile_picture_path ? Profile.data.details.profile_picture_path : ''
            })
        } 
        
    }

    componentDidUpdate(prevProps) {
        let { Profile } = this.props
        if(Profile.doneGet !== prevProps.Profile.doneGet) {  
            if(Profile.data) {
                this.setState({
                    email: Profile.data.email,
                    salutation: Profile.data.details.salutation,
                    firstname: Profile.data.details.firstname,
                    lastname: Profile.data.details.lastname,
                    phone: Profile.data.details.phone,
                    province: Profile.data.details.provinces_id ? Profile.data.details.provinces_id : '',
                    city: Profile.data.details.cities_id ? Profile.data.details.cities_id : '',
                    district: Profile.data.details.districts_id ? Profile.data.details.districts_id : '',
                    zip: Profile.data.details.zip_code ? Profile.data.details.zip_code : '',
                    language: Profile.data.details.language ? Profile.data.details.language : '',
                    expertise: Profile.data.details.expertise ? Profile.data.details.expertise : '',
                    job: Profile.data.details.job_title ? Profile.data.details.job_title : '',
                    description: Profile.data.details.profile_description ? Profile.data.details.profile_description : '',
                    photo: Profile.data.details.profile_picture_path ? Profile.data.details.profile_picture_path : ''
                })
            }          
            this.setState({loading: false})
        }
    }

    onChangeInput = event => {        
        this.setState({
          [event.target.name]: event.target.value,
        });        
        if(event.target.name == 'province') {
            this.props.getCity({provinces_id: event.target.value}, {...this.props.Location})
        } if (event.target.name == 'city') {
            this.props.getDistrict({cities_id: event.target.value}, {...this.props.Location})
        }
    };

    addImage = event => {
        console.log(event)

        event.preventDefault()
        this.getBase64(event.target.files[0], (result) => {
            this.setState({photo: result})
            
       });
        
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    ChangeProfile = async (event) => {
        event.preventDefault()
        let { Profile, cookies } = this.props
        let {
            salutation,
            firstname,
            lastname,
            email,
            phone,
            province,
            city,
            district,
            zip,
            language,
            expertise,
            job,
            description,
            photo
        } = this.state
        let cookie = cookies.getAll()
        let empty = false
        let emptyField = []
        this.setState({loading: true})

        if(phone.length < 10){
            const old_validationErrors = this.state.validationErrors
            old_validationErrors.phone =  ['The phone must be at least 10 characters.']
            this.setState({loading: false})
            this.setState({validationErrors : old_validationErrors})
            emptyField.push('phone') 
            return
        }

        for(let key in this.state) {
            if(key !== 'district' && this.state[key] == '' && key !== 'loading' && key != 'validationErrors') {
                empty = true 
                emptyField.push(key)
            }
        }
        if(!empty) {
            
            let body = {
                users_id: Profile.data._id,
                salutation,
                firstname,
                lastname,
                email,
                phone,
                zip_code: zip,
                language,
                expertise,
                job_title: job,
                profile_description: description,
                profile_picture_path: photo,
                provinces_id: province,
                cities_id: city,
                districts_id: district,
            }
            let headers = {
                Authorization: `Bearer ${cookie.access_token}`,
                'Accept' : 'application/json',
                'Content-Type': 'application/json'
            }

        await this.props.changeProfile(JSON.stringify(body), headers)
            .then(res => {
                this.setState({loading: false})
                if(res.type == "CHANGE_PROFILE_REQUEST_SUCCESS") {
                    swal({
                        title: "Success",
                        text: "Your profile has been sucessfully update!",
                        icon: "success",
                    })
                    this.props.history.push('/profile')
                } else {
                    
                    if(res.type == 'CHANGE_PROFILE_REQUEST_FAILED'){
                        if(res.payload && res.payload.response && res.payload.response.data && res.payload.response.data.data){
                            this.setState({validationErrors : res.payload.response.data.data})
                        }
                    }

                    swal({
                        title: "Failed",
                        text: "There's been an error, please try again",
                        icon: "error",
                    })
                }
                this.props.getProfile({
                    Authorization: `Bearer ${cookie.access_token}`
                })
                .then(result => {
                    if(result.type == 'PROFILE_REQUEST_FAILED') {
                        cookies.remove('access_token')
                    }
                })
                
                
            })
            this.setState({loading: false})
        } else {
            this.setState({loading: false}) 
            swal({
                title: "There's empty field",
                text: `Isi ${emptyField.map(emp => ( ' ' + emp))} terlebih dahulu`,
                icon: "warning",
            })
        
        }
    }

    render() {
        let { salutation, 
            firstname, 
            lastname, 
            email, 
            phone, 
            province, 
            city, 
            district, 
            zip, 
            language,
            expertise,
            job,
            description, 
            photo,
            validationErrors
        } = this.state
        let { Location } = this.props

        // if(!email) return <>Loading . . .</>
        console.log(validationErrors)
        return (
        <>
            <Nav/>
            <React.Fragment >
                {
                    this.state.loading &&
                        <Loading isOpen={this.state.loading}/>
                }
                
                <Container className='py-4'>
                    <Row>
                        <Col xs='12' className='text-left'>
                            <h3 style={{ color: '#4A4A4A' }}>Profil Saya</h3>
                            <p style={{ fontSize: '14px', color: '#969696' }}>Lengkapi informasi tentang diri Anda</p>
                            <form onSubmit={(data)=>this.ChangeProfile(data)}>
                                <Row className='mb-4'>
                                    <Col xs='6' md='2' >
                                        <span style={{ fontSize: '.9em', color: '#4A4A4A', marginBottom: '10px', lineHeight: 2 }}>Titel <span style={{color: 'red'}}>*</span></span>
                                        <Input 
                                            name='salutation'
                                            type='select'
                                            value={salutation}
                                            onChange={this.onChangeInput}
                                            style={{ 
                                                width: '100%',
                                                padding: '.2em 0', 
                                                marginBottom: '1.5em',
                                                textAlign: 'center'
                                        }}>
                                            <option value="Mr">Mr.</option>
                                            <option value="Mr">Mrs.</option>
                                            <option value="Ms">Miss</option>
                                        </Input>
                                    </Col>
                                    <Col md='4' >
                                    <span style={{ fontSize: '.9em', color: '#4A4A4A', marginBottom: '10px', lineHeight: 2 }}>Nama <span style={{color: 'red'}}>*</span></span>
                                        <Input 
                                        type='text' 
                                        name='firstname'
                                        value={firstname}
                                        onChange={this.onChangeInput}
                                        style={{ 
                                            width: '100%',
                                            padding: '.2em 1em', 
                                            marginBottom: '1.5em'}}/>
                                    </Col>

                                    <Col sm='6' >
                                        <span style={{ fontSize: '.9em', color: '#4A4A4A', marginBottom: '10px', lineHeight: 2 }}>Nama Keluarga <span style={{color: 'red'}}>*</span></span>
                                        <Input 
                                        type='text' 
                                        name='lastname'
                                        value={lastname}
                                        onChange={this.onChangeInput}
                                        style={{ 
                                            width: '100%',
                                            padding: '.2em 1em', 
                                            marginBottom: '1.5em'}}/>
                                    </Col>

                                    <Col xs='6' >
                                        <span style={{ fontSize: '.9em', color: '#4A4A4A', marginBottom: '10px', lineHeight: 2 }}>Telepon <span style={{color: 'red'}}>*</span></span>
                                        <Input 
                                        type='text' 
                                        name='phone'
                                        value={phone}
                                        onChange={this.onChangeInput}
                                        min={10}
                                        style={{ 
                                            width: '100%',
                                            padding: '.2em 1em', 
                                            marginBottom: '1.5em'}}/>
                                        <span className='validation-message'>{validationErrors.phone ? validationErrors.phone[0] : ''}</span>
                                    </Col>

                                    <Col xs='6' >
                                        <span style={{ fontSize: '.9em', color: '#4A4A4A', marginBottom: '10px', lineHeight: 2 }}>Email <span style={{color: 'red'}}>*</span></span>
                                        <Input 
                                        disabled
                                        type='text' 
                                        name='email'
                                        value={email}
                                        onChange={this.onChangeInput}
                                        style={{ 
                                            width: '100%',
                                            padding: '.2em 1em', 
                                            marginBottom: '1.5em'}}/>
                                    </Col>

                                    <Col xs='6' >
                                        <span style={{ fontSize: '.9em', color: '#4A4A4A', marginBottom: '10px', lineHeight: 2 }}>Provinsi <span style={{color: 'red'}}>*</span></span>
                                        <Input 
                                            name='province'
                                            type='select'
                                            value={province}
                                            onChange={this.onChangeInput}
                                            style={{ 
                                                width: '100%',
                                                padding: '.2em 0', 
                                                marginBottom: '1.5em',
                                                textAlign: 'center'
                                        }}>
                                            <option>Provinsi</option>
                                            {
                                                Location.province ? (
                                                    Location.province.map((loc, idx) => (
                                                        <option value={loc._id} key={idx}>{loc.name}</option>
                                                    ))
                                                ) : null
                                            }
                                        </Input>
                                    </Col>

                                    <Col xs='6' >
                                        <span style={{ fontSize: '.9em', color: '#4A4A4A', marginBottom: '10px', lineHeight: 2 }}>Kota <span style={{color: 'red'}}>*</span></span>
                                        <Input 
                                            name='city'
                                            type='select'
                                            value={city}
                                            onChange={this.onChangeInput}
                                            style={{ 
                                                width: '100%',
                                                padding: '.2em 0', 
                                                marginBottom: '1.5em',
                                                textAlign: 'center'
                                        }}>
                                            <option>Kota</option>
                                            {
                                                Location.city ? (
                                                    Location.city.map((loc, idx) => (
                                                        <option value={loc._id} key={idx}>{loc.name}</option>
                                                    ))
                                                ) : null
                                            }
                                        </Input>
                                    </Col>

                                    {/* <Col xs='6' >
                                        <span style={{ fontSize: '.9em', color: '#4A4A4A', marginBottom: '10px', lineHeight: 2 }}>Kecamatan <span style={{color: 'red'}}></span></span>
                                        <Input 
                                            name='district'
                                            type='select'
                                            value={district}
                                            onChange={this.onChangeInput}
                                            style={{ 
                                                width: '100%',
                                                padding: '.2em 0', 
                                                marginBottom: '1.5em',
                                                textAlign: 'center'
                                        }}>
                                            <option>Kecamatan</option>
                                            {
                                                Location.district ? (
                                                    Location.district.map((loc, idx) => (
                                                        <option value={loc._id}>{loc.name}</option>
                                                    ))
                                                ) : null
                                            }
                                        </Input>
                                    </Col> */}

                                    <Col xs='6' >
                                        <span style={{ fontSize: '.9em', color: '#4A4A4A', marginBottom: '10px', lineHeight: 2 }}>Kode Pos <span style={{color: 'red'}}>*</span></span>
                                        <Input 
                                        type='text' 
                                        name='zip'
                                        value={zip}
                                        onChange={this.onChangeInput}
                                        style={{ 
                                            width: '100%',
                                            padding: '.2em 1em', 
                                            marginBottom: '1.5em'}}/>
                                    </Col>

                                    <Col xs='6' >
                                        <span style={{ fontSize: '.9em', color: '#4A4A4A', marginBottom: '10px', lineHeight: 2 }}>Bahasa <span style={{color: 'red'}}>*</span></span>
                                        <Input 
                                            name='language'
                                            type='text'
                                            value={language}
                                            onChange={this.onChangeInput}
                                            style={{ 
                                                width: '100%',
                                                padding: '.2em 1em', 
                                                marginBottom: '1.5em',
                                        }}/>
                                    </Col>

                                    <Col xs='6' >
                                        <span style={{ fontSize: '.9em', color: '#4A4A4A', marginBottom: '10px', lineHeight: 2 }}>Keahlian <span style={{color: 'red'}}>*</span></span>
                                        <Input 
                                        type='text' 
                                        name='expertise'
                                        value={expertise}
                                        onChange={this.onChangeInput}
                                        style={{ 
                                            width: '100%',
                                            padding: '.2em 1em', 
                                            marginBottom: '1.5em'}}/>
                                    </Col>

                                    <Col xs='6' >
                                        <span style={{ fontSize: '.9em', color: '#4A4A4A', marginBottom: '10px', lineHeight: 2 }}>Pekerjaan <span style={{color: 'red'}}>*</span></span>
                                        <Input 
                                        type='text' 
                                        name='job'
                                        value={job}
                                        onChange={this.onChangeInput}
                                        style={{ 
                                            width: '100%',
                                            padding: '.2em 1em', 
                                            marginBottom: '1.5em'}}/>
                                    </Col>

                                    <Col xs='12' >
                                        <span style={{ fontSize: '.9em', color: '#4A4A4A', marginBottom: '10px', lineHeight: 2 }}>Deskripsi <span style={{color: 'red'}}>*</span></span>
                                        <Form.Control 
                                        as="textarea" 
                                        name='description' 
                                        rows="6" 
                                        value={description}
                                        placeholder="Describe yourself" 
                                        onChange={this.onChangeInput}/>
                                    </Col>
                                </Row>  
                                <hr></hr>
                                <h3 style={{ color: '#4A4A4A' }}>Foto <span style={{color: 'red'}}>*</span></h3>
                                <p style={{ fontSize: '14px', color: '#969696' }}>Tambahkan foto terbaik Anda</p>
                                <br />
                                <Row style={{ marginBottom: '20px' }}>
                                    <Col lg="4"><span style={{ fontSize: '14px', color: '#4A4A4A' }}>Preview</span></Col>
                                    <Col lg="8">
                                        <Image width="120px" height="120px" src={photo == '' ? require('../assets/images/default.png') : photo} roundedCircle />
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: '20px' }}>
                                    <Col lg="4"><span style={{ fontSize: '14px', color: '#4A4A4A' }}>Tambah / Ubah Foto:</span></Col>
                                    <Col lg="8" style={{ fontSize: '14px' }}>
                                        <Form.File
                                            id="custom-file"
                                            name='photo'
                                            label="No file selected"
                                            data-browse="Upload Image"
                                            custom
                                            onChange={this.addImage}
                                        />
                                    </Col>
                                </Row>
                                <hr></hr>
                                <Row style={{ marginBottom: '50px', marginTop: '50px' }}>
                                    <Col xs='2'></Col>
                                    <Col lg="8" style={{ textAlign: 'center' }}>
                                        <Button type="submit" style={{ borderRadius: '10px', width: '100%', backgroundColor: '#009194', border: 'none' }}>Simpan {this.state.loading ? ( <Spinner size="sm" color="light" style={{marginLeft: '15px'}}/> ) : null}</Button>
                                    </Col>
                                </Row>
                            </form>
                            
                        </Col>
                    </Row>
                </Container>
                <style jsx>{`
                    ::-webkit-input-placeholder {
                        font-size: 13px!important;
                    }
                    
                    :-moz-placeholder { /* Firefox 18- */
                        font-size: 13px!important;
                    }
                    ::-moz-placeholder {  /* Firefox 19+ */
                        font-size: 13px!important;
                    }

                    .swal-text {
                        text-align: center
                    }
                `}</style>
            </React.Fragment> 
        </>
        );
    }
}

const WrappedCookies = withCookies(myprofile)

const mapStateToProps = state => {
  return {
    Auth: state.Auth,
    Profile: state.Profile,
    Location: state.Location
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getProfile, authLogout, getProvince, getCity, getDistrict, changeProfile }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedCookies);
