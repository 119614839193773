import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Container, Row, Col, Card, Form, Image, Button} from 'react-bootstrap'
import { Input, Spinner } from 'reactstrap'
import swal from 'sweetalert';

import Finish from '../assets/icon/finish.png'
import Failed from '../assets/icon/failed.png'
import Unfinish from '../assets/icon/unfinish.png'
import Loading from '../components/Modal/loading'
// import BackgroundImg from '../assets/images/background.png'
import Nav from '../components/nav'

const api_url = process.env.REACT_APP_API_URL;

class TransactionDone extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        this.state = { 
            status: '',
            loading: false,
            error: false,
            courseURL: ''
         };
    }

    componentDidMount() {
        this.checkPaymentCourse()
    }
  
    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    checkPaymentCourse(){
        let { location, cookies, match: {params} } = this.props
        let order_id = location.search.split('&')[0]
        let trans_code = order_id.split('=')[1]
        let cookie = cookies.getAll()
        this.setState({loading: true})
        if( cookie.hasOwnProperty('access_token')) {
            if(params.slug !== 'error') {
                axios.get(api_url + '/api/transaction?order_id=' + trans_code, {headers: {Authorization: 'Bearer ' + cookie.access_token}})
                .then(res => {
                    console.log(res, res.data.data);
                    let data = res.data.data
                    if(data !== null) {
                        if(data.status == 'awaiting payment') {
                            this.setState({loading: false, status: data.status, error: false})
                        } else if(data.status == 'cancel' || data.status == 'failed' || data.status == 'expired') {
                            this.setState({loading: false, status: data.status, error: true})
                        } else if(data.status == 'success') {
                            this.setState({loading: false, status: data.status, error: false, courseURL: `/course-enroll/${data.details[0].courses_id}`})
                            this.timer = setTimeout(function() {this.props.history.push(`/course-enroll/${data.details[0].courses_id}`)}.bind(this), 7000)
                        }
                    }
                    
                })
                .catch(err => {
                    this.setState({status: 'error'})
                    console.log(err.response);
                    
                    swal({
                        title: "Error",
                        text: err.response.data.data,
                        icon: "error",
                    })
                })
            } else {
                this.setState({status: 'error'})
            }
        } else {
            this.props.history('/')
        }      
    }

    render() {
        let { status, loading, error, courseURL } = this.state
        return (
            <>
                <Nav/>
                <React.Fragment>
                    <Loading isOpen={loading}/>
                    <div style={{ backgroundColor: '#EDEDED', padding: '2% 20%', backgroundImage: `url(${require('../assets/images/background.png')})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                        <Card style={{ borderRadius: '15px', padding: 0 }} className='bq-light'>
                            <Row style={{margin: 0}}>
                                {
                                    error ? (
                                        <Col xs='12' style={{textAlign: 'center', padding: '3em 0', color: '#4A4A4A'}}>
                                            <img src={Failed} style={{width: '20%', marginBottom: '1.5em'}}/>
                                            <h1 style={{ color: '#4A4A4A', marginBottom: '.3em' }}>Transaction {status}</h1>
                                            <p className='text-grey'>Transaksi gagal. Silahkan ulangi kembali transaksi anda.</p>
                                            <button onClick={() => this.props.history.push('/')} style={{
                                                width: '200px',
                                                border: 0,
                                                backgroundColor: '#009194',
                                                borderRadius: '5px',
                                                textAlign: 'center',
                                                padding: '.5em 0',
                                                color: '#fff'
                                            }}>
                                                Kembali ke Homepage
                                            </button>
                                        </Col>
                                    ) : (
                                        status == 'success' ? (
                                            <Col xs='12' style={{textAlign: 'center', padding: '3em 0', color: '#4A4A4A'}}>
                                                <img src={Finish} style={{width: '20%', marginBottom: '1.5em'}}/>
                                                <h1 style={{ color: '#4A4A4A', marginBottom: '.3em' }}>Transaksi Berhasil</h1>
                                                <p className='text-grey'>Transaksi kamu telah berhasil. Lanjutkan belajar kursus mu!</p>
                                                <button onClick={() => this.props.history.push(courseURL)} style={{
                                                    width: '200px',
                                                    border: 0,
                                                    backgroundColor: '#009194',
                                                    borderRadius: '5px',
                                                    textAlign: 'center',
                                                    padding: '.5em 0',
                                                    color: '#fff'
                                                }}>
                                                    Lanjutkan Belajar
                                                </button>
                                            </Col>
                                        ) : (
                                            <Col xs='12' style={{textAlign: 'center', padding: '3em 0', color: '#4A4A4A'}}>
                                                <img src={Unfinish} style={{width: '20%', marginBottom: '1.5em'}}/>
                                                <h1 style={{ color: '#4A4A4A', marginBottom: '.3em' }}>Awaiting Payment</h1>
                                                <p className='text-grey'>Lanjutkan pembayaran mu dan periksa riwayat transaksi untuk mengetahui status transaksi mu.</p>
                                                {/* <button onClick={() => this.props.history.push('/')} style={{
                                                    width: '200px',
                                                    border: 0,
                                                    backgroundColor: '#009194',
                                                    borderRadius: '5px',
                                                    textAlign: 'center',
                                                    padding: '.5em 0',
                                                    color: '#fff'
                                                }}>
                                                    Lihat Riwayat
                                                </button> */}
                                            </Col>
                                        )
                                    )
                                }
                            </Row>
                        </Card>
                    </div>
                </React.Fragment>
            </>
        );
    }
}

export default withCookies(TransactionDone);