import React, { Component } from 'react'

class Default extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (
            <React.Fragment>
                <div className='not-found-cont'>
                    {/* <img src={require('../assets/images/404.png')} style={{width: '30%'}} alt='404'/> */}

                    <button className='not-found-btn' onClick={() => this.props.history.push('/')}>
                        Kembali ke Homepage
                    </button>
                </div>
            </React.Fragment>
        );
    }
}

export default Default;