const defaultState = {
    isLoading: false,
    error: "",
    isLogout: true,
    doneGet: false
  };
  
  const profileReducer = (state = defaultState, action) => {
    switch (action.type) {
      case "PROFILE_REQUEST":
        return {
          isLoading: true,
          doneGet: false
        };
  
      case "PROFILE_REQUEST_FAILED":
        return {
          isLoading: false,
          error: action.payload.message
        };
  
      case "PROFILE_REQUEST_SUCCESS":
        return {
          isLoading: false,
          doneGet: true,
          ...action.payload
        };

      case "CHANGE_PROFILE_REQUEST_FAILED":
        return {
          isLoading: false,
          error: action.payload.message
        };
  
      case "CHANGE_PROFILE_REQUEST_SUCCESS":
        return {
          isLoading: false,
          doneGet: true,
        };

      case "AUTH_LOGOUT":
        return {
            defaultState
        };
      break;

      default:
        return state;
    }
  };
  
  export default profileReducer;
  