import React, { Component } from 'react'
import { connect } from "react-redux";
import { instanceOf } from 'prop-types';
import { bindActionCreators } from "redux";
import { Link, withRouter } from 'react-router-dom'
import { withCookies, Cookies } from 'react-cookie'
import { Col } from 'react-bootstrap'
import { authLogout } from '../../store/actions/auth'

class menu extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {  };
    }

    signOut = () => {
        let {cookies, dispatch, history} = this.props
        this.props.authLogout()
        .then(res => {
            cookies.remove('access_token')
            this.setState({success: true}, () => {
                history.push('/')
            })
        })
        .catch(err => {
            console.log(err);
            
        })
    }

    render() {
        console.log(this.props.match.path);
        
        let { match: {path} } = this.props
        return (
            <Col sm='4' style={{backgroundColor: '#EAEAEA', borderTopLeftRadius: '15px', borderBottomLeftRadius: '15px'}}>
                <div className='menu-box'>
                    <Link to='/profile' className={path == '/profile' ? 'menu-active' : 'menu-deactive'}><p className='mrg-btm-2'>Profil</p></Link>
                    <Link to='/my-course' className={path == '/my-course' ? 'menu-active' : 'menu-deactive'}><p className='mrg-btm-2'>Kursus Saya</p></Link>
                    <Link to='/history-transaction' className={path == '/history-transaction' ? 'menu-active' : 'menu-deactive'}><p className='mrg-btm-2'>Riwayat Transaksi</p></Link>
                    <Link to='/change-password' className={path == '/change-password' ? 'menu-active' : 'menu-deactive'}><p className='mrg-btm-2'>Ubah Sandi</p></Link>
                    <Link to='/payment-methods' className={path == '/payment-methods' ? 'menu-active' : 'menu-deactive'}><p className='mrg-btm-2 d-none'>Metode Pembayaran</p></Link>
                    <span onClick={this.signOut} className='lgt-menu text-danger'>Keluar</span>
                </div>
            </Col>
        );
    }
}

const WrappedCookies = withCookies(menu)

const mapStateToProps = state => {
  return {
    Auth: state.Auth,
    Profile: state.Profile,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ authLogout }, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedCookies));
