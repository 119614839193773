import React, { Component } from 'react'
import { Container, Row, Col, Progress } from 'reactstrap';
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { bindActionCreators } from "redux";
import { instanceOf } from 'prop-types';
import swal from 'sweetalert'
import { withCookies, Cookies } from 'react-cookie';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../assets/style/pages/homepage.css'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "#4A4A4A", borderRadius: '50px' }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "#4A4A4A", borderRadius: '50px' }}
            onClick={onClick}
        />
    );
}

class CoursesList extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };
        return (
            <React.Fragment>
                <div style={{ width: '100%', backgroundColor: '#FFE7D9' }}>
                    <Container style={{ textAlign: 'left', padding: '1em 0', backgroundColor: '#FFE7D9' }}>
                        <h2 style={{ fontFamily: 'Helvetica', color: '#4A4A4A' }}>Courses List</h2>
                    </Container>
                </div>

                <div style={{ width: '100%', backgroundColor: '#F7F7F7' }}>
                    <Container style={{ textAlign: 'left', padding: '2.2em 0', backgroundColor: '#F7F7F7' }}>
                        <h4 style={{ fontFamily: 'Helvetica', fontWeight: 'bold', color: '#4A4A4A' }}>Courses to get you started</h4>
                        <p style={{ color: '#969696' }}>Recommended for you</p>
                        <Slider {...settings}>
                            <div>
                                <Link>
                                    <div style={{
                                        width: '90%',
                                        backgroundColor: 'white',
                                        borderRadius: '10px',
                                        boxShadow: '5px 15px 20px #00000029',
                                        marginBottom: '2em',
                                        color: '#4A4A4A'
                                    }}>
                                        <img src={require('../assets/images/def2.jpeg')} style={{ width: '100%', height: '150px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} />
                                        <div style={{ padding: '1em',height: '140px' }}>
                                            <div style={{height: '3em'}}>
                                                <span style={{ fontWeight: 'bold', margin: 0 }}>Menjadi Guide ala Indiana Jones</span>
                                            </div>
                                            <p style={{ fontSize: '.8em' }}>by John Doe</p>

                                            <span style={{ fontSize: '.8em' }}>Free</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div>
                                <Link>
                                    <div style={{
                                        width: '90%',
                                        backgroundColor: 'white',
                                        borderRadius: '10px',
                                        boxShadow: '5px 15px 20px #00000029',
                                        marginBottom: '2em',
                                        color: '#4A4A4A'
                                    }}>
                                        <img src={require('../assets/images/def2.jpeg')} style={{ width: '100%', height: '150px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} />
                                        <div style={{ padding: '1em',height: '140px' }}>
                                            <div style={{height: '3em'}}>
                                                <span style={{ fontWeight: 'bold', margin: 0 }}>Menjadi Guide ala Indiana Jones</span>
                                            </div>
                                            <p style={{ fontSize: '.8em' }}>by John Doe</p>

                                            <span style={{ fontSize: '.8em' }}>Free</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div>
                                <Link>
                                    <div style={{
                                        width: '90%',
                                        backgroundColor: 'white',
                                        borderRadius: '10px',
                                        boxShadow: '5px 15px 20px #00000029',
                                        marginBottom: '2em',
                                        color: '#4A4A4A'
                                    }}>
                                        <img src={require('../assets/images/def2.jpeg')} style={{ width: '100%', height: '150px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} />
                                        <div style={{ padding: '1em',height: '140px' }}>
                                            <div style={{height: '3em'}}>
                                                <span style={{ fontWeight: 'bold', margin: 0 }}>Menjadi Guide ala Indiana Jones</span>
                                            </div>
                                            <p style={{ fontSize: '.8em' }}>by John Doe</p>

                                            <span style={{ fontSize: '.8em' }}>Free</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div>
                                <Link>
                                    <div style={{
                                        width: '90%',
                                        backgroundColor: 'white',
                                        borderRadius: '10px',
                                        boxShadow: '5px 15px 20px #00000029',
                                        marginBottom: '2em',
                                        color: '#4A4A4A'
                                    }}>
                                        <img src={require('../assets/images/def2.jpeg')} style={{ width: '100%', height: '150px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} />
                                        <div style={{ padding: '1em',height: '140px' }}>
                                            <div style={{height: '3em'}}>
                                                <span style={{ fontWeight: 'bold', margin: 0 }}>Menjadi Guide ala Indiana Jones</span>
                                            </div>
                                            <p style={{ fontSize: '.8em' }}>by John Doe</p>

                                            <span style={{ fontSize: '.8em' }}>Free</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </Slider>
                    </Container>
                </div>
            
                <Container style={{ textAlign: 'left', padding: '2.2em 0 4em 0' }}>
                    <h4 style={{ fontFamily: 'Helvetica', fontWeight: 'bold', color: '#4A4A4A' }}>All Travelprenure Courses</h4>
                    <p style={{ color: '#969696' }}>Not sure? Every course comes with a 30-day money-back guarantee</p>
                    <div style={{backgroundColor: '#F7F7F7', padding: '1.5em', display: 'flex', alignItems: 'center', flexWrap:'wrap', width: '100%'}}>
                        <div style={{
                            border: '1px solid #FF5B00', 
                            backgroundColor: '#fff', 
                            display: 'flex', 
                            padding: '.5em 1em',
                            alignItems: 'center', 
                            justifyContent: 'center',
                            color: '#FF5B00',
                            fontSize: '.85em',
                            marginRight: '2em'
                            }}>
                                <img src={require('../assets/icon/filter.png')} style={{width: '20%', marginRight: '10px'}}/>
                                Filter
                        </div>

                        <div style={{
                            border: '1px solid #FF5B00', 
                            backgroundColor: '#fff', 
                            display: 'flex', 
                            padding: '.5em',
                            alignItems: 'center', 
                            justifyContent: 'space-between',
                            color: '#FF5B00',
                            fontSize: '.85em',
                            marginRight: '2em'
                            }}>
                                Rating
                                <img src={require('../assets/icon/chevron-down.png')} style={{width: '20%', marginLeft: '10px'}}/>
                        </div>

                        <div style={{
                            border: '1px solid #FF5B00', 
                            backgroundColor: '#fff', 
                            display: 'flex', 
                            padding: '.5em',
                            alignItems: 'center', 
                            justifyContent: 'space-between',
                            color: '#FF5B00',
                            fontSize: '.85em',
                            marginRight: '2em'
                            }}>
                                Video Duration
                                <img src={require('../assets/icon/chevron-down.png')} style={{width: '12%', marginLeft: '10px'}}/>
                        </div>

                        <div style={{
                            border: '1px solid #FF5B00', 
                            backgroundColor: '#fff', 
                            display: 'flex', 
                            padding: '.5em 1em',
                            alignItems: 'center', 
                            justifyContent: 'center',
                            color: '#FF5B00',
                            fontSize: '.85em',
                            marginRight: '2em'
                            }}>
                                <img src={require('../assets/icon/sort.png')} style={{width: '20%', marginRight: '10px'}}/>
                                Sort
                        </div>
                    </div>

                    <div style={{
                        padding: '2em 0',
                        borderBottom: '2px solid #F7F7F7'
                    }}>
                        <Row style={{margin: 0}}>
                            <Col xs='2' style={{padding: 0, margin: 0}}>
                                <img src={require('../assets/images/def2.jpeg')} style={{width:'90%', borderRadius: '10px'}}/>
                            </Col>
                            <Col xs='6' style={{padding: 0, margin: 0}}>
                                <p style={{fontWeight: 'bold', color: '#4A4A4A', marginBottom: 0}}>Cara menjadi travel assistance profesional</p>
                                <span style={{color: '#4A4A4A'}}>by John Doe</span>
                            </Col>
                            <Col xs='3' style={{textAlign: 'right', paddingTop: '2em'}}>
                                <h5 style={{ fontFamily: 'Helvetica', fontWeight: 'bold', color: '#4A4A4A' }}>Rp 350.000</h5>
                            </Col>
                            <Col xs='1'></Col>
                        </Row>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

export default CoursesList;