import React, { Component } from 'react'
import { connect } from "react-redux";
import { instanceOf } from 'prop-types';
import { Link } from 'react-router-dom';
import { bindActionCreators } from "redux";
import Pagination from 'react-js-pagination'
import { withCookies, Cookies } from 'react-cookie';

import { Container, Row, Col, Card}  from 'react-bootstrap'
import swal from 'sweetalert';

import Menu from '../components/Auth/menu'
import Loading from '../components/Modal/loading'
import { authLogout } from '../store/actions/auth'
import { getProfile } from '../store/actions/profile'
import { getMyCourse } from '../store/actions/my-course'
import Nav from '../components/nav'

class MyCourse extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = { 
            pagination: {
                page: 1,
                total_page: 1,
                per_page: 1,
                total: 1,
                total_counting: 1
            },
            loading: false
         };
    }

    componentDidMount() {
        let {cookies, history} = this.props
        let cookie = cookies.getAll();
        this.setState({loading: true})
        if( cookie.hasOwnProperty('access_token')) {
            this.props.getMyCourse({
                Authorization: `Bearer ${cookie.access_token}`
            })
            .then(res => {
                if(res.payload.data) {
                    let temp = this.state.pagination
                    let data = res.payload.data

                    temp.page = data.current_page
                    temp.total_page = data.last_page
                    temp.per_page = data.per_page
                    temp.total = data.total
                    temp.total_counting = data.to
                    this.setState({pagination: temp, loading: false})
                } else {
                    if(res.type == 'MY_COURSE_REQUEST_FAILED'){
                        swal({
                            title: 'Failed',
                            text: res && res.payload && res.payload && res.payload.response && res.payload.response.data ? res.payload.response.data.data : 'Please check your connection or try again later',
                            icon: 'warning'
                        })
                        .then(res => {
                            history.push('/profile/edit')
                        })
                    }
                }
            })
            .catch(err => {
                this.setState({loading: false})
            })
        } else {
            this.setState({loading: false})
            history.push('/')
        }
        
    }

    openCourse = () => {
        swal({
            title: "Coming soon",
            text: "Halaman sedang dalam pengerjaan",
            icon: "warning",
        })
    }

    ChangePagination = (pagenumber) => {
        let {cookies, history} = this.props
        let cookie = cookies.getAll();
        this.setState({loading: true})
        this.props.getMyCourse({
            Authorization: `Bearer ${cookie.access_token}`
        }, 'page=' + pagenumber)
        .then(res => {
            let temp = this.state.pagination
            let data = res.payload.data

            temp.page = data.current_page
            temp.total_page = data.last_page
            temp.per_page = data.per_page
            temp.total = data.total
            temp.total_counting = data.to
            this.setState({pagination: temp, loading: false})
        })
        .catch(err => {
            console.log(err);
            this.setState({ loading: false })
        })
    }

    makeSlug = (id, title) => {
        let titleArr = title.split(' ')
        let titleStr = titleArr.join('_')
        let slug = `${id}-${titleStr}`
        return slug
    }

    render() {
        let { MyCourse } = this.props
        let { pagination } = this.state
        
        console.log(MyCourse)
        
        return (
            <>
                <Nav/>
                <React.Fragment>
                    <Loading isOpen={this.state.loading}/>
                    <Container className='mt-4'>
                        <Row>
                            <Col sm='12'>
                                <div style={{ backgroundColor: '#EDEDED', backgroundImage: `url(${require('../assets/images/background.png')})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                                    <Card style={{ borderRadius: '15px', padding: 0 }} className='bq-light'>
                                        <Row style={{margin: 0}}>
                                            <Menu/>
                                            <Col sm='8' className="text-left pt-3">
                                                <h3 style={{ color: '#4A4A4A' }}>Kursus Saya</h3>
                                                <Row style={{margin: 0, marginBottom: '1em'}}>
                                                    <Col sm='4' xs='6' style={{padding: '.5em 0', borderBottom: '2.5px solid #009194', textAlign: 'center'}}>
                                                        <span style={{fontSize: '.95em', color: '#3E3E3E'}}>Semua Kursus</span>
                                                    </Col>
                                                </Row>
                                                {
                                                    MyCourse.data ? (
                                                        <Row>
                                                            {
                                                                MyCourse.data.length > 0 ? (
                                                                    MyCourse.data.map((course, idx) => (
                                                                        <Col md='6' lg='6' xs='6' key={idx}> 
                                                                                <div style={{
                                                                                    backgroundColor: 'white',
                                                                                    borderRadius: '10px',
                                                                                    boxShadow: '0px 0px 5px #00000020',
                                                                                    marginBottom: '15px',
                                                                                    color: '#000'
                                                                                }}>
                                                                                    <Link to={`/course-enroll/${this.makeSlug(course.courses_id._id, course.courses_id.course_title)}`}>
                                                                                     <img className='course-thumbnail' src={course.courses_id.course_image_medium} />
                                                                                    </Link> 
                                                                                    <div style={{ padding: '1em',height: '140px' }}>
                                                                                    <Link to={`/course-enroll/${this.makeSlug(course.courses_id._id, course.courses_id.course_title)}`}>
                                                                                        <div>
                                                                                            <span style={{ color: '#000', fontWeight: 'bold', margin: 0, fontSize: '.95em'}}>{course.courses_id.course_title}</span> 
                                                                                        </div>
                                                                                        <p className="mb-0 color-black" style={{ color: '#000', fontSize: '.8em' }}>{course.courses_id.sections.length} Section</p>
                                                                                        <p style={{ fontSize: '.8em' ,color: '#000' }} className='color-black'>Status: {course.status == 'enroll' ? 'Progress' : course.status}</p>
                                                                                    </Link>
                                                                                    {
                                                                                        course.is_done == true && course.link_pdf && 
                                                                                            <a href={course.link_pdf} target="_blank" className='text-right'>Print certificate</a>
                                                                                    }  
                                                                                    </div> 
                                                                                </div>  
                                                                        </Col>
                                                                    ))
                                                                ) : (
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems:'center',
                                                                        flexDirection: 'column', 
                                                                        padding: '2em 0'}}>
                                                                        <h4>Belum ada kursus yang kamu beli, lihat kursus lainnya.</h4>
                                                                        <button style={{
                                                                            backgroundColor: '#009194',
                                                                            color: '#fff',
                                                                            border: 0,
                                                                            borderRadius: '10px',
                                                                            boxShadow: '0  17px 10px -10px rgba(0,0,0,0.1)',
                                                                            padding: '.5em 1.5em',
                                                                            marginTop: '2em'
                                                                        }} onClick={() => this.props.history.push('/')}>
                                                                            Kembali ke Homepage
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }
                                                        </Row>
                                                    ) : (
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems:'center',
                                                            flexDirection: 'column', 
                                                            padding: '2em 0'}}>
                                                            <h4>Belum ada kursus yang kamu beli, lihat kursus lainnya.</h4>
                                                            <button style={{
                                                                backgroundColor: '#009194',
                                                                color: '#fff',
                                                                border: 0,
                                                                borderRadius: '10px',
                                                                boxShadow: '0  17px 10px -10px rgba(0,0,0,0.1)',
                                                                padding: '.5em 1.5em',
                                                                marginTop: '2em'
                                                            }} onClick={() => this.props.history.push('/')}>
                                                                Kembali ke Homepage
                                                            </button>
                                                        </div>
                                                    ) 
                                                }
                                                
                                                {
                                                    MyCourse.data ? (
                                                        MyCourse.data.length > 0 ? (
                                                            <Row style={{margin: 0, padding: '1.5em 0'}}>
                                                                <Pagination
                                                                    activePage={pagination.page}
                                                                    itemsCountPerPage={pagination.per_page}
                                                                    totalItemsCount={pagination.total}
                                                                    pageRangeDisplayed={3}
                                                                    // pageCount={3}
                                                                    onChange={(nextPage) => this.ChangePagination(nextPage)}
                                                                />
                                                            </Row>
                                                        ) : null
                                                    ) : null
                                                }
                                                
                                                
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </React.Fragment>
            </>
        );
    }
}

const WrappedCookies = withCookies(MyCourse)

const mapStateToProps = state => {
  return {
    Auth: state.Auth,
    Profile: state.Profile,
    MyCourse: state.MyCourse
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getProfile, authLogout, getMyCourse }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedCookies);
