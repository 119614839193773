const defaultState = {
    isLoading: false,
    error: "",
  };
  
  const checkout = (state = defaultState, action) => {
    switch (action.type) {
      case "CHECKOUT_REQUEST":
        return {
          isLoading: false,
          authRequest: true
        };
  
      case "CHECKOUT_REQUEST_FAILED":
        return {
          isLoading: false,
          error: action.payload.message
        };
  
      case "CHECKOUT_REQUEST_SUCCESS":
        return {
          isLoading: true,
          courses: action.payload.data.data,
          ...action.payload
        };
  
      case "AUTH_LOGOUT":
        return {
            defaultState
        };
        
      default:
        return state;
    }
  };
  
  export default checkout;
  