import axios from "axios";

const api_url = process.env.REACT_APP_API_URL;

export const getPaymentMaps = headers => async dispatch => {
  try {
    const request = await axios.get(api_url + "/api/payment_maps/", {
      headers
    });
    
    return request;
  } catch (err) {
    return err
  }
};

export const setPaymentMethod = (body, headers) => async dispatch => {
    try {
        const request = await axios.post(api_url + "/api/ewallet/", body, {
            headers
          });
          
          return request;
    } catch (error) {
        
    }
}

export const getEWallet = headers => async dispatch => {
    try {
      const request = await axios.get(api_url + "/api/ewallet/me?", {
        headers
      });
      
      return request;
    } catch (err) {
      return err
    }
  };