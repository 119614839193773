import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { bindActionCreators } from 'redux';
import { Container, Row, Col, Input, FormGroup, Label, Spinner } from 'reactstrap';
import { Image, Accordion, Card, Form } from 'react-bootstrap';
import Countdown from 'react-countdown';
import YouTube from 'react-youtube';
import swal from 'sweetalert';
import Nav from '../../components/nav'
import Rating from '../../components/Modal/Rating';
import Loading from '../../components/Modal/loading';
import numberWithComma from '../../utils/numberWithComma';
import { detailMyCourse, detailModules, checkAccessSection } from '../../store/actions/course';
import { checkoutCourse } from '../../store/actions/checkout';
import { checkValidVoucher } from '../../store/actions/voucher';
import ChevronUp from '../../assets/icon/chevron-up-black.png'
import Rocket from '../../assets/icon/rocket-up.png'
import DefaultImg from '../../assets/images/default.png'
import HttpModule from '../../stores/modules/httpCall'
import CourseModule from './src/CourseModule';
import QuizMultipleOption from './src/QuizMultipleOpstion'

const api_url = process.env.REACT_APP_API_URL;
 
const addLeadingZeros = (value) => {
	value = String(value);
	while (value.length < 2) {
		value = '0' + value;
	}
	return value;
};

const renderer = ({ hours, minutes, seconds, completed }) => {
	if (completed) {
		// Render a completed state
		return (
			<h4 className="text-bold" style={{ color: '#FF5B00' }}>
				Waktu kuis telah berakhir.
			</h4>
		);
	} else {
		// Render a countdown
		return (
			<h4 className="text-bold" style={{ color: '#FF5B00' }}>
				{addLeadingZeros(hours)}:{addLeadingZeros(minutes)}:{addLeadingZeros(seconds)}
			</h4>
		);
	}
};

class CourseEnroll extends Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};
	constructor(props) {
		super(props);
		this.state = {
			details: {},
			modules: {},
			loading: false,
			rateOpen: false,
			question_now: 1,
			questions: {},
			quiz_agree: false,
			quiz_done: false,
			answers: [ 'A', 'B', 'C', 'D', 'E' ],
			choose_answer: {},
			start_quiz_time: new Date(),
			essay_ans: {
				answer_title: '',
				answer_url: ''
			},
			sections_id: ''
		};
	}

	componentDidMount() { 
		this.firsLoad()
		this.sendCheckedModules()
	}

	firsLoad(){
		const { match: { params }, cookies, history } = this.props;
		let id = params.slug.split('-')[0];
		let cookie = cookies.getAll();
		if (cookie.hasOwnProperty('access_token')) {
			this.setState({ loading: true });
			this.props
				.detailMyCourse(
					id,
					cookie.hasOwnProperty('access_token') ? { Authorization: 'Bearer ' + cookie.access_token } : {}
				)
				.then((res) => {
					this.setState({ details: res.data.data.courses });
					let course = res.data.data.courses;
					if (course.sections.length > 0) {
						if (course.sections[0].modules.length > 0) {
							this.props
								.detailModules(
									{ modules_id: course.sections[0].modules[0]._id, courses_id: id },
									{ Authorization: 'Bearer ' + cookie.access_token }
								)
								.then((res) => {
									let data = res.data.data.module_details[0];
									if (data && data.type == 'lesson') {
										if (data.lesson_video_id == '' || !data.lesson_video_id) {
											let split = data.lesson_url.split('/');
											let id = split[split.length - 1].split('=');
											data.lesson_video_id = id[1];
										}
									}
									if (data && data.type == 'quiz') {
										if (data.questions.length > 0) {
											for (let i = 0; i < data.questions.length; i++) {
												data.questions.done = false;
											}
										}
									}
									data.idx = 0;
									data.section_idx = 0;
									if (res.status == 200) {
										this.setState({ modules: data });
									} else {
										swal({
											title: "There's been an error occured",
											text: 'Please check your internet and try again later',
											icon: 'error'
										});
									}
									this.setState({ loading: false });
								});
						} else {
							this.setState({ loading: false });
						}
					} else {
						this.setState({ loading: false });
					}
				})
				.catch((err) => {
					swal({
						title: "There's been an error occured",
						text: 'Please check your internet and try again later',
						icon: 'error'
					});
					this.setState({ loading: false });
				});
		} else {
			history.push('/');
		}
	}

	getModules = async (id, idx, section_idx, sections_id = null,module_payload_detail={}) => {
		this.setState({sections_id})
		const { match: { params }, cookies } = this.props;
		let course_id = params.slug.split('-')[0];
		let cookie = cookies.getAll();
		this.setState({ loading: true });
		let checkAccessSection = this.props
			.checkAccessSection({ sections_id: sections_id }, { Authorization: 'Bearer ' + cookie.access_token })
			.then((response) => {
				if (response) {
					this.props
						.detailModules(
							{ modules_id: id, courses_id: course_id },
							{ Authorization: 'Bearer ' + cookie.access_token }
						)
						.then((res) => {
							let data = res.data.data.module_details[0];
							if (data.type == 'lesson') {
								let split = data.lesson_url.split('/');
								let id = split.length == 5 ? split[4] : '';
								data.youtube_id = id;
							}

							data.idx = idx;
							// data.modules_id = id;
							data.section_idx = section_idx;
							data.module_payload_detail = module_payload_detail
							clearTimeout(this.timeout);
							if (data) {
								this.setState({ modules: data, quiz_agree: false, loading: false });
							} else {
								this.setState({ modules: {}, quiz_agree: false, loading: false });
							}
						});
				} else {
				this.setState({ loading: false });

				swal({
					title: 'Apa kamu ingin membeli materi ini?',
					closeOnClickOutside: false,
					closeOnEsc: false,
					buttons: [ 'Tidak', 'Ya' ],
					closeOnConfirm: false
				})
					.then((use) => {
						let is_use = use;
						if (is_use) {
							swal({
								title: 'Apa kamu ingin menggunakan kupon?',
								content: 'input',
								buttons: [ 'Tidak Gunakan', 'Gunakan' ],
								closeOnClickOutside: false,
                                closeOnEsc: false,
                                showCancelButton: true,
							}).then((input) => {
								this.setState({
									sections_id: sections_id
								});
								if (input === '') {
									return swal({
										icon: 'warning',
										text: 'Maaf, kolom kupon harus diisi!',
										closeOnClickOutside: false
									});
								}
								if (input === null) {
									return this.checkout();
								} else {
									console.log(input)
									return this.props.checkValidVoucher({ voucher_code: input}, { Authorization: 'Bearer ' + cookie.access_token }).then((checkVoucher) => {
										if(checkVoucher?.payload.data.voucher_code !== undefined) {
											this.setState({ loading: false });
											this.checkout(input);
											swal.close();
										} else {
											return swal({
												icon: 'warning',
												text: 'Kupon kamu tidak bisa digunakan',
												closeOnClickOutside: false
											});
										}
									})
								}
								
							}).catch(errVoucher => {
								return swal({
									icon: 'warning',
									text: errVoucher,
									closeOnClickOutside: false
								});
							})
						} else {
							swal.close();
						}
					})
					.catch((err) => {
						if (err) {
							swal('Peringatan', err, 'error');
						} else {
							this.setState({ loading: true });
							swal.close();
						}
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	coomingSoon = () => {
		swal({
			title: 'Coming soon',
			text: 'Halaman sedang dalam pengerjaan',
			icon: 'warning'
		});
	};

	requestMeeting = () => {
		let { modules, details } = this.state;
		let cookie = this.props.cookies.getAll();
		this.setState({ loading: true });
		axios
			.post(
				api_url + '/api/module-detail/request-meeting',
				{
					modules_details_id: modules._id,
					courses_id: details._id,
					modules_id : modules.modules_id
				},
				{ headers: { Authorization: 'Bearer ' + cookie.access_token } }
			)
			.then((res) => {
				this.setState({ loading: false });
				this.sendCheckedModules()
				swal({
					title: 'Request Success',
					text: 'Permintaan meeting anda berhasil dan sedang di proses',
					icon: 'success'
				});
			})
			.catch((err) => {
				this.setState({ loading: false });
				swal({
					title: 'Failed',
					text: 'Ada kesalahan! Silahkan coba beberapa saat lagi',
					icon: 'error'
				});
			});
	};

	sendCheckedModules = async () => {
		let { modules, details , sections_id } = this.state; 

		if(!sections_id) return false 

		console.log(modules.module_payload_detail)

		let cookie = this.props.cookies.getAll();
		let form = new FormData();
		form.append('modules_id',modules.modules_id);
		form.append('module_detail_id', modules.module_payload_detail._id );
		form.append('courses_id', details.details.courses_id);
 
		const call = await axios
			.post(api_url + '/api/module/change-status', form, {
				headers: { Authorization: 'Bearer ' + cookie.access_token }
			})
			.then((res) => {
				let temp = details;
				temp.sections[modules.section_idx].modules[modules.idx].is_finish = true;
				this.setState({ details: temp });
			})
			.catch((err) => {
				swal({
					title: 'Failed',
					text: 'Ada kesalahan! Silahkan coba beberapa saat lagi',
					icon: 'error'
				});
			});
	};

	startQuiz = () => {
		let { modules } = this.state;
		let { match: { params } } = this.props;
		let id = params.slug.split('-')[0];
		let cookie = this.props.cookies.getAll();
		this.setState({ loading: true });
		axios
			.get(api_url + '/api/question', {
				headers: {
					Authorization: 'Bearer ' + cookie.access_token
				},
				params: {
					modules_details_id: modules._id,
					per_page: 1,
					courses_id: id,
					modules_id: modules.modules_id
				}
			})
			.then((res) => {
				let data = res.data.data;
				let temp_ans = {};
				let temp_essay_ans = {
					answer_title: '',
					answer_url: ''
				};
				if (modules.questions[0].is_answered) {
					temp_ans = modules.questions[0].logs[0];
					temp_essay_ans = modules.questions[0].logs[0];
				}
				this.setState({
					loading: false,
					questions: data.question.data[0],
					quiz_agree: true,
					start_quiz_time:
						modules.quiz_duration > 0
							? new Date(new Date().getTime() + modules.quiz_duration * 60000)
							: null,
					choose_answer: temp_ans,
					essay_ans: temp_essay_ans,
					question_now:  data.question.data[0].question_order
				});
				if (modules.quiz_duration > 0) {
					this.timeout = setTimeout(() => {
						swal({
							title: 'Waktu kuis telah berakhir',
							text: 'Semua jawaban otomatis ter submit.',
							icon: 'warning'
						}).then(() => {
							let temp = modules;
							temp.status = 'review';
							this.quizFinalSubmit();
							this.setState({
								quiz_agree: false,
								modules: temp
							});
						});
					}, modules.quiz_duration * 60000);
				}
			})
			.catch((err) => {
				swal({
					title: "There's been an error occured",
					text: 'Please check your internet and try again later',
					icon: 'error'
				});
				this.setState({ loading: false });
			});
	};

	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	answerQuiz = (ans) => { 

		let { modules, questions, question_now, details, essay_ans } = this.state;
		let cookie = this.props.cookies.getAll();

		this.setState({ loading: true });

		let reqBody = {
			courses_id: details._id,
			questions_id: questions._id,
			modules_details_id: modules._id,
			modules_id: modules.modules_id,
			answer_id : ans._id
		};

		if (questions.type === 'choices') {
			reqBody.answer_id = ans._id;
		} else {
			if (questions.answer_type === 'freetext') {
				reqBody.answer_title = essay_ans.answer_title;
			}
		}

		axios
			.post(api_url + '/api/answer', reqBody, {
				headers: {
					Authorization: 'Bearer ' + cookie.access_token
				}
			})
			.then((res) => {
				let temp = modules;
				if (questions.type === 'choices') {
					ans.answers_id = ans._id;
					temp.questions[question_now].logs.push(ans);
				}
				if (questions.answer_type === 'freetext') {
					temp.questions[question_now].logs.push({
						answer_title: essay_ans.answer_title
					});
					swal({
						title: 'Jawaban mu sudah tersimpan',
						icon: 'success'
					});
				}

				temp.questions[question_now].is_answered = true;

				this.setState({
					loading: false,
					choose_answer: ans,
					modules: temp
				});
			})
			.catch((err) => {
				swal({
					title: "There's been an error occured",
					text: 'Please check your internet and try again later',
					icon: 'error'
				});
				console.log(err);

				this.setState({ loading: false });
			});
	};

	nextQuestion = () => {
		let { modules, question_now } = this.state;
		let { match: { params } } = this.props;
		let id = params.slug.split('-')[0];
		let cookie = this.props.cookies.getAll();
		this.setState({ loading: true });
		axios
			.get(api_url + '/api/question', {
				headers: {
					Authorization: 'Bearer ' + cookie.access_token
				},
				params: {
					modules_details_id: modules._id,
					per_page: 1,
					courses_id: id,
					modules_id: modules.modules_id,
					page: question_now+2,
					// id : modules.questions[question_now-1]._id
				}
			})
			.then((res) => {
				let data = res.data.data;
				let temp_ans = {};
				let temp_essay_ans = {
					answer_title: '',
					answer_url: ''
				};

				if (data.question.data[0].is_answered){
					temp_ans = modules.questions[data.question.data[0].question_order].logs[0];
					temp_essay_ans = modules.questions[data.question.data[0].question_order].logs[0];
				}

				this.setState({
					loading: false,
					questions: data.question.data[0],
					quiz_agree: true,
					question_now: data.question.data[0].question_order,
					choose_answer: temp_ans,
					essay_ans: temp_essay_ans
				});
			})
			.catch((err) => {
				swal({
					title: "There's been an error occured",
					text: 'Please check your internet and try again later',
					icon: 'error'
				});
				this.setState({ loading: false });
		});
	};

	prevQuestion = () => {
		let { modules, question_now } = this.state;
		let { match: { params } } = this.props;
		let id = params.slug.split('-')[0];
		let cookie = this.props.cookies.getAll();
		this.setState({ loading: true });
		axios
			.get(api_url + '/api/question', {
				headers: {
					Authorization: 'Bearer ' + cookie.access_token
				},
				params: {
					modules_details_id: modules._id,
					per_page: 1,
					courses_id: id,
					modules_id: modules.modules_id,
					page: question_now,
					// id : modules.questions[question_now-1]._id
				}
			})
			.then((res) => {
				let data = res.data.data;
				let temp_ans = {};
				let temp_essay_ans = {
					answer_title: '',
					answer_url: ''
				};

				if (data.question.data[0].is_answered){
					temp_ans = modules.questions[data.question.data[0].question_order].logs[0];
					temp_essay_ans = modules.questions[data.question.data[0].question_order].logs[0];
				}

				this.setState({
					loading: false,
					questions: data.question.data[0],
					quiz_agree: true,
					question_now: data.question.data[0].question_order,
					choose_answer: temp_ans,
					essay_ans: temp_essay_ans
				});
			})
			.catch((err) => {
				swal({
					title: "There's been an error occured",
					text: 'Please check your internet and try again later',
					icon: 'error'
				});
				this.setState({ loading: false });
			});
	};

	chooseQuestion = (choosen_question) => {  
		let { modules } = this.state;
		let { match: { params } } = this.props;
		let id = params.slug.split('-')[0];
		let cookie = this.props.cookies.getAll();
		this.setState({ loading: true });
		axios
			.get(api_url + '/api/question', {
				headers: {
					Authorization: 'Bearer ' + cookie.access_token
				},
				params: {
					modules_details_id: modules._id,
					per_page: 1,
					courses_id: id,
					modules_id: modules.modules_id,
					page: choosen_question.question_order == 0 ? 1 : choosen_question.question_order+1,
					// id : choosen_question._id
				}
			})
			.then((res) => {
				let data = res.data.data;
				let temp_ans = {};
				let temp_essay_ans = {
					answer_title: '',
					answer_url: ''
				};
				 
				if (data.question.data[0].is_answered){
					temp_ans = modules.questions[choosen_question.question_order].logs[0];
					temp_essay_ans = modules.questions[choosen_question.question_order].logs[0];
				}

				console.log({
					temp_ans,
					temp_essay_ans
				})

				this.setState({
					loading: false,
					questions: data.question.data[0],
					quiz_agree: true,
					question_now:  data.question.data[0].question_order,
					choose_answer: temp_ans,
					essay_ans: temp_essay_ans,
				});
			})
			.catch((err) => {
				this.setState({ loading: false });
				swal({
					title: "There's been an error occured",
					text: 'Please check your internet and try again later',
					icon: 'error'
				});
			});
	};
 
	submitQuiz = async () => {
		let { questions, modules, question_now, details, essay_ans } = this.state;
		let cookie = this.props.cookies.getAll();

		this.setState({ loading: true });

		swal({
			title: 'Anda sudah yakin?',
			text: 'Semua jawabanmu akan dikirimkan',
			buttons: {
				Batal: 'Batal',
				Proses: true
			}
		}).then((value) => {
			if (value === 'Proses') {
				if (questions.type === 'essay') {
					if (questions.answer_type !== 'freetext') {
						if (essay_ans.answer_title !== '' && essay_ans.answer_url !== '') {
							axios
								.post(
									api_url + '/api/answer',
									{
										...essay_ans,
										courses_id: details._id,
										questions_id: questions._id,
										modules_details_id: modules._id,
										modules_id: modules.modules_id
									},
									{
										headers: {
											Authorization: 'Bearer ' + cookie.access_token
										}
									}
								)
								.then((res) => {
									this.quizFinalSubmit();
								})
								.catch((err) => {
									swal({
										title: "There's been an error occured",
										text: 'Please check your internet and try again later',
										icon: 'error'
									});
									this.setState({ loading: false });
								});
						} else {
							swal({
								title: 'Terdapat jawaban yang belum di isi',
								text: 'Silahkan isi semua jawaban terlebih dahulu',
								icon: 'warning'
							});
							this.setState({ loading: false });
						}
					} else {
						this.quizFinalSubmit();
					}
				} else {
					this.quizFinalSubmit();
				}
			} else {
				this.setState({ loading: false });
			}
		});
	};

	onChangeEssay = (e) => {
		let temp = this.state.essay_ans;
		temp[e.target.name] = e.target.value;
		this.setState({ essay_ans: temp });
	};

	quizFinalSubmit = () => {
		let { modules, question_now } = this.state;
		let { match: { params } } = this.props;
		let id = params.slug.split('-')[0];
		let cookie = this.props.cookies.getAll();
		this.setState({ loading: true });
		axios
			.post(
				api_url + '/api/module-detail/change-status-log',
				{
					modules_details_id: modules._id,
					courses_id: id,
					modules_id: modules.modules_id
				},
				{
					headers: {
						Authorization: 'Bearer ' + cookie.access_token
					}
				}
			)
			.then((res) => {
				let temp = modules;
				temp.status = 'review';
				this.setState({
					loading: false,
					modules: temp,
					quiz_agree: false,
					essay_ans: {
						answer_title: '',
						answer_url: ''
					}
				});
				this.sendCheckedModules()
				this.firsLoad()
				swal({
					title: 'Success',
					text: 'Your anser has been successfuly submit',
					icon: 'success'
				});
			})
			.catch((err) => {
				this.setState({ loading: false });
				swal({
					title: "There's been an error occured",
					text: 'Please check your internet and try again later',
					icon: 'error'
				});
			});
	};

	getCertificate = () => {
		let { match: { params } } = this.props;
		let id = params.slug.split('-')[0];
		let cookie = this.props.cookies.getAll();
		this.setState({ loading: true });
		axios
			.get(api_url + '/api/course/download-certificate', {
				params: {
					courses_id: id,
					download: true
				},
				headers: {
					Authorization: 'Bearer ' + cookie.access_token
				}
			})
			.then((res) => {
				this.setState({ loading: false });
				window.open(res.data.data, '_blank');
			})
			.catch((err) => {
				this.setState({ loading: false });
				if (err.response.status === 400) {
					this.setState({ rateOpen: true });
				} else {
					swal({
						title: "There's been an error occured",
						text: 'Please check your internet and try again later',
						icon: 'error'
					});
				}
			});
	};

	checkout = (voucher = null) => {
		let { cookies, history } = this.props;
        let cookie = cookies.getAll();
		if (cookie.hasOwnProperty('access_token')) {
			let body = { sections_id: this.state.sections_id, voucher_code: voucher };
			this.props.checkoutCourse(body, { Authorization: `Bearer ${cookie.access_token}` }).then((res) => {
				this.setState({ loading: false });
				if (res.type == 'CHECKOUT_REQUEST_SUCCESS') {
					if (!res.payload.data.transaction?._id) {
						swal({
							title: 'Sukses',
							text: 'Transaksi berhasil dilakukan',
							icon: 'success'
						});
						history.push(`/course-enroll/${res.payload.data.slug_product	}`);
					} else {
						window.location.replace(`${res.payload.data.url}`);
					}
				} else {
					this.setState({ loading: false });
					let err = res.payload.response.data.data;
					let message = err.courses
						? `Selesaikan terlebih dahulu kursus ${err.courses.map((course) => course.course_title)}`
						: 'Please try again a few moment later';

					swal({
						title: err.courses ? err.message : err,
						text: message,
						icon: 'error'
					});
				}
			});
		} else {
			this.setState({ loading: false });
			swal({
				title: 'Please Login First',
				text: '',
				icon: 'warning'
			});
		}
	};


	renderPaginationButton(data){
		console.log(data)
		return<></>
	}

	render() {
		let {
			details,
			modules,
			question_now,
			answers,
			quiz_agree,
			questions,
			choose_answer,
			start_quiz_time,
			rateOpen,
			essay_ans,
			sections_id
		} = this.state;
		let cookie = this.props.cookies.getAll();
		const opts = {
			height: '450',
			width: '100%',
			frameBorder: 0,
			allowFullScreen: true, 
			playerVars: {
				modestbranding: 1,
				autohide: 1,
				showinfo: 0,
				controls: 1
			}
		};
			
		return (
			<>
			<Nav/>
			<React.Fragment> 
				<Loading isOpen={this.state.loading} />
				<Rating
					isOpen={rateOpen}
					toggle={() => this.setState({ rateOpen: false })}
					token={cookie.access_token}
					courses_id={details._id}
				/>
				
				{/* <button onClick={()=>this.sendCheckedModules()} >{modules.modules_id} {details._id} {sections_id}</button> */}
 
				<div style={{ width: '100%' }} className="container-wheat">
					
					{/* <button onClick={()=> HttpModule.httpSetModuleFinish({modules_id: '5ecb4a48b36a0000dc007de4', courses_id : '5ec2a77968530000b8001bd1', module_detail_id : '5ecb4a48b36a0000dc007de5'})}>BUTTON</button> */}

					<Container>
						<Row>
							<Col sm="8" style={{ padding: 0 }}>
								{/* <div className='progres-cont'>
                                    <div className='dot-progres-active'/>

                                    <React.Fragment>
                                        <div className='progres-box'>
                                            <p className='progres-title'>Video</p>
                                            <div className='progres-line-active'/>
                                        </div>

                                        <div className='dot-progres-active'/>
                                    </React.Fragment>
                                    
                                    <React.Fragment>
                                        <div className='progres-box'>
                                            <p className='progres-title'>Quiz</p>
                                            <div className='progres-line-deactive'/>
                                        </div>

                                        <div className='dot-progres-deactive'/>
                                    </React.Fragment>

                                    <React.Fragment>
                                        <div className='progres-box'>
                                            <p className='progres-title'>Upload Video</p>
                                            <div className='progres-line-deactive'/>
                                        </div>

                                        <div className='dot-progres-deactive'/>
                                    </React.Fragment>

                                    <React.Fragment>
                                        <div className='progres-box'>
                                            <p className='progres-title'>Create Itinerary</p>
                                            <div className='progres-line-deactive'/>
                                        </div>

                                        <div className='dot-progres-deactive'/>
                                    </React.Fragment>
                                </div> */}

								{
								details.progress === 'graduated' && (
									details.get_certificate && (
										<div
											className="request-cont"
											style={{
												backgroundColor: 'whitesmoke',
												flexDirection: 'column',
												height: '400px'
											}}
										>
											<img
												src={Rocket}
												style={{ width: '10%' }}
											/>
											Selamat kamu sudah lulus kursus ini. Download Sertifikat mu disini.
											<button
												className="cert-box"
												style={{ marginTop: '1em' }}
												onClick={this.getCertificate}
											>
												Print Certificate
											</button>
										</div>
									)
								)
								}
								{
									(
										<div>
											{modules.type ? (
												<div>
													{modules.type == 'quiz' ? quiz_agree ? (
														<div style={{ width: '95%' }}>
															<div className="quiz-cont">
																<Row style={{ margin: 0 }}>
																	<Col sm="8" style={{ padding: 0 }}>
																		<h4 className="text-bold">Kuis</h4>
																		<div className="question-box">
																			{modules.questions.map((q, id) => (
																				<div
																					key={id}
																					className={`question-number ${question_now ===
																					q.question_order
																						? 'question-now'
																						: (q.is_answered == true && (modules && modules.questions && modules.questions[0] && modules.questions[q.question_order].is_answered == true))
																							? 'question-finish'
																							: 'question-unfinish'}`}
																					onClick={() => this.chooseQuestion(q)}
																				>
																					{id+1} 
																				</div>
																			))}
																			{
																				this.renderPaginationButton(modules)
																			}
																		</div>
																	</Col>
																	<Col sm="4" className="right-question-box">
																		{start_quiz_time !== null && (
																			<Countdown
																				date={start_quiz_time}
																				renderer={renderer}
																			/>
																		)}

																		<div>
																			<div className="example-box">
																				<div
																					className="question-number question-now"
																					style={{
																						width: '30px',
																						height: '27px',
																						marginRight: '10px'
																					}}
																				>
																					N
																				</div>
																				= Sedang Dikerjakan
																			</div>

																			<div className="example-box">
																				<div
																					className="question-number question-unfinish"
																					style={{
																						width: '30px',
																						height: '27px',
																						marginRight: '10px'
																					}}
																				>
																					N
																				</div>
																				= Belum Dikerjakan
																			</div>

																			<div className="example-box">
																				<div
																					className="question-number question-finish"
																					style={{
																						width: '30px',
																						height: '27px',
																						marginRight: '10px'
																					}}
																				>
																					N
																				</div>
																				= Sudah Dikerjakan
																			</div>
																		</div>
																	</Col>
																</Row>
															</div>

															<div className="q-card">
																<div className="q-card-header">
																	<h5 className="text-bold">Soal no. {question_now+1}</h5>
																</div>
																<div style={{ padding: '2em' }}>
																	<div
																		dangerouslySetInnerHTML={{
																			__html: questions.question
																		}}
																	/>

																	{/* IF QUIZ */}
																	{questions.type === 'choice' &&
																		<QuizMultipleOption answerQuiz={(ans) => this.answerQuiz(ans)} choose_answer={choose_answer} questions={questions}/>
																	}

																	{/* IF FREETEXT */} 
																	{ questions.answer_type === 'freetext' && questions.type != 'choice' && (
																		<div
																			style={{
																				marginTop: '2em'
																			}}
																		>
																			<Form.Control
																				as="textarea"
																				name="answer_title"
																				rows="6"
																				style={{ marginBottom: '1em' }}
																				value={essay_ans.answer_title}
																				onChange={this.onChangeEssay}
																				placeholder="Type your answer here"
																			/>

																			<button
																				className="q-btn-filled"
																				style={{
																					marginBottom: '1em',
																					width: '100%',
																					borderRadius: '5px'
																				}}
																				onClick={this.answerQuiz}
																			>
																				{'Jawab'}
																			</button>
																		</div>
																	)
																	}
																	
																	{/* IF NOT QUIZ and NOT FREETEXT */}  
																	{
																		questions.answer_type != 'freetext' && questions.type != 'choice' && (
																			<div>
																				<Input
																					type={'text'}
																					name="answer_title"
																					value={essay_ans.answer_title}
																					onChange={this.onChangeEssay}
																					placeholder="Insert The Title Of Your Task"
																					style={{
																						width: '90%',
																						padding: '.2em 1em',
																						marginBottom: '1.5em'
																					}}
																				/>

																				<Input
																					type={'text'}
																					name="answer_url"
																					value={essay_ans.answer_url}
																					onChange={this.onChangeEssay}
																					placeholder="Insert Your URL Task"
																					style={{
																						width: '90%',
																						padding: '.2em 1em'
																					}}
																				/>
																			</div>
																	)}

																	<div className="q-card-footer"> 
																		{
																			question_now  > 0 &&
																			<button
																				className="q-btn-outline"
																				onClick={this.prevQuestion}
																			>
																				{'<< Sebelumnya'}
																			</button>
																		}

																		{(question_now+1) === modules.questions.length ? (
																			<button
																				className="q-btn-filled"
																				onClick={this.submitQuiz}
																			>
																				{'Submit'}
																			</button>
																		) : (
																			<button
																				className="q-btn-filled"
																				onClick={this.nextQuestion}
																			>
																				{'Selanjutnya >>'}
																			</button>
																		)}
																	</div>
																</div>
															</div>
														</div>
													) : modules.status !== 'progress' ? modules.status === 'review' ? (
														<div
															className="request-cont"
															style={{
																backgroundColor: 'whitesmoke',
																flexDirection: 'column',
																height: '400px'
															}}
														>
															<img
																src={Rocket}
																style={{ width: '10%' }}
															/>
															Kuis anda sedang dalam proses pemeriksaan. Hasil akan diberikan
															melalui email.
														</div>
													) 
													: 
													( 
														<div
															className="request-cont"
															style={{
																backgroundColor: 'whitesmoke',
																flexDirection: 'column',
																height: '400px'
															}}
														>
															{/* <img
																src={Rocket}
																style={{ width: '10%' }}
															/>
															Selamat kamu sudah lulus kursus ini. Download Sertifikat mu
															disini. */}
															{
																details.progress != 'graduated' ?
																<button
																	className="cert-box"
																	style={{ marginTop: '1em' }}
																	// onClick={this.getCertificate}
																	onClick={() => this.sendCheckedModules()}
																>
																	Finish Quiz
																</button>
																:
																<button className="cert-box" onClick={this.getCertificate}>
																	Print Certificate
																</button>
															}
															 
														</div>
													) 
													:
													(
														<div style={{ width: '95%' }}>
															<div className="q-card">
																<div className="q-card-header">
																	<h3 className="text-bold">QUIZ</h3>
																</div>
																<div style={{ padding: '2em' }}>
																	<div
																		dangerouslySetInnerHTML={{
																			__html: modules.quiz_description
																		}}
																	/>
																	<div
																		className="q-card-footer"
																		style={{ justifyContent: 'flex-end' }}
																	>
																		<button
																			className="q-btn-filled"
																			onClick={this.startQuiz}
																		>
																			{'Mulai Kuis'}
																		</button>
																	</div>
																</div>
															</div>
														</div>
													) : <></>}
													{
														modules.type == 'lesson' && modules.lesson_video_id !== '' && (
															<YouTube
																opts={opts}
																videoId={modules.lesson_video_id}
																onEnd={(e) => this.sendCheckedModules()}
															/>
														)
													}
													
													{
													modules.type == 'lesson' && modules.lesson_url !== '' && modules.lesson_video_id == '' && (
														<iframe
															width="100%"
															height="345"
															src={
																modules.lesson_url ? (
																	`${modules.lesson_url}?modestbranding=1&autohide=1&showinfo=0&controls=0`
																) : (
																	''
																)
															}
															frameBorder="0"
															allowFullScreen
														/>
													)
													}

													{modules.type == 'meeting' ? (
														<div>
															<div className="req-title-box">
																<h4 className="text-bold">Zoom Meeting</h4>
																<p style={{ color: '#969696', fontSize: '.85em' }}>
																	Submit jadwal untuk bertemu mentor, kamu akan di hubungi
																	melalui email atau telepon. Pastikan telepon kamu selalu
																	aktif
																</p>
															</div>
															<div className="request-cont">
																<button
																	disabled={this.state.loading}
																	onClick={this.requestMeeting}
																	className="req-button"
																>
																	<p
																		style={{
																			fontSize: '.9em',
																			fontWeight: 'bold',
																			marginBottom: 0
																		}}
																	>
																		Request your meeting schedule
																	</p>
																	{this.state.loading ? (
																		<React.Fragment>
																			<Spinner
																				type="grow"
																				color="light"
																				className="spinner-btn"
																				style={{ marginLeft: '15px' }}
																			/>
																			<Spinner
																				type="grow"
																				color="light"
																				className="spinner-btn"
																			/>
																			<Spinner
																				type="grow"
																				color="light"
																				className="spinner-btn"
																			/>
																		</React.Fragment>
																	) : <></>}
																</button>
															</div>
														</div>
													) : <></>}
													{}
												</div>
											) : <></>}
										</div>
									)
								}

								<Row style={{ margin: 0 }}>
									<Col sm="3" className="title-box">
										<span style={{ fontSize: '.9em' }}>Ikhtisar</span>
									</Col>
									{/* <Col xs='3' lassName='title-box' onClick={this.coomingSoon}>
                                        <span style={{fontSize: '.9em'}}>Q &amp; A</span>
                                    </Col>
                                    <Col xs='3' lassName='title-box' onClick={this.coomingSoon}>
                                        <span style={{fontSize: '.9em'}}>Announcements</span>
                                    </Col> */}
								</Row>
								<Row style={{ margin: 0, marginBottom: '1.5em' }}>
									<Col xs="12" className="crs-desc-box">
										<h4 className="text-bold">Tentang kursus ini</h4>
										<p
											dangerouslySetInnerHTML={{
												__html: modules.lesson_description ? modules.lesson_description : ''
											}}
										/>
									</Col>

									{modules.status == 'done' ? details.get_certificate == true ? (
										<Col xs="12" className="crs-desc-box">
											<Row style={{ margin: 0 }}>
												<Col sm="3" className="col-left">
													<p className="little-grey-text">Certificates</p>
												</Col>
												<Col sm="9" className="col-left">
													<p className="little-grey-text">
														Get Kerjah.com certificate by completing entire course
													</p>
													<br />
													<button className="cert-box" onClick={this.getCertificate}>
														Print Certificate
													</button>
												</Col>
											</Row>
										</Col>
									) : <></> : <></>}

									<Col xs="12" className="crs-desc-box">
										<Row style={{ margin: 0 }}>
											<Col sm="3" className="col-left">
												<p className="little-grey-text">Deskripsi</p>
											</Col>
											<Col sm="9" className="col-left">
												<p
													dangerouslySetInnerHTML={{
														__html: details.details
															? details.details.course_description
															: ''
													}}
												/>
											</Col>
										</Row>
									</Col>

									<Col xs="12" className="crs-desc-box" style={{ border: 0 }}>
										<Row style={{ margin: 0 }}>
											<Col sm="3" className="col-left">
												<p className="little-grey-text">Pengajar</p>
											</Col>
											<Col sm="9" className="col-left">
												<div className="author-cont">
													<Image
														width="85px"
														height="85px"
														src={
															details.authors_id ? details.authors_id.details
																.profile_picture_path !== '' ? (
																details.authors_id.details.profile_picture_path
															) : (
																DefaultImg
															) : (
																DefaultImg
															)
														}
														roundedCircle
													/>
													<div style={{ marginLeft: '2em' }}>
														<p className="text-bold" style={{ marginBottom: 0 }}>
															{details.authors_id ? (
																details.authors_id.details.firstname
															) : (
																''
															)}{' '}
															{details.authors_id ? (
																details.authors_id.details.lastname
															) : (
																''
															)}
														</p>
														<span style={{ fontSize: '.8em' }}>
															{details.authors_id ? (
																details.authors_id.details.job_title
															) : (
																''
															)}
														</span>
													</div>
												</div>
												<p
													dangerouslySetInnerHTML={{
														__html: details.authors_id
															? details.authors_id.details.profile_description
															: ''
													}}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>

							<CourseModule getModules={this.getModules} details={details} activeModule={modules} />

						</Row>
					</Container>
				</div>
			</React.Fragment>
			</>
		);
	}
}

const WrappedCookies = withCookies(CourseEnroll);

const mapStateToProps = (state) => {
	return {
		Course: state.Course
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ detailMyCourse, detailModules, checkAccessSection, checkValidVoucher, checkoutCourse }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedCookies);
