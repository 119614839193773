import React, { Component } from 'react'
import axios from 'axios'
import { Container, Row, Col, Progress } from 'reactstrap';
import RocketUp from '../assets/icon/rocket-up.png'
import RocketDown from '../assets/icon/rocket-down.png'
import Marker from '../assets/icon/marker.png'
import Nav from '../components/nav'

let API_URL = process.env.REACT_APP_API_URL

class VerifyAcount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verify: true,
            email: '',
            resend: null
          };
    }

    componentDidMount() {
        let { location } = this.props
        let searchArr = location.search.split('&')
        let token = searchArr[0].split('=')[1]
        let id = searchArr[1].split('=')[1]

        axios.post(`${API_URL}/api/verification`, {
            token,
            id
        })
        .then(res => {
            this.time = setTimeout(function() {this.props.history.push('/')}.bind(this), 5000)
        })
        .catch(err => {
            this.setState({verify: false})
        })
    }

    Resend = event => {
        event.preventDefault()
        let {email} = this.state
        axios.post(`${API_URL}/api/reverification`, {
            email
        })
        .then(res => {
            this.setState({resend: true})
            
        })
        .catch(err => {
            this.setState({resend: false})
        })
    }

    componentWillUnmount() {
        clearTimeout(this.time)
    }

    render() {
        let { verify, resend } = this.state
        return (
            <>
            <Nav/> 
            <React.Fragment>
                <Container style={{ padding: '2.2em 0' }}>
                    <Row>
                        {
                            verify ? (
                                <Col xs='12' style={{borderRadius: '20px', boxShadow: '5px 15px 20px #0000001A', padding: '5em 0', marginBottom: '1em'}}>
                                    <img src={RocketUp} style={{width: '10%'}}/>
                                    <h4 style={{fontWeight: 'bold', color: '#4A4A4A', margin: '1em 0 0 0'}}>Berhasil Aktivasi</h4>
                                    <p style={{ color: '#969696', fontSize: '.9em'}}>Email anda telah di verifikasi, silahkan login dengan email dan password Anda</p>
                                </Col>
                            ) : (
                                <Col xs='12' style={{borderRadius: '20px', boxShadow: '5px 15px 20px #0000001A', padding: '5em 0', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <img src={RocketDown} style={{width: '10%'}}/>
                                    {
                                        !resend ? (
                                            <>
                                                <h4 style={{fontWeight: 'bold', color: '#4A4A4A', margin: '1em 0 0 0'}}>Aktivasi Gagal</h4>
                                                <p style={{ color: '#969696', fontSize: '.9em'}}>Silakan masukan email untuk aktivasi kembali!</p>
                                                <div style={{ width: '450px', display: 'flex', flexWrap: 'wrap'}}>
                                                    <input type='text' placeholder='Enter Your Email Here' value={this.state.email} onChange={e => this.setState({email: e.target.value})} style={{border: '1px solid #EAEAEA', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', borderRight: 0, height: '2.2em', padding: '0 1em', width: '90%'}}/>
                                                    <button onClick={this.Resend} style={{backgroundColor: '#009194', height: '2.2em', width: '10%', border: 0, borderTopRightRadius: '5px', borderBottomRightRadius: '5px'}}>
                                                        <img src={Marker} style={{width: '60%'}}/>
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <h4 style={{fontWeight: 'bold', color: '#4A4A4A', margin: '1em 0 0 0'}}>A verification link has been sent to your email</h4>
                                        )
                                    }
                                </Col>
                            )
                        }
                        
                        
                    </Row>
                </Container>
            </React.Fragment>
        </>
        );
    }
}

export default VerifyAcount;