import { combineReducers } from "redux";
import Auth from './auth'
import Course from './course'
import Profile from './profile'
import MyCourse from './mycourse'
import Regist from './auth-regist'
import Location from './location'
import Checkout from './checkout'

export default combineReducers({
    Auth,
    Course,
    Profile,
    MyCourse,
    Regist,
    Location,
    Checkout
});