import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Form } from 'react-bootstrap'
import swal from 'sweetalert'
import axios from 'axios'

import Loading from './loading'
import numberWithComma from '../../utils/numberWithComma'
import Star from '../../assets/icon/star.png'
import Star_empty from '../../assets/icon/star-empty.png'

const api_url = process.env.REACT_APP_API_URL;

class Rating extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            rating: 0,
            ratingArr: [1,2,3,4,5],
            review: '',
            empty: false,
            loading: false
         };
    }

    sendReviewRating = () => {
        let {rating, review} = this.state
        if(rating !== 0 && review !== '') {
            this.setState({loading: true})
            axios.post(api_url + '/api/course/review', {
                courses_id: this.props.courses_id,
                rating,
                review
            }, {
                headers: {
                    Authorization: 'Bearer ' + this.props.token
                }
            })
            .then(res => {
                console.log(res);
                this.props.toggle()
                window.open(res.data.data.link_pdf, '_blank')
                this.setState({loading: false})
            })
            .catch(err => {
                this.setState({loading: false})
                swal({
                    title: "There's been an error occured",
                    text: 'Please check your internet and try again later',
                    icon: 'error'
                });
            })
        } else {
            this.setState({empty: true})
        }
        
    }
    render() {
        let { isOpen, toggle, } = this.props
        let { rating, ratingArr, empty, loading} = this.state
        return (
            <React.Fragment>
                <Loading isOpen={loading}/>
                <Modal isOpen={isOpen} toggle={toggle} size='md'>
                    <ModalHeader toggle={toggle}></ModalHeader>
                    <ModalBody>
                        <div style={{padding: '2.5em 1em', textAlign: 'center'}}>
                            <h4 style={{marginBottom: '1em'}}>Selamat kamu sudah lulus kursus ini</h4>
                            {
                                empty === true && (
                                    <p style={{color: 'red'}}><small>Rating dan Review wajib diisi</small></p>
                                )
                            }
                            <h6 style={{textAlign: 'left'}}>Berikan rating untuk kursus ini</h6>
                            <p style={{textAlign:"left", fontSize: '.9em'}}>Beritahu yang lain apa yang kamu pikirkan</p>
                            <div style={{
                                width: '80%',
                                display:'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                margin: '0 auto 1em auto'
                            }}>
                                {
                                    ratingArr.map((rat, id) => (
                                        <img src={ rat <= rating ? Star : Star_empty} style={{width: '8%'}} onClick={() => this.setState({rating: rat})}/>
                                    ))
                                }
                            </div>
                            
                            <div style={{
                                width: '90%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}>
                                <Form.Control 
                                    as="textarea" 
                                    name='answer_title' 
                                    rows="3" 
                                    style={{marginBottom: '1em'}}
                                    value={this.state.review}
                                    onChange={e => this.setState({review: e.target.value})}
                                    placeholder="Tuliskan Ulasan mu disini" />
                            </div>

                            <button onClick={this.sendReviewRating} className='q-btn-filled' style={{marginBottom: '1em', width: '100%', borderRadius: '5px'}}>
                                Submit
                            </button>
                            
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Rating;