import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label, Row, Col, Spinner } from 'reactstrap';

class RegistSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        let { isOpen, toggle } = this.props
        return (
            <Modal isOpen={isOpen} toggle={toggle} size='sm'>
                <div style={{textAlign: 'center', padding: '2em'}}>
                    Account has been successful registered!<br/>
                    Please check your email and verify your account
                </div>
            </Modal>
        );
    }
}

export default RegistSuccess;