import React, { Component } from 'react';
import { connect } from "react-redux";
import swal from 'sweetalert'
import { bindActionCreators } from "redux";
import { Modal, ModalBody, FormGroup, Input, Label, Row, Col, Spinner } from 'reactstrap';

import eye_slash from '../../../assets/icon/eye-slash.png';
import { signUp  } from "../../../store/actions/auth";
import eye from '../../../assets/icon/eye.png';
import RegistSuccess from './RegistSuccess';
import logo from './../../../assets/images/logo.png'

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            salutation: 'Mr',
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            password: '',
            agreement: false,
            message: '',
            loading: false,
            success: false,
            errorList: [],
            emailTaken: false,
            seePass: false
         };
    }

    componentDidUpdate(prevProps) {
        const { Regist } = this.props;
        if (prevProps.Regist.registRequest !== Regist.registRequest) {
            this.setState({loading: true})
        }

        if (prevProps.Regist.registAuthenticated !== Regist.registAuthenticated && (Regist.registAuthenticated == true) ) {
            // console.log(Regist);
            this.setState({loading: false}, () => swal({
                title: "Anda berhasil mendaftar di Kerjah.com!",
                text: "Mohon cek email Anda dan lakukan verifikasi.",
                icon: "success",
            }))
            this.props.toggle()
        }
        // if (!prevProps.Regist.error && Regist.error) {
        //     this.setState({
        //       message: "Please check your data",
        //       loading: false
        //     });
        // }
        
    }

    signup = event => {
        event.preventDefault()
        let { salutation, firstname, lastname, email, phone, password, agreement } = this.state

        if(phone.length < 10){
            this.setState({errorList : { phone : ['The phone must be at least 10 characters.'] }})
            return false
        }

        if(!agreement){
            swal('Mohon setujui Persyaratan dan kebijakan privasi')
            return false;
        }

        // console.log(this.state);
        if( salutation !== '' || firstname !== '' || lastname !== '' || email !== '' || password !== '' || agreement !== false){
            this.setState({message: ''})
            let reqBody = {
                salutation,
                firstname,
                lastname,
                email,
                phone,
                password
            }
            
            this.props.signUp(reqBody)
            .then(res => {
                
                if(res.type == 'REGIST_REQUEST_FAILED') {
                    console.log(res.payload.response)
                    let err = res.payload.response.data.data
                    let errList = err // Object.keys(err)
                    this.setState({
                        loading: false,
                        errorList: errList,
                        emailTaken: (err && err.length > 0 && err.email[0] == "The email has already been taken." ? true : false),
                        message: (err && err.length > 0 && err.email[0] == "The email has already been taken." ? err.email[0] : '')
                      });
                }
                
            })
        } else {
            this.setState({message: 'Please fill all the field below'})
        }
        
    }

    onChangeInput = event => {
        if(event.target.name == 'agreement') {
            // console.log(event.target);
            
            this.setState({
                [event.target.name]: event.target.checked,
              });
        } else {
            this.setState({
                [event.target.name]: event.target.value,
                });
        }
        
    };
    render() {
        console.log(this.state.errorList)

        let { isOpen, toggle, signIn} = this.props
        let { salutation, firstname, lastname, email, phone, password, agreement, loading, message, success, errorList, emailTaken, seePass } = this.state
        return (
            <React.Fragment>
                <RegistSuccess isOpen={success} toggle={() => this.setState({success: false})}/>
                <Modal isOpen={isOpen} toggle={toggle} size='md' centered>
                    <ModalBody>
                        <p className='text-center pt-4 pb-3'><img src={logo} alt='kerjah' /></p>
                        <p className='text-center text-24 mb-0'>Sign up</p>
                        <div className='register-form-wrapper'>
                            <p className='validation-message'>{message}</p>
                            <form className='auth-form' onSubmit={this.signup}>
                                <Row style={{width: '100%'}}>
                                    <Col sm='2' style={{paddingRight: 0}}>
                                        <div style={{marginBottom: '1em'}}>
                                            <Input 
                                                className='has-border-bottom'
                                                name='salutation'
                                                type='select'
                                                checked={salutation}
                                                onChange={this.onChangeInput}
                                                style={{ 
                                                    border: `1px solid ${errorList.salutation ? 'red' : '#C9C9C9' }` , 
                                                    width: '100%',
                                                    padding: '0', 
                                                }}>
                                                    <option value="Mr">Mr.</option>
                                                    <option value="Mr">Mrs.</option>
                                                    <option value="Ms">Miss</option>
                                            </Input>
                                            {
                                                errorList.salutation ? (<span style={{color: 'red', fontSize: '.8em'}}>{errorList.salutation[0]}</span>) : null
                                            }
                                        </div>
                                    </Col>

                                    <Col sm='5' style={{paddingRight: 0}}>
                                        <div style={{marginBottom: '1em'}}>
                                            <Input 
                                            placeholder='Firstname' 
                                            type='text' 
                                            name='firstname'
                                            value={firstname}
                                            onChange={this.onChangeInput}
                                            style={{ 
                                                border: `1px solid ${errorList.firstname ? 'red' : '#4A4A4A'}` , 
                                                width: '100%',
                                                padding: '.2em 1em'}}/>
                                            {
                                                errorList.firstname ? (<span className='validation-message'>{errorList.firstname[0]}</span>) : null
                                            }
                                        </div>
                                    </Col>
                                    <Col sm='5'>
                                        <div style={{marginBottom: '1em'}}>
                                            <Input 
                                            placeholder='Lastname' 
                                            type='text' 
                                            name='lastname'
                                            value={lastname}
                                            onChange={this.onChangeInput}
                                            style={{ 
                                                borderRadius: '3px', 
                                                border: `1px solid ${errorList.lastname ? 'red' : '#4A4A4A'}` , 
                                                width: '100%',
                                                padding: '.2em 1em'}}/>
                                            {
                                                errorList.lastname ? (<span className='validation-message'>{errorList.lastname[0]}</span>) : null
                                            }
                                        </div>
                                    </Col>
                                    <Col xs='12'>
                                        <div style={{marginBottom: '1em'}}>
                                            <Input 
                                            placeholder='Email' 
                                            type='text' 
                                            name='email'
                                            value={email}
                                            onChange={this.onChangeInput}
                                            style={{ 
                                                borderRadius: '3px', 
                                                border: `1px solid ${errorList.email ? 'red' : '#4A4A4A'}` , 
                                                width: '100%',
                                                padding: '.2em 1em'}}/>
                                            {
                                                errorList.email ? (<span className='validation-message'>{errorList.email[0]}</span>) : null
                                            }
                                        </div>
                                    </Col>
                                    <Col xs='12'>
                                        <div style={{marginBottom: '1em'}}>
                                            <Input 
                                            placeholder='Phone Number' 
                                            type='text' 
                                            name='phone'
                                            value={phone}
                                            onChange={this.onChangeInput}
                                            style={{ 
                                                borderRadius: '3px', 
                                                border: `1px solid ${errorList.phone ? 'red' : '#4A4A4A'}` , 
                                                width: '100%',
                                                padding: '.2em 1em'}}/>
                                            {
                                                errorList.phone ? (<span className='validation-message'>{errorList.phone[0]}</span>) : null
                                            }
                                        </div>
                                        <div style={{marginBottom: '1em'}}>
                                            <div 
                                                className='has-border-bottom'
                                                style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                border: `1px solid ${errorList.password ? 'red' : '#4A4A4A' }`, 
                                                borderRadius: '3px'}}>
                                            <Input 
                                            placeholder='Password' 
                                            type={seePass ? 'text' : 'password'} 
                                            name='password'
                                            value={password}
                                            onChange={this.onChangeInput}
                                            style={{
                                                marginBottom: '-1px',
                                                width: '100%', 
                                                padding: '.2em 1em',
                                                backgroundColor: 'transparent'}}/>
                                            <img alt='password' src={!seePass ? eye_slash : eye} style={{width: '20px'}} onClick={() => this.setState({seePass: !seePass})}/>  
                                            </div>
                                            {
                                                errorList.password ? (<span className='validation-message'>{errorList.password[0]}</span>) : null
                                            }
                                        </div>
                                   
                                    </Col>
                                    <Col xs='12' className='py-3'>
                                        <FormGroup check style={{textAlign: 'left',  marginLeft: 'auto', marginRight: 'auto', fontSize: '.85em'}}>
                                            <Label className='text-12' check>
                                                <Input type="checkbox" name='agreement' checked={agreement} onChange={this.onChangeInput}/>{' '}
                                                Saya bersedia menerima email tentang diskon dan rekomendasi pribadi yang menarik.
                                            </Label>
                                            <Label className='text-12' check>
                                                <Input type="checkbox" name='agreement' checked={agreement} onChange={this.onChangeInput}/>{' '} 
                                                <p style={{marginBottom: '1.5em'}}>Dengan mendaftar, Anda menyetujui <a rel='noreferrer' href='#' target='_blank'><span style={{color: '#009194'}}>Persyaratan</span></a> dan <a href='#' rel='noreferrer' target='_blank'><span style={{color: '#009194'}}>Kebijakan Privasi</span></a></p>
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col xs='12'>
                                        <button type='submit' className='register-button'>Daftar
                                            {
                                                loading ? (
                                                    <>
                                                        <Spinner className='loading-spinner' type="grow" color="light"/>
                                                        <Spinner className='loading-spinner' type="grow" color="light"/>
                                                        <Spinner className='loading-spinner' type="grow" color="light"/>
                                                    </>
                                                ) : null
                                            }
                                        </button>
                                    </Col>
                                    <Col xs='12'>
                                        {/* <p style={{marginBottom: '1.5em', fontSize: '.7em'}}>Dengan mendaftar, Anda menyetujui <a href='https://www.pigijo.com/term-and-condition' target='_blank'><span style={{color: '#FF5B00'}}>Persyaratan</span></a> dan <a href='https://www.pigijo.com/privacy-policy' target='_blank'><span style={{color: '#FF5B00'}}>Kebijakan Privasi</span></a></p> */}
                                    </Col>
                                </Row>
                            </form>
                        </div>
                        <p className='text-center text-bold'>Sudah punya akun? <span style={{color: '#009194', cursor: 'pointer'}} onClick={signIn}>Masuk</span></p>
                    </ModalBody>
                </Modal>
            </React.Fragment>
            
        );
    }
}

const mapStateToProps = state => {
    return {
      Regist: state.Regist,
    };
  };
  
const mapDispatchToProps = dispatch =>
    bindActionCreators({ signUp }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Signup);