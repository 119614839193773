import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Container, Nav, Navbar, Form, FormControl, Button, Row, Col, NavDropdown, Dropdown, DropdownButton, Badge } from 'react-bootstrap';
import {UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap'

import { setAuth, authLogout } from '../store/actions/auth'
import { getProfile } from '../store/actions/profile'
import Signin from './Modal/Auth/Signin.js';
import Signup from './Modal/Auth/Signup.js';
import logo from './../assets/images/logo.png'
import DefaultImg  from '../assets/images/default.png';

class index extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        this.state = {
            SigninOpen: false,
            SignUpOpen: false,
            uncompleteProfile: null
        };
    }

    componentDidMount() {
        let {cookies, dispatch} = this.props
        let cookie = cookies.getAll();
        if( cookie.hasOwnProperty('access_token')) {
            // console.log(cookie);
            
            let data = {
                data: {
                    access_token: cookie.access_token
                }
            }
            this.props.setAuth(data)
            .then(res => {
                this.props.getProfile({
                    Authorization: `Bearer ${cookie.access_token}`
                })
                .then(res => {
                    if(res.type == 'PROFILE_REQUEST_FAILED') {
                        cookies.remove('access_token')
                        // window.location.reload()
                    } else {
                        let profile = res.payload.data
                        if(profile.status.name == "Activated") {
                            this.setState({uncompleteProfile: false})
                        } else {
                            this.setState({uncompleteProfile: true})
                        }
                    }
                })
            })
        }
        
    }


    signOut = () => {
        let {cookies, dispatch, history} = this.props
        this.props.authLogout()
        .then(res => {
            cookies.remove('access_token')
            this.props.history.push('/')
        })
        .catch(err => {
            console.log(err);
            
        })
    }

    openSignin = event => {
        event.preventDefault()
        this.setState({
            SigninOpen: !this.state.SigninOpen,
            SignUpOpen: false
        })
    }

    openSignUp = event => {
        event.preventDefault()
        this.setState({
            SigninOpen: false,
            SignUpOpen: !this.state.SignUpOpen
        })
    }

    signUpToogle = event => {
        if(event) {
            event.preventDefault()
            this.setState({SignUpOpen: !this.state.SignUpOpen})
        } else {
            this.setState({SignUpOpen: false})
        }
        
    }

    signInToogle = event => {
        if(event) {
            event.preventDefault()
            this.setState({SigninOpen: !this.state.SigninOpen})
        } else {
            this.setState({SigninOpen: false})
        }
        
    }

    openSigninonly = () => {
        this.setState({SigninOpen: true})
    }
    render() {
        let { SigninOpen, SignUpOpen } = this.state
        let { Auth , Profile} = this.props        
        return (
            <React.Fragment>
                <Signup isOpen={SignUpOpen} toggle={this.signUpToogle} signIn={this.openSignin}/>
                <Signin isOpen={SigninOpen} toggle={this.signInToogle} signUp={this.openSignUp} openSigninonly={this.openSigninonly}/>
                <Navbar className="d-none d-md-block" style={{ backgroundColor: 'white', boxShadow: '5px 15px 20px #00000029', }}>
                    <Container>
                        <Row style={{width: '100%'}}>
                            <Col sm="2" md="3" style={{textAlign: 'left', display: 'flex', flexWrap: 'wrap'}}>
                                <Navbar.Brand >
                                    <Link to='/' style={{textDecoration: 'none'}}>
                                        <img src={logo} alt='Kerjah.com' style={{width: '100%'}}/>
                                    </Link>
                                    {/* <Badge variant="success" className="mx-2 badge-success">Demo</Badge> */}
                                </Navbar.Brand>
                            </Col>
                            <Col sm="10" md="9" style={{display: 'flex', justifyContent : 'flex-end'}}>
                                <React.Fragment>
                                    <Form inline>
                                        <Link to='/contact-us' className="mr-sm-2 nav-contact-button">Kontak Kami</Link> 
                                        {/* <a href='/demo' className="mr-sm-2 nav-contact-button">Demo</a>  */}
                                    </Form>
                                </React.Fragment>
                            </Col>
                        </Row>
                    </Container>
                </Navbar>
                <Navbar sticky="top" bg="light" expand="lg" className="d-block d-md-none">
                    <Container>
                        <Navbar.Brand>
                            <Link to='/' style={{textDecoration: 'none'}}><img src={logo} alt='Kerjah.com' style={{width: '100%'}}/></Link>
                            {/* <Badge variant="success" className="mx-2 badge-success">Demo</Badge> */}
                        </Navbar.Brand>
                        {Auth.data ? (
                            <>
                                <Dropdown align="end">
                                    <Dropdown.Toggle className="button-profile">
                                        <img 
                                            alt='profile'
                                            src={Profile.data ? (
                                                Profile.data.details.profile_picture_path ? (
                                                    Profile.data.details.profile_picture_path !== '' ? Profile.data.details.profile_picture_path : DefaultImg
                                                ) : DefaultImg
                                            ) : DefaultImg}                                                 
                                            style={{width: '40px', height: '40px', borderRadius: 50}}
                                        />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu variant="dark">
                                        <Dropdown.Item>
                                            <p className='mb-0 text-bold'>{Profile.data ? ( Profile.data.details.firstname + ' ' + Profile.data.details.lastname ) : null}</p>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            <span className='text-12'>{Profile.data ? Profile.data.email : null}</span>
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item><Link tag="b" to="/profile" style={{color: '#4A4A4A', lineHeight: '2'}}>Profil</Link></Dropdown.Item>
                                        <Dropdown.Item><Link tag="b" to="/my-course" style={{color: '#4A4A4A', lineHeight: '2'}}>Kursus Saya</Link></Dropdown.Item>
                                        <Dropdown.Item><Link tag="b" to="/change-password" style={{color: '#4A4A4A', lineHeight: '2'}}>Ubah Password</Link></Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item><Link tag="b" to="/history-transaction" style={{color: '#4A4A4A', lineHeight: '2'}}>Riwayat</Link></Dropdown.Item>
                                        <Dropdown.Item><span tag="b" onClick={this.signOut} className='logout-button'>Keluar</span></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </>
                            ) : (
                            <>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="me-auto m-menu-dropdown-nav">
                                    <Link to='/contact-us'>Kontak Kami</Link>
                                    <Link onClick={this.signInToogle}>Masuk</Link>
                                    <Link onClick={this.signUpToogle}>Daftar</Link>
                                </Nav>
                                </Navbar.Collapse>
                            </>
                            )}
                    </Container>
                </Navbar>
                {
                    Profile.data ? (
                        Profile.data.status.name !== 'Activated' ? (
                            <div style={{ backgroundColor: '#FF5215', boxShadow: '5px 15px 20px #00000029', textAlign: 'center', width: '100%', padding: '1em', textTransform: 'uppercase', fontSize: '1.1em', fontWeight: 'bold', color: '#fff'}}>
                                Please complete your profile data to take part in the course!
                            </div>
                        ) : null
                        
                    ) : null
                }
                
                <style jsx>{`
                    .logout-button {
                        color: #4A4A4A; 
                        line-height: 2; 
                        cursor: pointer
                    }
                    span.logout-button:hover {
                        color: #009194
                    }
                `}</style>
            </React.Fragment>
        );
    }
}

const WrappedCookies = withCookies(index)

const mapStateToProps = state => {
  return {
    Auth: state.Auth,
    Profile: state.Profile
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setAuth, getProfile, authLogout }, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedCookies));
