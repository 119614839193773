import axios from 'axios';

const api_url = process.env.REACT_APP_API_URL;

export const getCourse = (Headers) => async (dispatch) => {
	dispatch(CourseOnRequest());

	try {
		const request = await axios.get(api_url + '/api/course-list?per_page=5', { headers: Headers ? Headers : {} });

		return dispatch(CourseRequestSuccess(request.data.data));
	} catch (err) {
		return dispatch(CourseRequestFailed(err));
	}
};

export const detailCourse = (id, headers) => async (dispatch) => {
	try {
		const request = await axios.get(api_url + '/api/course-list?id=' + id, { headers });

		return request;
	} catch (err) {
		return err;
	}
};

export const detailMyCourse = (id, headers) => async (dispatch) => {
	try {
		const request = await axios.get(api_url + '/api/course/my-course?courses_id=' + id, { headers });

		return request;
	} catch (err) {
		return err;
	}
};

export const checkAccessSection = (body, headers) => async (dispatch) => {
	try {
		const request = await axios.post(api_url + '/api/section/check-access', body, {
			headers
		});

		return request;
	} catch (error) {}
};

export const detailModules = (params, headers) => async (dispatch) => {
	try {
		const request = await axios.get(api_url + '/api/module-detail', { headers, params });

		return request;
	} catch (err) {
		return err;
	}
};

export const CourseOnRequest = () => {
	return {
		type: 'COURSE_REQUEST'
	};
};

export const CourseRequestFailed = (err) => ({
	type: 'COURSE_REQUEST_FAILED',
	payload: err
});

export const CourseRequestSuccess = (payload) => ({
	type: 'COURSE_REQUEST_SUCCESS',
	payload
});
