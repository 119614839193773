import axios from "axios";

const api_url = process.env.REACT_APP_API_URL;

export const checkoutCourse = (body,headers) => async dispatch => {
    dispatch(CheckoutOnRequest())
  try {
    const request = await axios.post(
      api_url + "/api/course/checkout", body, {
      headers, crossDomain: true
    });
    
    return dispatch(CheckoutRequestSuccess(request.data));
  } catch (err) {
    return dispatch(CheckoutRequestFailed(err))
  }
};

export const checkoutPaymentMethod = (body, headers) => async dispatch => {
    try {
        const request = await axios.post(api_url + "/api/course/checkout-payment", body, {
            headers
          });
          
          return request;
    } catch (error) {
        
    }
}


export const finalPayment = (body, headers) => async dispatch => {
    try {
        const request = await axios.post(api_url + "/api/course/final-payment", body, {
            headers
          });
          
          return request;
    } catch (error) {
        return error
    }
}

export const getTransaction = (id, headers, page) => async dispatch => {
    try {
        const request = await axios.get(api_url + '/api/transaction?' + (id !== null ? 'id=' +  id : '') + (page ? page : ''), { headers}) 

        return request
    } catch (error) {
      return dispatch(getTransactionFailed(error)); 
    }
}

export const getTransactionFailed = () => {
  return {
    type: "GET_MY_TRANSACTION_FAILED",
  };
};

export const getTransactionDetail = (id, headers) => async dispatch => {
  try {
      const request = await axios.get(api_url + '/api/transaction-detail?transactions_id=' + id, { headers})

      return request
  } catch (error) {
      return error
  }
}

export const CheckoutOnRequest = () => {
    return {
      type: "CHECKOUT_REQUEST",
    };
  };
  
export const CheckoutRequestFailed = err => ({
    type: "CHECKOUT_REQUEST_FAILED",
    payload: err,
});
  
export const CheckoutRequestSuccess = payload => ({
    type: "CHECKOUT_REQUEST_SUCCESS",
    payload,
});