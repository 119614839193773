import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { instanceOf } from 'prop-types';
import swal from 'sweetalert';
import { withCookies, Cookies } from 'react-cookie';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { Image } from 'react-bootstrap';

import numberWithComma from '../../utils/numberWithComma';
import validateCookie from '../../utils/validateCookie';
import { getVoucher } from '../../store/actions/voucher';
import { checkoutCourse } from '../../store/actions/checkout';
import DefaultImg from '../../assets/images/default.png'
import Checklist from '../../assets/icon/check.png'

class BeforeEnroll extends Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};
	constructor(props) {
		super(props);
		this.state = {
			voucher: {},
			loading: false
		};
	}

	checkout = (voucher = null) => {
		let { cookies, history, details, params } = this.props;
		let token = validateCookie(cookies)
		if(token) {
			let body = { 
				courses_id: details._id, 
				voucher_code: voucher 
			};
			this.props.checkoutCourse(body, { 
				Authorization: `Bearer ${token}` 
			})
			.then(res => {
				this.setState({ loading: false });
				if (res.type == 'CHECKOUT_REQUEST_SUCCESS') {
					if (!res.payload.data.transaction?._id) {
						swal({
							title: 'Sukses',
							text: 'Transaksi berhasil dilakukan',
							icon: 'success'
						});
						history.push(`/course-enroll/${params.slug}`);
					} else {
						window.location.replace(`${res.payload.data.url}`);
					}
				} else {
					let err = res.payload.response.data.data;
					swal({
						title: err.courses ? err.message : err,
						text: (err.courses
							? `Selesaikan terlebih dahulu kursus ${err.courses.map((course) => course.course_title)}`
							: 'Please try again a few moment later'),
						icon: 'error'
					});
				}
			})
		}
	};

	checkProductAsync = async () => {
		try {
			let { details, cookies } = this.props;
			let token = validateCookie(cookies)
			if (token) {
				this.setState({ loading: true });
				if (details.is_free_access) {
					this.checkout();
				} else {
					const voucherConf = await swal({
						title: 'Apa kamu ingin menggunakan voucher?',
						text: '',
						buttons: true,
						buttons: [ 'Tidak, terimakasih', 'Ya gunakan' ]
					})

					if(voucherConf) {
						let voucherInput = await swal({
							title: 'Masukan kode voucher',
							content: 'input',
							buttons: [ 'Batal', 'Gunakan' ],
							closeOnClickOutside: false
						})

						if(voucherInput) {
							if(voucherInput === '') {
								swal({
									icon: 'warning',
									text: 'Maaf, kolom kupon harus diisi!',
									closeOnClickOutside: false
								})
							}
							let checkVoucher = await this.props.checkValidVoucher({ voucher_code: voucherInput}, { Authorization: 'Bearer ' +token })

							if(checkVoucher) {
								if(checkVoucher?.payload.data.voucher_code !== undefined) {
									this.setState({ loading: false });
									this.checkout(voucherInput);
									swal.close();
								} else {
									return swal({
										icon: 'warning',
										text: 'Kupon kamu tidak bisa digunakan',
										closeOnClickOutside: false
									});
								}
							}
						}
						
					} else {
						this.setState({ loading: false });
						this.checkout();
						swal.close();
					}
				}
			}
		} catch (err) {
			if (err) {
				swal('Peringatan', err, 'error');
			} else {
				this.setState({ loading: true });
				swal.close();
			}
		}
	}

	checkProduct = () => {
		let { details, cookies } = this.props;
		let token = validateCookie(cookies)
		if (token) {
			this.setState({ loading: true });
			if (details.is_free_access) {
				this.checkout();
			} else {
				swal({
					title: 'Apa kamu ingin menggunakan voucher?',
					text: '',
					buttons: true,
					buttons: [ 'Tidak, terimakasih', 'Ya gunakan' ]
				})
					.then((result) => {
						if (result) {
							swal({
								title: 'Masukan kode voucher',
								content: 'input',
								buttons: [ 'Batal', 'Gunakan' ],
								closeOnClickOutside: false
							}).then((input) => {
								if (input == '') {
									swal({
										icon: 'warning',
										text: 'Maaf, kolom kupon harus diisi!',
										closeOnClickOutside: false
									});
								}
								this.props.checkValidVoucher({ voucher_code: input}, { Authorization: 'Bearer ' +token })
								.then((checkVoucher) => {
									if(checkVoucher?.payload.data.voucher_code !== undefined) {
										this.setState({ loading: false });
										this.checkout(input);
										swal.close();
									} else {
										return swal({
											icon: 'warning',
											text: 'Kupon kamu tidak bisa digunakan',
											closeOnClickOutside: false
										});
									}
								})
							}).catch((err) => {
								if (err) {
									swal('Peringatan', err, 'error');
								} else {
									this.setState({ loading: true });
									swal.close();
								}
							});
						} else {
							this.setState({ loading: false });
							this.checkout();
							swal.close();
						}
					})
					.catch((err) => {
						if (err) {
							swal('Peringatan', err, 'error');
						} else {
							this.setState({ loading: true });
							swal.close();
						}
					});
			}
		}
	};

	render() {
		let { details } = this.props;

		return (
			<React.Fragment>
				<div className='banner-section'>
					<Container className="banner-cont py-3">
						<Row className="align-items-center">
							<Col sm="5">
								<h3 className="text-white font-weight-bold">{details.course_title}</h3>
								<p className="text-white">{details.course_subtitle}</p>
								<h2 className="text-white font-weight-normal">
									{details.is_free_access ? (
										'Free'
									) : (
										`Rp ${numberWithComma(+details.course_price)}`
									)}
								</h2>
								<button
									disabled={this.state.loading}
									className="buy-btn mb-3"
									onClick={this.checkProduct}
								>
									Beli sekarang
									{this.state.loading ? (
										<React.Fragment>
											<Spinner
												color="light"
												className="btn-spinner"
											/>
										</React.Fragment>
									) : null}
								</button>
							</Col>
							<Col sm="7">
								{details.preview_video_id ? details.preview_video_id !== '' ? (
									<iframe
										width="100%"
										height="345"
										src={`https://www.youtube.com/embed/${details.preview_video_id}`}
									/>
								) : null : (
									<img src={details.course_image_large} style={{ width: '100%' }} />
								)}
							</Col>
						</Row>
					</Container>
				</div>

				<Container className="text-left py-3" >
					<Row>
						<Col sm="8" style={{ paddingRight: '2em' }}>
							<div style={{ padding: '2em 0 1em 0' }}>
								<h4 className="sect-detail-title">Pengajar</h4>
								<div
									style={{
										display: 'flex',
										flexWrap: 'wrap',
										paddingBottom: '1em',
										alignItems: 'center'
									}}
								>
									{details.authors_id && details.authors_id.details.profile_picture_path !=='' ? (
										<Image
											width="85px"
											height="85px"
											src={details.authors_id.details.profile_picture_path}
											roundedCircle
											className="mr-4"
										/>
									) : (
										<Image
											width="85px"
											height="85px"
											src={DefaultImg}
											roundedCircle
											className="mr-4"
										/>
									)}
									<div>
										<p style={{ color: '#4A4A4A', fontWeight: 'bold', marginBottom: 0 }}>
											{details.authors_id ? details.authors_id.details.firstname : ''}{' '}
											{details.authors_id ? details.authors_id.details.lastname : ''}
										</p>
										<span style={{ fontSize: '.8em' }}>
											{details.authors_id ? details.authors_id.details.job_title : ''}
										</span>
									</div>
								</div>
								<p
									style={{ color: '#969696', fontSize: '.85em' }}
									dangerouslySetInnerHTML={{
										__html: details.authors_id ? details.authors_id.details.profile_description : ''
									}}
								/>
							</div>

							{details.objectives ? details.objectives.length > 0 ? (
								details.objectives.map((cur, idx) => (
									<>
										<h4 className="sect-detail-title">
											Yang akan Anda pelajari
										</h4>
										<ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
											<li
												key={idx}
												style={{
													float: 'left',
													width: '50%',
													color: '#969696',
													fontSize: '.9em',
													marginBottom: '5px'
												}}
											>
												<img
													src={Checklist}
													style={{ width: '3%', marginRight: '10px' }}
												/>
												{cur.objective}
											</li>
										</ul>
									</>
								))
							) : null : null}

							<div style={{ padding: '2em 0 1em 0' }}>
								<h4 className="sect-detail-title">
									Persyaratan
								</h4>
								<p
									style={{ color: '#969696', fontSize: '.9em' }}
									dangerouslySetInnerHTML={{
										__html: details.details ? details.details.course_requirement : ''
									}}
								/>
							</div>

							<div style={{ padding: '1em 0' }}>
								<h4 className="sect-detail-title">Deskripsi</h4>
								<p
									style={{ color: '#969696', fontSize: '.9em', lineHeight: 2 }}
									dangerouslySetInnerHTML={{
										__html: details.details ? details.details.course_description : ''
									}}
								/>
							</div>
						</Col>

						<Col sm="4">
							<div
								style={{
									backgroundColor: '#F7F7F7',
									borderRadius: '10px',
									boxShadow: '0px 0px 5px #00000020',
									padding: '1.5em'
								}}
							>
								<h3 style={{ color: '#4A4A4A',}}>
										{details.is_free_access ? (
											'Free'
										) : (
											`Rp ${numberWithComma(+details.course_price)}`
										)}
								</h3>
								<button
									disabled={this.state.loading}
									className="buy-btn btn-block mb-4"
									onClick={this.checkProduct}
								>
									Beli sekarang
									{this.state.loading ? (
										<React.Fragment>
											<Spinner
												color="light"
												style={{ width: '1em', height: '1em', marginLeft: '15px' }}
											/>
										</React.Fragment>
									) : null}
								</button>
								{/* {console.log('++', details)} */}
								{details.target ? (
									<>
										<h5 style={{ color: '#4A4A4A', marginBottom: '1em' }}>
											Kursus ini mencakup :
										</h5>
										<ul style={{ paddingLeft: 0, listStyleType: 'none' }}>
											{details.targets.map((target, idx) => (
												<li style={{ fontSize: '.9em', color: '#969696', marginBottom: '5px' }}>
													- {target.target}
												</li>
											))}
										</ul>
									</>
								) : null}
								
							</div>
						</Col>
					</Row>
				</Container>
			</React.Fragment>
		);
	}
}

const WrappedCookies = withCookies(BeforeEnroll);

const mapStateToProps = (state) => {
	return {
		Profile: state.Profile
	};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ checkoutCourse, getVoucher }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedCookies);
