import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Container, Row, Col, Card, Form, Image, Button} from 'react-bootstrap'
import { Input, Spinner } from 'reactstrap'
import Pagination from 'react-js-pagination'
import swal from 'sweetalert';
import qs from 'qs'
import moment from 'moment'
import 'moment/locale/id'

import { getTransaction } from '../store/actions/checkout'
import numberWithComma from '../utils/numberWithComma'
import success from '../assets/icon/ic_success.png'
import Loading from '../components/Modal/loading'
import cancel from '../assets/icon/ic_cancel.png'
import clock from '../assets/icon/ic_clock.png'
import Menu from '../components/Auth/menu'
import Nav from '../components/nav'

moment.locale('id')

class HistoryTransaction extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            transaction: [],
            pagination: {
                page: 1,
                total_page: 1,
                per_page: 1,
                total: 1,
                total_counting: 1
            },
            loading: false
         };
    }

    componentDidMount() {
        let { cookies, history } = this.props
        let cookie = cookies.getAll()
        this.setState({loading: true})
        if( cookie.hasOwnProperty('access_token')) {
            this.props.getTransaction(null, {Authorization: 'Bearer ' + cookie.access_token}, 'per_page=5&order_by=created_at desc')
                .then(res => {
                    console.log(res, 'transaction');
                    if(res.data) {
                        let temp = this.state.pagination
                        let data = res.data.data

                        temp.page = data.current_page
                        temp.total_page = data.last_page
                        temp.per_page = data.per_page
                        temp.total = data.total
                        temp.total_counting = data.to
                        this.setState({transaction: res.data.data.data, pagination: temp, loading: false})
                    } else {
                        if(res.type == 'GET_MY_TRANSACTION_FAILED'){
                            swal({
                                title: 'Failed',
                                text: res && res.payload && res.payload && res.payload.response && res.payload.response.data ? res.payload.response.data.data : 'Please check your connection or try again later',
                                icon: 'warning'
                            })
                            .then(res => {
                                history.push('/profile/edit')
                            })
                        }
                    }
                    
                })
        } else {
            history.push('/')
        }
    }

    ChangePagination = (pagenumber) => {
        let {cookies, history} = this.props
        let cookie = cookies.getAll();
        this.setState({loading: true})
        this.props.getTransaction(null,{
            Authorization: `Bearer ${cookie.access_token}`
        }, 'page=' + pagenumber + '&per_page=5&order_by=created_at desc')
        .then(res => {
            let temp = this.state.pagination
            let data = res.data.data

            temp.page = data.current_page
            temp.total_page = data.last_page
            temp.per_page = data.per_page
            temp.total = data.total
            temp.total_counting = data.to
            this.setState({transaction: res.data.data.data, pagination: temp, loading: false})
        })
        .catch(err => {
            this.setState({ loading: false })
        })
    }

    render() {        
        let { transaction, loading, pagination } = this.state
        return (
            <>
            <Nav/>
            <React.Fragment>
                <Loading isOpen={loading}/>
                <div className='mt-2 container'>
                    <Row>
                        <Col sm='12'>

                            <div style={{ backgroundColor: '#EDEDED', backgroundImage: `url(${require('../assets/images/background.png')})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                                <Card style={{ borderRadius: '15px' }} className='bq-light'>
                                    <Row style={{margin: 0}}>
                                        <Menu/>
                                        <Col sm='8' className="pt-3 text-left">
                                            <h3 style={{ color: '#4A4A4A', marginBottom: '1em' }}>Riwayat</h3>
                                            {
                                                transaction.length > 0 ? (
                                                <React.Fragment>
                                                    {
                                                        transaction.map((trans, idx) => (
                                                            <div key={idx} className="rounded mb-4" style={{boxShadow: '0px 0px 5px #00000020'}}>
                                                                <Row style={{margin: 0, padding: '1em 0', display: 'flex', alignItems:'flex-end'}}>
                                                                    <Col sm='5'>
                                                                        <span style={{color: '#a2afc5', fontSize: '.7em', fontWeight: 'bold'}}>TANGGAL TRANSAKSI</span>
                                                                        <p style={{color: '#000', fontSize: '.9em', marginBottom: 0}}>{moment(trans.created_at).format('llll')}</p>
                                                                    </Col>

                                                                    <Col sm='4'>
                                                                        <span style={{color: '#a2afc5', fontSize: '.7em', fontWeight: 'bold'}}>KODE TRANSAKSI</span>
                                                                        <p style={{color: '#000', fontSize: '.9em', marginBottom: 0}}>{trans.trans_code ? trans.trans_code : trans._id}</p>
                                                                    </Col>
                                                                    <Col sm='3' style={{textAlign: 'right'}}>
                                                                        <p style={{color: '#000', fontSize: '.9em', marginBottom: 0}}>Rp {numberWithComma(trans.total_paid)}</p>
                                                                    </Col>
                                                                </Row>
                                                                <Row style={{margin: 0, padding: '1em 0', display: 'flex', alignItems:'flex-end', backgroundColor: '#edf0f5', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px'}}>
                                                                    <Col sm='6'>
                                                                        <p style={{color: '#000', fontSize: '.9em', marginBottom: 0, verticalAlign: 'middle'}}>
                                                                            <img src={trans.status ? (
                                                                                trans.status == 'success' ? success : (
                                                                                    trans.status == 'awaiting payment' ? clock : cancel
                                                                                )
                                                                            ) : ''} style={{width: '5%', marginRight: '5px'}}/>{trans.status ? trans.status : ''}
                                                                        </p>
                                                                    </Col>

                                                                    <Col sm='3'>
                                                                        <Link to={'/history-transaction/' + trans._id}>
                                                                            <p style={{color: '#009194', fontSize: '.9em', marginBottom: 0}}>View Details</p>
                                                                        </Link> 
                                                                    </Col>
                                                                    {
                                                                            trans.status == 'awaiting payment' && 
                                                                            <Col sm='3'>
                                                                                <a href={trans.snap_url} >
                                                                                    <p style={{color: '#009194', fontSize: '.9em', marginBottom: 0}}>Continue Payment</p>
                                                                                </a>
                                                                            </Col>
                                                                    }
                                                                </Row>
                                                            </div>
                                                        ))
                                                    }
                                                </React.Fragment>
                                                ) : (
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems:'center',
                                                        flexDirection: 'column', 
                                                        padding: '2em 0'}}>
                                                        <h4>Belum Ada riwayat transaksi, lihat kursus lainnya.</h4>
                                                        <button style={{
                                                            backgroundColor: '#009194',
                                                            color: '#fff',
                                                            border: 0,
                                                            borderRadius: '10px',
                                                            boxShadow: '0  17px 10px -10px rgba(0,0,0,0.1)',
                                                            padding: '.5em 1.5em',
                                                            marginTop: '2em'
                                                        }} onClick={() => this.props.history.push('/')}>
                                                            Kembali ke Homepage
                                                        </button>
                                                    </div>
                                                )
                                            }
                                            
                                            {
                                                transaction.length > 0 ? (
                                                    <Row style={{margin: 0, padding: '1.5em 0'}}>
                                                        <Pagination
                                                            activePage={pagination.page}
                                                            itemsCountPerPage={pagination.per_page}
                                                            totalItemsCount={pagination.total}
                                                            pageRangeDisplayed={3}
                                                            // pageCount={3}
                                                            onChange={(nextPage) => this.ChangePagination(nextPage)}
                                                        />
                                                    </Row>
                                                ) : null
                                            }
                                            
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
            </>                                            
        );
    }
}

const WrappedCookies = withCookies(HistoryTransaction)

const mapStateToProps = state => {
  return {
    Profile: state.Profile
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({getTransaction }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedCookies);