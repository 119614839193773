import axios from "axios";

const api_url = process.env.REACT_APP_API_URL;

export const getMyCourse = (headers, page) => async dispatch => {
  dispatch(CourseOnRequest());

  try {
    const request = await axios.get(api_url + `/api/course/my-course?per_page=10&${page ? page : ''}`, {
      headers
    });
    
    return dispatch(CourseRequestSuccess(request.data));
  } catch (err) {
    return dispatch(CourseRequestFailed(err));
  }
};

export const CourseOnRequest = () => {
    return {
      type: "MY_COURSE_REQUEST",
    };
  };
  
export const CourseRequestFailed = err => ({
    type: "MY_COURSE_REQUEST_FAILED",
    payload: err,
});
  
export const CourseRequestSuccess = payload => ({
    type: "MY_COURSE_REQUEST_SUCCESS",
    payload,
});