import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Container, Row, Col, Card, Form, Image, Button} from 'react-bootstrap'
import { Input, FormGroup, Label } from 'reactstrap'
import swal from 'sweetalert'
import Countdown from 'react-countdown';

import Loading from '../components/Modal/loading'
import numberWithComma from '../utils/numberWithComma'
import { getPaymentMaps, setPaymentMethod, getEWallet } from '../store/actions/payment'
import { checkoutPaymentMethod, finalPayment, getTransaction} from '../store/actions/checkout'

const api_url = process.env.REACT_APP_API_URL;
let ENV = process.env.REACT_APP_ENV

const addLeadingZeros = (value) => {
    value = String(value);
    while (value.length < 2) {
      value = '0' + value;
    }
    return value;
}

const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span style={{color: '#FF5216',fontSize: '.85em',}}>Transaction has been expired</span>;
    } else {
      // Render a countdown
      return <span style={{color: '#FF5216',fontSize: '.85em',}}>Complete Payment in {addLeadingZeros(hours)}:{addLeadingZeros(minutes)}:{addLeadingZeros(seconds)}</span>;
    }
};
  

class PaymentPage extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        this.state = { 
            paymentMaps: [],
            voucher: '',
            activation: false,
            pay: false,
            amount: 0,
            secret_key: '',
            product_id: '',
            balance: null,
            details: {},
            transaction_voucher: null,
            loading: false,
            dipayVoucherUse: false
         };
    }

    componentDidMount() {
        let { cookies, match: {params}, history } = this.props
        let cookie = cookies.getAll()
        this.setState({loading: true})
        if( cookie.hasOwnProperty('access_token')) {
            this.props.getPaymentMaps({Authorization: 'Bearer ' + cookie.access_token})
            .then(res => {
                this.setState({paymentMaps: res.data.data})
                
                this.props.getTransaction(params.id, {Authorization: 'Bearer ' + cookie.access_token})
                .then(res => {
                    console.log(res, 'transaction');
                    if(res.data.data.vouchers_id !== "" ) {
                        this.setState({voucher: res.data.data.vouchers_id.voucher_code, dipayVoucherUse: true})
                    }
                    this.setState({details: res.data.data, transaction_voucher: res.data.data.vouchers_id, loading: false})
                })
            })
            
        } else {
            history.push('/')
        }
        
    }

    payment = (selected) => {
        let { paymentMaps, voucher, details} = this.state
        let { Chcekout, match: {params}, cookies} = this.props
        let cookie = cookies.getAll()

        if(new Date(details.time_limit) > new Date()) {
            this.props.checkoutPaymentMethod({
                transactions_id: params.id,
                voucher_code: voucher,
                payment_maps_id: selected._id
            }, {Authorization: 'Bearer ' + cookie.access_token})
            .then(res => {
                
                let data = res.data.data
                console.log(data);
                if(data.mid === 'dipay') {
                    if(res.data.data.secret_key !== '') {
                        this.setState({pay: true, amount: data.transaction.total_paid, secret_key: data.secret_key, product_id: details.trans_code,})
                    } else {
                        this.setState({activation: true, amount: data.transaction.total_paid, product_id: details.details[0].trans_code,})
                    }
                } else {
                    window.location.href=data.payment_url
                }
                
            })
            .catch(err => {
                console.log(err);
                
            })
        } else {
            swal({
                title: 'Transaction has been Expired',
                icon: 'warning'
            })
            .then(res => (
                this.props.history.push('/')
            ))
        }
        
    }


    finalStep = data => {
        let { paymentMaps, details} = this.state
        let { Chcekout, match: {params}, cookies, history} = this.props
        let cookie = cookies.getAll()
        let dipay = paymentMaps.find(pm => { return pm.mid == 'dipay'})
        
        if(data.statusCode == 200) {
            this.props.finalPayment({
                transactions_id: params.id,
                mid: dipay.mid,
                status: data.data.payment
            }, {Authorization: 'Bearer ' + cookie.access_token})
            .then(res => {
                this.setState({loading: false})
                if(res.status == 200) {
                    if(res.data.data == "Enroll success") {
                        
                        
                        swal({
                            title: "Success",
                            text: "Your payment has been completed",
                            icon: "success",
                        })
                        history.push(`/course-enroll/${details.details[0].courses_id}`)
                    } else {
                        swal({
                            title: "Failed",
                            text: "Please try again in a few minutes",
                            icon: "error",
                        })
                    }
                } else {
                    swal({
                        title: "Failed",
                        text: "Please try again in a few minutes",
                        icon: "error",
                    })
                }
                
                
            })
        }
    }

    useVoucher = e => {
        e.preventDefault()
        console.log('trying to be sane');
        let { voucher } = this.state
        let { match: {params}, cookies} = this.props
        let cookie = cookies.getAll()
        if(voucher !== '') {
            axios.post(api_url + '/api/voucher/applied' , {
                transactions_id: params.id,
                voucher_code: voucher
            }, {
                headers: {
                    Authorization: 'Bearer ' + cookie.access_token
                }
            })
            .then(res => {
                console.log(res);
                this.props.getTransaction(params.id, {Authorization: 'Bearer ' + cookie.access_token})
                .then(res => {
                    if(res.data.data.vouchers_id !== "" ) {
                        this.setState({voucher: res.data.data.vouchers_id.voucher_code})
                    }
                    this.setState({details: res.data.data, transaction_voucher: res.data.data.vouchers_id, dipayVoucherUse: true})
                })
            })
            .catch(err => {
                this.setState({voucher: ''})
                swal({
                    title: err.response.data.data,
                    icon: 'error'
                })
                
            })
        }
    }

    render() {
        let {Checkout, match: {params }} = this.props
        let {details, paymentMaps, dipayVoucherUse} = this.state
        console.log(details);
        
        return (
            <React.Fragment>
                <Loading isOpen={this.state.loading}/>
                <div style={{ backgroundColor: '#EDEDED', 
                padding: '2% 25%', backgroundImage: `url(${require('../assets/images/background.png')})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                    <Card style={{ borderRadius: '15px', padding: 0 }} className='bq-light'>
                        <Row style={{margin: 0}}>
                            <Col xs='12' style={{textAlign: 'left', padding: '2em 10%'}}>
                                <div style={{
                                    width: '90%',
                                    backgroundColor: '#FFDFD5',
                                    textAlign: 'center',
                                    borderRadius: '10px',
                                    padding: '.7em 0',
                                    marginRight: 'auto',
                                    marginLeft: 'auto',
                                    color: '#FF5216',
                                    fontSize: '.85em',
                                    marginBottom: '1em'
                                }}>
                                    <Countdown
                                        date={new Date(details.time_limit ? details.time_limit : '')}
                                        renderer={renderer}
                                    />
                                </div>

                                <p style={{color: '#969696', fontSize: '.9em', marginBottom: 0}}>You’re course ID</p>
                                <h4 style={{fontWeight: 'bold', color: '#FF5B00', marginBottom: 0}}>{details.trans_code}</h4>

                                <Row style={{margin: 0, paddingTop: '.7em 0'}}>
                                    <Col xs='6' style={{paddingLeft: 0, color: '#969696', fontSize: '.9em'}}>
                                        <p style={{marginBottom: '3px'}}>Total Price</p>
                                        <p style={{marginBottom: '3px'}}>Discount</p>
                                        <p style={{marginBottom: '3px'}}>Total Paid</p>
                                    </Col>
                                    <Col xs='6' style={{paddingRight: 0, textAlign: 'right', color: '#969696', fontSize: '.9em'}}>
                                        <p style={{marginBottom: '3px'}}>Rp {numberWithComma(details.total_price ? details.total_price: 0)}</p>
                                        <p style={{marginBottom: '3px'}}>{details.total_discount ? (details.total_discount !== 0 ? `Rp ${numberWithComma(details.total_discount)}` : '-') : '-'}</p>
                                        <p style={{marginBottom: '3px', fontWeight: 'bold', color: '#FF5B00'}}>Rp {numberWithComma(details.total_paid ? details.total_paid: 0)}</p>
                                        <p style={{marginBottom: '3px'}}>Detail</p>
                                    </Col>
                                </Row>

                                <p style={{color: '#969696', fontSize: '.9em', marginBottom: '10px'}}>Have a voucher code?</p>
                                <div style={{width: '85%', display: 'flex', flexWrap: 'wrap', marginBottom: '10px'}}>
                                    <form style={{width: '100%'}} onSubmit={this.useVoucher}>
                                        <input
                                        style={{
                                            width: '80%',
                                            borderTopLeftRadius: '7px',
                                            borderBottomLeftRadius: '7px',
                                            border: '1px solid #EAEAEA',
                                            padding: '.5em 1em',
                                            fontSize: '.9em'
                                        }}
                                        placeholder='Voucher Code'
                                        value={this.state.voucher}
                                        onChange={e => this.setState({voucher: e.target.value  })}/>
                                        <button
                                        style={{
                                            width: '20%',
                                            borderTopRightRadius: '7px',
                                            borderBottomRightRadius: '7px',
                                            backgroundColor: '#FF5B00',
                                            color: '#fff',
                                            textTransform: 'uppercase',
                                            textAlign: 'center',
                                            padding: '.5em 0',
                                            border: 0
                                        }} type='submit'>
                                            USE
                                        </button>
                                    </form>
                                    
                                </div>
                                <span style={{color: '#969696', fontSize: '.8em',}}>By clicking the button bellow, you agree to Kerjah.com <span style={{color: '#009194'}}>term &amp; conditions</span> and <span style={{color: '#FF5B00'}}>Privacy Policy</span></span>

                                <div style={{marginTop: '2em'}}>
                                    <h4 style={{fontWeight: 'bold', color: '#4A4A4A',}}>Choose payment</h4>
                                    
                                    {
                                        paymentMaps.map((PM, idx) => (
                                            <button disabled={PM.mid !== 'dipay' ? (dipayVoucherUse) : false} key={idx} onClick={() => this.payment(PM)} style={{
                                                width: '94%',
                                                backgroundColor: (PM.mid !== 'dipay' ? ( dipayVoucherUse ? '#ededed' : '#F7F7F7') : '#F7F7F7'),
                                                borderRadius: '10px',
                                                border: 0,
                                                boxShadow: '5px 15px 20px #0000001A',
                                                padding: '1em 2em',
                                                position: 'relative',
                                                textAlign: 'left',
                                                display: 'grid',
                                                marginBottom: '1.2em'
                                            }}>
                                                <span style={{color: '#4A4A4A', fontWeight: 'bold', verticalAlign: 'center', fontSize: '1.1em'}}><img src={PM.payment_channels_id.logo_path} style={{width: '6%', marginBottom: '10px', marginRight: '10px'}}/> {PM.payment_channels_id.name}</span>
                                                <span style={{color: '#969696', fontSize: '.8em',}}>{PM.payment_channels_id.description}</span>
                                                {
                                                    PM.status == 'active' ? null : (
                                                        <img src={require('../assets/icon/coming-soon.png')} style={{width: '13%', position: 'absolute', right: 25, top: 0}}/>
                                                    )
                                                }
                                                
                                            </button>
                                        ))
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </React.Fragment>
        );
    }
}

const WrappedCookies = withCookies(PaymentPage)

const mapStateToProps = state => {
  return {
    Chcekout: state.Checkout
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getPaymentMaps, getEWallet ,setPaymentMethod, checkoutPaymentMethod, finalPayment, getTransaction }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedCookies);

