import axios from "axios";
import swal from 'sweetalert';

const api_url = process.env.REACT_APP_API_URL;

export const getProfile = header => async dispatch => {
  dispatch(ProfileOnRequest());

  try {
    const request = await axios.get(api_url + "/api/user/profile", { headers: header});
    
    return dispatch(ProfileRequestSuccess(request.data));
  } catch (err) {
    return dispatch(ProfileRequestFailed(err));
    
  }
};

export const changeProfile = (body, header, newData) =>  async dispatch => {
  dispatch(ProfileOnRequest());

  try {
    const request = await axios.put(api_url + "/api/user/update", body, { headers: header});
    
    return dispatch(ChangeProfileRequestSuccess());
  } catch (err) {
    return dispatch(ChangeProfileRequestFailed(err));
  }
};

export const changePassword = (token, body) => async dispatch => {
  try {
    const request = await axios.post(api_url + "/api/change-password-profile", body, { headers: { Authorization: `Bearer ${token}`}});
    
    return request
  } catch (err) {
    return err
  }
}

export const ProfileOnRequest = () => {
    return {
      type: "PROFILE_REQUEST",
    };
  };
  
export const ProfileRequestFailed = err => ({
    type: "PROFILE_REQUEST_FAILED",
    payload: err,
});
  
export const ProfileRequestSuccess = payload => ({
    type: "PROFILE_REQUEST_SUCCESS",
    payload,
});

export const ChangeProfileRequestFailed = err => ({
  type: "CHANGE_PROFILE_REQUEST_FAILED",
  payload: err,
});

export const ChangeProfileRequestSuccess = payload => ({
  type: "CHANGE_PROFILE_REQUEST_SUCCESS",
  payload,
});