import React, { Component } from 'react'
import axios from 'axios'
import { Modal, ModalHeader, ModalBody, Input, Spinner, ModalFooter } from 'reactstrap';

let API_URL = process.env.REACT_APP_API_URL

class ForgotPass extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: false,
            email: '',
            message: '',
            error: false,
            empty: false
         };
    }

    onChangeInput = event => {
        this.setState({
          [event.target.name]: event.target.value,
        });
    };

    resetPass = async (event) => {
        try {
            event.preventDefault()
            let {email} = this.state
            this.setState({loading: true})
            if(email !== '') {
                let req = await axios.post(API_URL + '/api/forgot-password', {email})

                if(req) {
                    this.setState({
                        loading: false,
                        message: 'Silakan cek email Anda untuk melanjutkan proses reset sandi',
                        error: false
                    })
                }                
            } else {
                this.setState({
                    empty: true,
                    loading: false
                })
            }
        } catch (error) {                        
            this.setState({
                loading: false,
                message: error.response.data.data,
                error: true
            })
        }
        
    }

    render() {
        let { isOpen, toggle, openingSignin} = this.props
        let { loading, message, error, empty } = this.state
        return (
            <React.Fragment>
                <Modal isOpen={isOpen} toggle={toggle} size='md'>
                    <ModalHeader toggle={toggle}>
                        <h5>Lupa Sandi</h5>
                    </ModalHeader>
                    <ModalBody>
                        <div className='p-1 text-center'>
                            <p style={{color: (error ? 'red' : '#4A4A4A'), fontSize: '.9em'}}>{message}</p>
                            <form onSubmit={this.resetPass} className='d-flex flex-column flex-wrap align-items-center'>
                                <Input 
                                name='email'
                                placeholder='Email' 
                                type='text'
                                className='txt-field'
                                style={{ border: `1px solid ${empty ? 'red' : '#4A4A4A' }` , }} 
                                value={this.state.email}
                                onChange={this.onChangeInput}
                                />
                                {
                                    empty ? (<span style={{color: 'red', fontSize: '.8em'}}>Email field is required</span>) : null
                                }
                                <button 
                                className='login-button' type='submit' disabled={loading}>
                                    Reset Sandi
                                    {
                                        loading ? (
                                            <>
                                                <Spinner type="grow" color="light" style={{width: '1em', height: '1em', marginLeft: '15px'}}/>
                                                <Spinner type="grow" color="light" style={{width: '1em', height: '1em', marginLeft: '5px'}}/>
                                                <Spinner type="grow" color="light" style={{width: '1em', height: '1em', marginLeft: '5px'}}/>
                                            </>
                                        ) : null
                                    }
                                </button>
                            </form>         
                        </div>
                    </ModalBody>
                    <ModalFooter style={{justifyContent: 'center', padding: '2em 0'}}>
                        <p>atau <span style={{color: '#009194', cursor: 'pointer'}} onClick={ openingSignin }>Masuk</span></p>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ForgotPass;