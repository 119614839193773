import axios from "axios"      

const appHeaderProperties = {
  "Accept" : 'application/json', 
}
 
const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
    c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
    return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const apiCall = async ({ method, url, data = "", baseURL , headers = {} }, navigation) => {  
 
  const access_token = getCookie('access_token')
  if(access_token){ 
    appHeaderProperties.Authorization = "Bearer "+access_token
  }
  
  let head = { 
    ...appHeaderProperties,   
    ...headers
  };
 
  try { 
    const payloadHttp = {
      baseURL: baseURL || process.env.REACT_APP_API_URL,
      method: method,
      url: url,
      data: data.data || "",
      headers: head || "",
      params: data.params || "",
      timeout: data.timeout || 0,
      'axios-retry': {
        retries: 0
      }
    }

    const response = await axios(payloadHttp)
    .then((success) => { return  success })  
    .catch(function (error) {   
       let code = 500 
        if(error && error.response && error.response.data) code = error.response.data.meta.code 
         
        if(error.response && error.response.data) return error.response.data
    })
 
    return response
  } catch (error) {  
    console.log(error)
  }
}