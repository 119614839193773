import React, { Component } from 'react'
import axios from 'axios'
import swal from 'sweetalert'
import { Container, Row, Col, Progress } from 'reactstrap'

import eye from '../assets/icon/eye.png'
import Loading from '../components/Modal/loading'
import eye_slash from '../assets/icon/eye-slash.png'

let API_URL = process.env.REACT_APP_API_URL

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            password: '',
            loading: false,
            error: false,
            done: null,
            tokenStatus: null,
            message: '',
            seePass: false,
            loading: false
         };
    }

    componentDidMount() {
        let { location } = this.props
        let token = location.search.split('=')[1]
        let url_string = window.location.href;
        let current_url = new URL(url_string);
        let lang = current_url.searchParams.get("token");
        
        axios.get(`${API_URL}/api/change-password?token=${token}`)
        .then(res => {
            this.setState({tokenStatus: true})
            
        })
        .catch(err => {
            this.setState({tokenStatus: false, error: true})
            
        })
    }

    componentWillUnmount() {
        clearTimeout(this.time)
    }

    setPass = event => {
        if(event.target.value.length < 8) {
            this.setState({
                password: event.target.value,
                message: 'Password minimal 8 karakter'
            })
        } else {
            this.setState({
                password: event.target.value,
                message: ''
            })
        }
    }

    changePass = event => {
        event.preventDefault()
        let { password, message } = this.state
        let { location } = this.props
        let token = location.search.split('=')[1]
                
        if(password !== '' && message == '') {
            swal({
                title: "Are you sure?",
                text: "Your password will be changed",
                buttons: {
                    cancel: 'Cancel',
                    proceed: true
                }
            })
            .then((value) => {
                if(value === "proceed") {
                    this.setState({loading: true})
                     axios.put(`${API_URL}/api/change-password`, {
                        token,
                        password
                    })
                    .then(res => {                
                        this.setState({done: true, loading: false})
                        this.time = setTimeout(function() {this.props.history.push('/')}.bind(this), 5000)
                    })
                    .catch(err => {                
                        this.setState({error: true, loading: false})
                    })
                }
            })
           
        }
    }

    render() {
        let { tokenStatus, done, error, message, seePass } = this.state
        return (
            <React.Fragment>
                <Loading isOpen={this.state.loading}/>
                <Container style={{ padding: '2.2em 0' }}>
                    <Row>
                        {
                            (tokenStatus || !error) ? (
                                <Col xs='12' style={{borderRadius: '20px', boxShadow: '5px 15px 20px #0000001A', padding: '5em 0', marginBottom: '1em', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <img src={require('../assets/icon/reset-pass.png')} style={{width: '10%'}}/>
                                    {
                                        done ? (
                                            <>
                                                <h4 style={{fontWeight: 'bold', color: '#4A4A4A', margin: '1em 0 0 0'}}>Reset Sandi Berhasil</h4>
                                                <p style={{ color: '#969696', fontSize: '.9em'}}>Reset Sandi berhasil, silahkan masuk kembali.</p>
                                            </>
                                        ) : (
                                            <>
                                                <h4 style={{fontWeight: 'bold', color: '#4A4A4A', margin: '1em 0 0 0'}}>Reset Sandi</h4>
                                                <p style={{ color: '#969696', fontSize: '.9em'}}>Silahkan isi password baru untuk akun anda.</p>
                                                <div style={{ width: '450px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', border: '1px solid #EAEAEA', borderRadius: '5px', marginBottom: '1.5em'}}>
                                                    <input type={seePass ? 'text' : 'password'} placeholder='Masukkan Sandi baru Anda di sini' value={this.state.password} onChange={this.setPass} style={{ border: 0, backgroundColor: 'transparent', height: '2.2em', padding: '0 1em', width: '90%'}}/>
                                                    <button onClick={() => this.setState({seePass: !seePass})} style={{height: '2.2em', width: '10%', border: 0, backgroundColor: 'transparent'}}>
                                                        <img src={!seePass ? eye_slash : eye} style={{width: '60%'}}/>
                                                    </button>
                                                </div>
                                                <button onClick={this.changePass} style={{
                                                    width: '200px',
                                                    border: 0,
                                                    backgroundColor: '#FF5B00',
                                                    borderRadius: '5px',
                                                    textAlign: 'center',
                                                    padding: '.5em 0',
                                                    color: '#fff'
                                                }}>
                                                    Ganti Sandi
                                                </button>
                                                <p style={{color: 'red', fontSize: '.9em'}}>{message}</p>
                                                
                                            </>
                                        )
                                    }
                                </Col>
                            ) : (
                                <Col xs='12' style={{borderRadius: '20px', boxShadow: '5px 15px 20px #0000001A', padding: '5em 0', marginBottom: '1em', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <img src={require('../assets/icon/reset-pass.png')} style={{width: '10%'}}/>
                                    <h4 style={{fontWeight: 'bold', color: '#4A4A4A', margin: '1em 0 0 0'}}>Reset Sandi Gagal</h4>
                                    <p style={{ color: '#969696', fontSize: '.9em'}}>Kirim ulang permintaan untuk reset sandi anda.</p>
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

export default ResetPassword;