import React from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Nav from './components/nav'
import Footer from './components/footer.js'
import Dashboard from "./pages/index";
import Profile from "./pages/myprofile"
import ChangePass from './pages/change-password'
import Mycourse from './pages/my-course'
import VerifyAcc from './pages/verify-account'
import CourseDet from './pages/course-detail'
import ResetPass from './pages/reset-password'
import PaymentMethod from './pages/payment-methods'
import CourseEnr from './pages/course-enroll'
import Payment from './pages/payment-page'
import CourseList from './pages/courses-list'
import Page404 from './pages/404'
import ContactUs from './pages/contact-us'
import ProfileStat from './pages/profile-static'
import HistoryTransaction from './pages/history-transaction'
import HistoryDetail from './pages/history-detail'
import TransactionDone from './pages/transaction-done'
import SearchCourse from './pages/course-search'
import Home from './pages/home'

const routes = ({ Auth }) => {
  return (
    <div>
      <BrowserRouter>
        <div style={{minHeight: '100vh'}}>
          {/* <Nav/> */}
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/profile" component={ProfileStat} />
            <Route exact path="/profile/edit" component={Profile} />
            <Route exact path="/history-transaction" component={HistoryTransaction} />
            <Route exact path="/history-transaction/:id" component={HistoryDetail} />
            <Route exact path="/history-detail" component={HistoryDetail} />
            <Route exact path="/change-password" component={ChangePass} />
            <Route exact path="/payment-methods" component={PaymentMethod} />
            <Route exact path="/my-course" component={Mycourse} />
            <Route exact path="/verify-account" component={VerifyAcc}/>
            <Route exact path="/reset-password" component={ResetPass} />
            <Route exact path="/course/:slug" component={CourseDet}/>
            <Route exact path="/payment/:id" component={Payment}/>
            <Route exact path="/course-enroll/:slug" component={CourseEnr}/>
            <Route exact path="/course-list" component={CourseList}/>
            <Route exact path="/transaction-done/:slug" component={TransactionDone}/>
            <Route exact path="/course-search" component={SearchCourse} />
            <Route exact path="/demo" component={Home} />
            <Route component={Page404}/>
          </Switch>  
        </div> 
        <Footer/>
      </BrowserRouter>
    </div>
  );
};

const mapStateToProps = ({ Auth }) => ({ Auth });

export default connect(mapStateToProps)(routes);
