import React, { useState } from 'react' 
import { Container, Row, Col, Input, FormGroup, Label, Spinner } from 'reactstrap';

const Index =(props) => {
    const {questions,choose_answer} = props
    const [selected,setSelected] = useState(choose_answer && choose_answer.answers_id ? choose_answer.answers_id : null)  
    return (
        <>
        {
            questions.answers.map((ans, idx) => (
                <FormGroup
                    check
                    key={ans._id+'-'+idx}
                    className="option-group"
                >
                    <Label check style={{ width: '100%' }}>
                        <Input
                            onChange={()=>[setSelected(ans._id),props.answerQuiz(ans)]}
                            type="radio"  
                            checked={(choose_answer && choose_answer.answers_id == ans._id)|| (selected == ans._id)}
                        />
                        <div style={{ display: 'flex' }}>
                            {/* {answers[idx]}.{' '} */}
                            <p
                                style={{ marginLeft: '10px' }}
                                dangerouslySetInnerHTML={{
                                    __html:  ans.answer_option
                                }}
                            />
                        </div>
                    </Label>
                </FormGroup>
            ))
        }
        </>
    )
}



{/* <FormGroup
    check
    key={idx}
    className="option-group"
>
    <Label check style={{ width: '100%' }}>
        <Input
            type="radio"
            checked={
                (choose_answer && choose_answer.answers_id == ans._id)
                ?
                (
                    true
                ) : (
                    false
                )
            }
            onChange={() =>
                this.answerQuiz(ans)}
        />
        <div style={{ display: 'flex' }}>
            {answers[idx]}.{' '}
            <p
                style={{ marginLeft: '10px' }}
                dangerouslySetInnerHTML={{
                    __html: ans.answer_option
                }}
            />
        </div>
    </Label>
</FormGroup> */}
 

export default Index