import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Container, Row, Col, Card, Form, Image, Button} from 'react-bootstrap'
import { Input, FormGroup, Label } from 'reactstrap'

import Menu from '../components/Auth/menu'
import Loading from '../components/Modal/loading'
import numberWithComma from '../utils/numberWithComma'
import { getPaymentMaps, setPaymentMethod, getEWallet } from '../store/actions/payment'

let DIPAY_ID = process.env.REACT_APP_DIPAY_ID
let ENV = process.env.REACT_APP_ENV

class paymentMethods extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = { 
            activation: false,
            paymentMaps: [],
            balance: null,
            wallet: [],
            loading: false
         };
    }

    componentDidMount() {
        let { cookies, history } = this.props
        let cookie = cookies.getAll();
        if( cookie.hasOwnProperty('access_token')) {
            this.setState({loading: true})
            this.props.getPaymentMaps({Authorization: 'Bearer ' + cookie.access_token})
            .then(res => {
                this.setState({paymentMaps: res.data.data})
            })
        } else {
            history.push('/')
        }
        
    }

    render() {
        return (
            <React.Fragment>
                <Loading isOpen={this.state.loading}/>
                <div className='auth-background' style={{padding: '2% 15%'}}>
                    <Card style={{ borderRadius: '15px', padding: 0 }} className='bq-light'>
                        <Row style={{margin: 0}}>
                            <Col xs='4' style={{backgroundColor: '#EAEAEA', padding: 0, borderTopLeftRadius: '15px', borderBottomLeftRadius: '15px',}}>
                                <Menu/>
                            </Col>
                            <Col xs='8' style={{textAlign: 'left', padding: '2em'}}>
                                <h3 style={{ color: '#4A4A4A' }}>Metode Pembayaran</h3>
                                {/* <p style={{ fontSize: '14px', color: '#969696' }}>Update your payment method information</p> */}
                                <br/>
                                <Row style={{margin: 0}}>
                                    <Col xs='12' style={{float: 'left'}}>
                                        <FormGroup check style={{textAlign: 'left',  marginLeft: 'auto', marginRight: 'auto', fontSize: '.85em', marginBottom: '1.5em'}}>
                                            {/* <Label check style={{width: '100%'}}>
                                                <Input type="radio" checked={this.state.balance !== null ? true : false}/>
                                                <div style={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    justifyContent: 'space-between',
                                                    divgnItems: 'center'
                                                }}>
                                                    <div style={{width: '75%', paddingRight: '1em'}}>
                                                        <img src={require('../assets/icon/dipay.png')} style={{width: '25%'}}/>
                                                        <p style={{fontSize: '.9em', color: '#969696'}}>Aktifkan segera DIPAY, dapatkan Diskon dan penawaran menarik lainnya!</p>
                                                    </div>
                                                    <div style={{width: '25%', textAlign: 'right'}}>
                                                        {
                                                            this.state.balance !== null ? (
                                                                <h4>Rp {numberWithComma(this.state.balance.primary)}</h4>
                                                            ) : (
                                                                <button style={{
                                                                    backgroundColor: '#1b74f4',
                                                                    borderRadius: '5px',
                                                                    padding: '.7em',
                                                                    color:'#fff',
                                                                    fontWeight:'bold',
                                                                    textAlign: 'center',
                                                                    width: '100%',
                                                                    border: 0
                                                                }}
                                                                onClick={() => this.setState({activation: true})}>
                                                                    Aktivasi
                                                                </button>
                                                            )
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </Label> */}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </React.Fragment>
        );
    }
}


const WrappedCookies = withCookies(paymentMethods)

const mapStateToProps = state => {
  return {
    Chcekout: state.Checkout
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getPaymentMaps, setPaymentMethod, getEWallet }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedCookies);
