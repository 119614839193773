import React, { Component } from 'react'
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { bindActionCreators } from "redux";
import { instanceOf } from 'prop-types';
import swal from 'sweetalert'
import { withCookies, Cookies } from 'react-cookie';
import { Container, Row, Col, Progress } from 'reactstrap';

import numberWithComma from '../utils/numberWithComma'
import { getCourse } from '../store/actions/course'
import { getMyCourse } from '../store/actions/my-course'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../assets/style/pages/homepage.css';
import circle from './../assets/images/circle.png'
import chevronLeft from './../assets/icon/chevron-left.png'
import LandingNav from '../components/LandingNav'
import img55 from './../assets/images/55.png'
import img56 from './../assets/images/56.png'
import img57 from './../assets/images/57.png'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "#55CBCD", borderRadius: '50px' }}
            onClick={onClick}
        >
            {/* <img src={chevronLeft}/> */}
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "#55CBCD", borderRadius: '50px' }}
            onClick={onClick}
        />
    );
}

class index extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            myCourseLength: 0,
            courseLength: 0
        };
    }

    componentDidMount() {
        this.props.getCourse()
        .then(res => { 
            this.setState({courseLength: res.payload.course?.length})
            // console.log('++ response', res);
        })
        let {cookies, dispatch} = this.props
        let cookie = cookies.getAll();
        if( cookie.hasOwnProperty('access_token')) {
            this.props.getMyCourse({
                Authorization: `Bearer ${cookie.access_token}`
            })
            .then(res => {
                console.log(res);
                this.setState({myCourseLength: res.payload.course?.length})
            })
            .catch(err => {
                console.log(err);
                
            })
        }
        
    }

    componentDidUpdate(prevProps) {
        if(prevProps.MyCourse.data !== this.props.MyCourse.data) {
            if(this.props.MyCourse.data !== undefined) {
                this.setState({myCourseLength: this.props.MyCourse.data.length})
            }
            // console.log(this.props.MyCourse, 'berubah');
            
            
        }
    }

    makeSlug = (id, title) => {
        let titleArr = title.split(' ')
        let titleStr = titleArr.join('_')
        let slug = `${id}-${titleStr}`
        return slug
    }
    openCourse = () => {
            swal({
                title: "Coming soon",
                text: "Halaman sedang dalam pengerjaan",
                icon: "warning",
            })
        }
    render() {
        let { myCourseLength, courseLength } = this.state
        console.log(courseLength, 'state');
        
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: courseLength < 3 ? courseLength : 3,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
        };

        const TAsettings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };

        const secSettings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: courseLength < 3 ? courseLength : 3,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };

        const myCoursesettings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: myCourseLength < 3 ? myCourseLength : 3,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
        };

        let { Course, Auth, MyCourse, Profile } = this.props

        return (
            <>
                <LandingNav/>
                <React.Fragment>
                    <div className='banner-wrapper'>
                        <div className=''>
                            <Container style={{ textAlign: 'left' }}>
                                <Row>
                                    <Col sm="5 tagline-wrapper">
                                        <h2 className='text-greeting'>Welcome to Kerjah.com</h2>
                                        <p className='banner-subtitle'>Build in-demand tech skills with free access to expert-led courses, assessments and more. Upgrade below for full access!</p>
                                    </Col>
                                    <Col sm='7' style={{ position: 'relative' }}>
                                        <div class="element d-none d-xl-block" />
                                        <img src={circle} alt='circle' className="img-banner-circle" />
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>

                    <div style={{padding : 0}}>
                        <Container>
                            <Row> 
                                 <Col sm="6 tagline-wrapper" style={{padding : 20}}>
                                    <h2 className='text-greeting text-left'>Mudah dalam mengelola kursus</h2>
                                    <p className='banner-subtitle text-left'>Halaman admin yang mudah di gunakan untuk mengelola kursus dan trining video, di buat dengan antar muka yang sangat responsive</p>
                                </Col>
                                 <Col style={{padding : 0}}>
                                    <img src={img55} height={"100%"} width={"100%"} /> 
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className='primary-block-wrapper'  style={{padding : 0}}>
                        <Container>
                            <Row>
                                <Col style={{padding : 0}}>
                                    <img src={img56} height={"100%"} width={"100%"} /> 
                                </Col>
                                <Col sm="6 tagline-wrapper" style={{padding : 20}}>
                                    <h2 className='text-greeting text-right text-white'>Mobile Friendly</h2>
                                    <p className='banner-subtitle text-right text-white'>
                                        Peserta kursus dapat mengakses materi pelajaran dari berbagai jenis deivce dengan nyaman. Materi kursus yang di buat secara modular dan mencatat perkembangan proses belajar
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </div> 

                    <div style={{padding : 0}}>
                        <Container>
                            <Row>  
                                <Col sm="6 tagline-wrapper" style={{padding : 20}}>
                                    <h2 className='text-greeting text-left'>Mendukung Banyak Metode Pembayaran</h2>
                                    <p className='banner-subtitle text-left'>
                                        Mendukung hampir semua metode pembayaran untuk melakukan pembelian dalam website, Kartu kredit, Debit, Bank transfer atau melalui merchand kesayangan anda
                                    </p>
                                </Col>
                                <Col style={{padding : 0}}>
                                    <img src={img57} height={"100%"} width={"100%"} /> 
                                </Col> 
                            </Row>
                        </Container>
                    </div>

  
                    <div className="primary-block-wrapper" style={{marginTop : '-5px'}}>
                        <Container>
                            <Row>
                                <Col xs='12' sm="4" className='card-feature'>
                                    <h1>1</h1>
                                    <h2>Luangkan Waktumu</h2>
                                    <p>Kamu akan menerima jadwal dari team Kerjah.com untuk mengikuti pelatihannya</p>
                                </Col>
                                <Col xs='12' sm="4" className='card-feature'>
                                    <h1>2</h1>
                                    <h2>Belajar gratis dari ahlinya</h2>
                                    <p>Anda dapat mengakses berbagai materi pelatihan dalam bentuk video</p>
                                </Col>
                                <Col xs='12' sm="4" className='card-feature'>
                                    <h1>3</h1>
                                    <h2>Dapatkan sertifikatnya sebagai bukti kelulusan Anda</h2>
                                    <p>Jika Anda ingin mendapatkan sertifikat kelulusan Anda dapat mengikuti test ujian di akhir pelatihan</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            </>
        );
    }
}

const WrappedCookies = withCookies(index)

const mapStateToProps = state => {
  return {
    Auth: state.Auth,
    Course: state.Course,
    MyCourse: state.MyCourse,
    Profile: state.Profile
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getCourse, getMyCourse }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedCookies)
