import React, { Component } from 'react'
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { bindActionCreators } from "redux";
import { Container, Row, Col, Progress } from 'reactstrap';

import numberWithComma from '../utils/numberWithComma'
import { detailCourse } from '../store/actions/course'
import BeforeEnroll from '../components/Course-detail/before-enroll'
import Nav from '../components/nav'

class CourseDetail extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = { 
            curicull: [
                'Wilderness Backpacking',
                'Water Purifaction',
                'Fire Making',
                'Wilderness Shelter',
                'Using Cutting Tools like Knifes and Machetes ',
                'Backpacking Safety',
                'Wilderness Navigation with Map, Compass and GPS',
                'Planning and National Park Camping Permits',
                'Survival Gear including Day and Extended Back',
                'Outdoor Physical Fitness'
            ],
            details: {},
            enrollVisible: false
         };
    }

    componentDidMount() {
        const { match: { params }, cookies } = this.props;
        let id = params.slug.split('-')[0]
        let cookie = cookies.getAll()
        this.props.detailCourse(id, cookie.hasOwnProperty('access_token') ? {Authorization: 'Bearer ' +cookie.access_token} : {})
        .then(res => {
            this.setState({details: res.data.data.course})
        })
        .catch(err => {
            console.log(err);
        })
        
    }

    render() {
        let { curicull, details, enrollVisible } = this.state
        let { match: {params}} = this.props
        return (
            <>
                <Nav/>
                <React.Fragment>
                    <BeforeEnroll details={details} history={this.props.history} params={params}/>
                </React.Fragment>
            </>
        );
    }
}

const WrappedCookies = withCookies(CourseDetail)

const mapStateToProps = state => {
    return {
        Course: state.Course,
        Profile: state.Profile
    };
};

const mapDispatchToProps = dispatch =>
bindActionCreators({ detailCourse }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WrappedCookies)