import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label, Row, Col, Spinner } from 'reactstrap';

class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        let { isOpen, toggle } = this.props
        return (
            <Modal isOpen={isOpen} size='sm' style={{backgroundColor: 'transparent'}}>
                <ModalBody style={{backgroundColor: 'transparent',textAlign: 'center'}}>
                    <Spinner style={{ width: '10em', height: '10em' }} color='light'/>
                </ModalBody>
                <style jsx>{`
                .modal-content {
                    background-color: transparent;
                    border: 0
                }
                `}</style>
            </Modal>
        );
    }
}

export default Loading;