import swal from 'sweetalert';

export default (cookies) => {
    let cookie = cookies.getAll();
    if (cookie.hasOwnProperty('access_token')) {
        return cookie.access_token
    } else {
        swal({
            title: 'Please Login First',
            text: '',
            icon: 'warning'
        });
        return false
        
    }
}