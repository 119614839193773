import axios from "axios";

const api_url = process.env.REACT_APP_API_URL;

export const getVoucher = (courses_id, headers) => async dispatch => {
  try {
    const request = await axios.get(api_url + "/api/voucher?courses_id=" + courses_id, {
      headers
    });
    
    return request;
  } catch (err) {
    return err
  }
};

export const checkValidVoucher = (body, headers) => async (dispatch) => {
	try {
		const request = await axios.post(api_url + '/api/voucher/applied', body, {
			headers
		});

		return request;
	} catch (error) {}
};
