const defaultState = {
    isLoading: false,
    error: "",
  };
  
  const courseReducer = (state = defaultState, action) => {
    switch (action.type) {
      case "LOCATION_REQUEST":
        return {
          isLoading: true,
          authRequest: true
        };
  
      case "LOCATION_REQUEST_FAILED":
        return {
          isLoading: false,
          error: action.payload.message,
          ...state
        };
  
      case "PROVINCE_REQUEST_SUCCESS":
        return {
          isLoading: false,
          province: action.payload.data,
          ...state
        };

      case "CITY_REQUEST_SUCCESS":
        return {
            ...state,
            isLoading: false,
            ...action.payload,
            
        };
    case "DISTRICT_REQUEST_SUCCESS":
        return {
            isLoading: false,
            ...action.payload,
            ...state
        };
      case "AUTH_LOGOUT":
        return {
            defaultState
        };
      break;
      default:
        return state;
    }
  };
  
  export default courseReducer;
  