// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../images/background.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".auth-background {\n    background-color: #EDEDED;  \n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); \n    background-size: cover; \n    background-repeat: no-repeat\n}\n\n.spinner-btn {\n    width: 1em;\n    height: 1em;\n    margin-left: 5px\n}\n", "",{"version":3,"sources":["webpack://src/assets/style/pages/index.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,yDAAoD;IACpD,sBAAsB;IACtB;AACJ;;AAEA;IACI,UAAU;IACV,WAAW;IACX;AACJ","sourcesContent":[".auth-background {\n    background-color: #EDEDED;  \n    background-image: url('../../images/background.png'); \n    background-size: cover; \n    background-repeat: no-repeat\n}\n\n.spinner-btn {\n    width: 1em;\n    height: 1em;\n    margin-left: 5px\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
