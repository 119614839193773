import React, { Component } from 'react'
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { bindActionCreators } from "redux";
import { instanceOf } from 'prop-types';
import swal from 'sweetalert'
import { withCookies, Cookies } from 'react-cookie';
import { Container, Row, Col, Progress } from 'reactstrap';

import numberWithComma from '../../utils/numberWithComma'
import { getCourse } from '../../store/actions/course'
import { getMyCourse } from '../../store/actions/my-course'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/style/pages/homepage.css';
import circle from '../../assets/images/circle.png'
import Nav from '../../components/nav'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "#55CBCD", borderRadius: '50px' }}
            onClick={onClick}
        >
            {/* <img src={chevronLeft}/> */}
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "#55CBCD", borderRadius: '50px' }}
            onClick={onClick}
        />
    );
}

class index extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            myCourseLength: 0,
            courseLength: 0
        };
    }

    componentDidMount() {
        this.props.getCourse()
        .then(res => { 
            this.setState({courseLength: res.payload.course?.length})
            // console.log('++ response', res);
        })
        let {cookies, dispatch} = this.props
        let cookie = cookies.getAll();
        if( cookie.hasOwnProperty('access_token')) {
            this.props.getMyCourse({
                Authorization: `Bearer ${cookie.access_token}`
            })
            .then(res => {
                console.log(res);
                this.setState({myCourseLength: res.payload.course?.length})
            })
            .catch(err => {
                console.log(err);
                
            })
        }
        
    }

    componentDidUpdate(prevProps) {
        if(prevProps.MyCourse.data !== this.props.MyCourse.data) {
            if(this.props.MyCourse.data !== undefined) {
                this.setState({myCourseLength: this.props.MyCourse.data.length})
            }
            // console.log(this.props.MyCourse, 'berubah');
            
            
        }
    }

    makeSlug = (id, title) => {
        let titleArr = title.split(' ')
        let titleStr = titleArr.join('_')
        let slug = `${id}-${titleStr}`
        return slug
    }
    openCourse = () => {
            swal({
                title: "Coming soon",
                text: "Halaman sedang dalam pengerjaan",
                icon: "warning",
            })
        }
    render() {
        let { myCourseLength, courseLength } = this.state
        console.log(courseLength, 'state');
        
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: courseLength < 3 ? courseLength : 3,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
        };

        const TAsettings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };

        const secSettings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: courseLength < 3 ? courseLength : 3,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };

        const myCoursesettings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: myCourseLength < 3 ? myCourseLength : 3,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
        };

        let { Course, Auth, MyCourse, Profile } = this.props

        return (
            <>
            <Nav/>
            <React.Fragment>
                <div className='banner-wrapper'>
                    <div className=''>
                        <Container style={{ textAlign: 'left' }}>
                            <Row>
                                <Col sm="5 tagline-wrapper">
                                    <h2 className='text-greeting'>Welcome to Kerjah.com</h2>
                                    <p className='banner-subtitle'>Build in-demand tech skills with free access to expert-led courses, assessments and more. Upgrade below for full access!</p>
                                </Col>
                                <Col sm='7' style={{ position: 'relative' }}>
                                    <div class="element d-none d-xl-block" />
                                    <img src={circle} alt='circle' className="img-banner-circle" />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

                {
                    Auth.data ? null : (
                        <Container className='align-container d-none'>
                            <Row>
                                <Col xs="4">
                                    {/* <h4 className='text-bold'>Pigilearning terbuka untuk masyarakat umum, khususnya Digital Travelpreneur di seluruh Indonesia</h4> */}
                                    {/* <p className='text-grey'>Daftar dan buat akun Digital Travelpreneur kamu di Pigijo.com, ikuti pelatihannya dan dapatkan sertifikat setelah lulus. Sertifikat kelulusan dari kami akan membantumu dalam meningkatkan skill dan mendapatkan penghasilan.</p> */}
                                </Col>

                                <Col xs="8" style={{ paddingLeft: '3em' }}>
                                    <div>
                                        <Slider {...secSettings}>
                                            {
                                                Course.courses ? (
                                                    Course.courses.map((course, idx) => (
                                                        <div key={idx}>
                                                            <Link to={`/course/${this.makeSlug(course._id, course.course_title)}`}>
                                                                <div className='card-medium'>
                                                                    <img src={course.course_image_medium} className='img-card-medium'/>
                                                                    <div className='card-medium-content'>
                                                                        <div style={{height: '3em'}}>
                                                                            <span className='text-bold text-grey'>{course.course_title}</span>
                                                                        </div>
                                                                        
                                                                        <p className='card-little-text'>by {course.authors_id && `${course.authors_id.details.firstname} ${course.authors_id.details.lastname}`}</p>

                                                                        <span className='card-little-text'>{course.is_free_access ? 'Free' : 'Rp ' + numberWithComma(course.course_price)}</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    ))
                                                ) : null
                                            }

                                        </Slider>
                                    </div>


                                </Col>
                            </Row>

                        </Container>
                    )
                }
                

                { 
                        Auth.data && MyCourse.data && MyCourse.data.length > 0  &&
                            <Container key={Math.random(1000)} className='align-container'>
                                <h4 className='text-bold'>
                                    Mari mulai belajar, {Profile.data ? ( Profile.data.details.firstname + ' ' + Profile.data.details.lastname ) : null}</h4>
                                <div className='mt-2 slider-wrapper' style={{width: '100%'}}>
                                        <div className='row' {...settings}>
                                            { 
                                                MyCourse.data.map((course, idx) => {
                                                    if(!course.courses_id || idx > 4) return <></>
                                                    return( 
                                                        <>
                                                         <Link  to={`/course-enroll/${this.makeSlug(course.courses_id._id, course.courses_id.course_title)}`} key={idx} className='col-md-4 mb-4'>
                                                            <div className="d-flex course-card">
                                                                <div>
                                                                    <img alt='course' className='img-card-medium' src={course.courses_id.course_image_medium} />
                                                                </div>
                                                                <div>
                                                                    <p className="text-bold">{course.courses_id.course_title}</p>
                                                                    <p className='card-little-text mb-0'>by {course.courses_id.authors_id && `${course.courses_id.authors_id.details.firstname} ${course.courses_id.authors_id.details.lastname}`}</p>                                                                       
                                                                </div>
                                                            </div>
                                                        </Link> 
                                                        </>
                                                    )
                                                }) 
                                            }
                                        </div>    
                                </div>
                                
                            </Container> 
                }


                <div className='container-wheat'>
                    <Container className='align-container container-wheat'>
                        <h4 className='text-bold'>Apa yang akan dipelajari berikutnya</h4>
                        <div className='d-flex justify-content-between'>
                            <p className='text-grey'>Direkomendasikan untuk Anda</p>
                            <Link className='text-grey' to={`/course-search`}>lebih banyak pelajaran &nbsp;
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                    </svg>
                                </span>
                            </Link>
                        </div>
                        <div className='slider-wrapper' style={{width: courseLength < 4 ? `${courseLength*25}%` : '100%'}}>
                            <Slider {...settings}>
                                {
                                    Course.courses ? (
                                        Course.courses.map((course, idx) => {
                                            if(!course.is_purchase && idx < 5){
                                                return(
                                                    <div key={idx}>
                                                        {/* {console.log('++', Course)} */}
                                                        <Link to={`/course/${this.makeSlug(course._id, course.course_title)}`}>
                                                            <div className="d-flex course-card">
                                                                <div>
                                                                    <img alt='course' className='img-card-medium' src={course.course_image_medium} />
                                                                </div>
                                                                <div>
                                                                    <p className="text-bold">{course.course_title}</p>
                                                                    <p className='card-little-text mb-0'>by {course.authors_id && `${course.authors_id.details.firstname} ${course.authors_id.details.lastname}`}</p>
                                                                    <p className="mb-0">{course.is_free_access ? 'Free' : 'Rp ' + numberWithComma(course.course_price)}</p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                )
                                            }
                                        })
                                    ) : null
                                    
                                }
                            </Slider>
                        </div>
                        
                    </Container>
                </div>
                
                <div className="primary-block-wrapper">
                    <Container>
                        <Row>
                            <Col xs='12' sm="4" className='card-feature'>
                                <h1>1</h1>
                                <h2>Luangkan Waktumu</h2>
                                <p>Kamu akan menerima jadwal dari team Kerjah.com untuk mengikuti pelatihannya</p>
                            </Col>
                            <Col xs='12' sm="4" className='card-feature'>
                                <h1>2</h1>
                                <h2>Belajar gratis dari ahlinya</h2>
                                <p>Anda dapat mengakses berbagai materi pelatihan dalam bentuk video</p>
                            </Col>
                            <Col xs='12' sm="4" className='card-feature'>
                                <h1>3</h1>
                                <h2>Dapatkan sertifikatnya sebagai bukti kelulusan Anda</h2>
                                <p>Jika Anda ingin mendapatkan sertifikat kelulusan Anda dapat mengikuti test ujian di akhir pelatihan</p>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </React.Fragment>
        </>
        );
    }
}

const WrappedCookies = withCookies(index)

const mapStateToProps = state => {
  return {
    Auth: state.Auth,
    Course: state.Course,
    MyCourse: state.MyCourse,
    Profile: state.Profile
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getCourse, getMyCourse }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedCookies)
