import React from 'react';
import logo from './logo.svg';
import './App.css';
import './assets/style/base.css'
import './assets/style/index.css'
import 'react-loading-skeleton/dist/skeleton.css'

import Routes from './routes'

function App() {
  return (
    <div className="App">
      <Routes/>
      <style jsx='true' global='true'>{`
        .pagination-nav {
          margin-top: 3em;
          display: flex;
          align-items: center;
        }
        .pagination {
          display: block;
          padding: 0;
          margin: 0 0 0 auto;
          border-radius: 0;
        }
        .pagination li {
          display: inline-block;
        }
        .pagination li + li {
          margin-left: 0.25em;
        }
        .pagination li a {
          position: relative;
          float: none;
          display: block;
          font-size: 0.86em;
          font-weight: 600;
          padding: 0;
          margin-left: 0;
          width: 36px;
          height: 36px;
          line-height: 36px;
          border-radius: 50% !important;
          text-align: center;
          color: inherit;
          background-color: transparent;
          border: none;
        }
        .pagination li a:focus {
          color: #2b2826;
          background-color: transparent;
        }
        .pagination li a:hover {
          color: #2b2826;
          background-color: transparent;
        }
        .pagination li:first-child a {
          margin-left: 0;
          border-radius: 0;
        }
        .pagination li:last-child a {
          margin-left: 0;
          border-radius: 0;
        }
        .pagination .active a {
          background-color: #fff;
          box-shadow: 0 0 24px rgba(43, 40, 38, .2);
          color: #2b2826;
        }
        .pagination .active a:focus {
          background-color: #fff;
          box-shadow: 0 0 24px rgba(43, 40, 38, .2);
          color: #2b2826;
        }
        .pagination .active a:hover {
          background-color: #fff;
          box-shadow: 0 0 24px rgba(43, 40, 38, .2);
          color: #2b2826;
        }
      `}</style>
    </div>
  );
}

export default App;
