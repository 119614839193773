
import {apiCall} from '../../repositories/Repository'; 

class HttpCall {

    constructor(callback) {
        this.callback = callback;
    }  

    async httpSetModuleFinish(payload) { 

        const dataReq = {
            method: "POST",
            url: `/api/module/change-status`, 
            data : {
                data : payload
            }
          }; 
        
        const call = await (apiCall(dataReq)).then((success) => {return {success}})
                                             .catch((error) => {return {error}})
        return call;
    } 
}



export default new HttpCall();
