import React, {
    Component
} from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Input, Modal } from 'reactstrap'
import { Container, Row, Col, Card, Button} from 'react-bootstrap'
import swal from 'sweetalert'

import eye from '../assets/icon/eye.png'
import Menu from '../components/Auth/menu'
import Loading from '../components/Modal/loading'
import { authLogout } from '../store/actions/auth'
import eye_slash from '../assets/icon/eye-slash.png'
import { getProfile, changePassword } from '../store/actions/profile'
import Nav from '../components/nav'

class ChangePassword extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            oldPass: '',
            newPass: '',
            confNewPass: '',
            error: null,
            difNewPass: false,
            success: false,
            seeOldPass: false,
            seeNewPass: false,
            seeConfNewPass: false,
            loading: false
        };
    }

    componentDidMount() {
        let {cookies, history} = this.props
        let cookie = cookies.getAll();
        if( !cookie.hasOwnProperty('access_token')) {
            history.push('/')
        }
    }

    onChangeInput = event => {
        // console.log(event);
        
        this.setState({
          [event.target.name]: event.target.value,
        });
    };

    changingPass = event => {
        event.preventDefault()

        let {oldPass, newPass, confNewPass} = this.state
        let { history, cookies, Profile} = this.props
        let cookie = cookies.getAll()
        
        if(newPass === confNewPass) {
            swal({
                title: "Are you sure?",
                text: "Your password will be changed",
                buttons: {
                    cancel: 'Cancel',
                    proceed: true
                }
            })
            .then((value) => {
                if(value === "proceed") {
                    this.setState({loading: true})
                    let body = {
                        users_id: Profile.data._id,
                        old_password: oldPass,
                        new_password: newPass
                    }
                    this.props.changePassword(cookie.access_token, body)
                    .then(result => {
                        console.log(result);
                        this.setState({loading: false})
                        if(result.data) {
                            swal({
                                title: "Password sudah berhasil dirubah!",
                                text: "Silahkan masuk menggunakan password baru anda",
                                icon: 'success'
                            })
                            .then(value => {
                                this.props.authLogout(cookie.access_token)
                                .then(res => {
                                    console.log(res);
                                    
                                    cookies.remove('access_token')
                                    this.setState({success: true}, () => {
                                        history.push('/')
                                    })
                                })
                                .catch(err => {
                                    console.log(err);
                                    
                                })
                            })
                            
                        } else {
                            swal({
                                title: "Error",
                                text: result.response.data.data,
                                icon: "error",
                            })
                        }
                    })
                    .catch(err => {
                        this.setState({
                            error: true
                        })
                    })
                }
            })
            
            
        } else {
            this.setState({
                difNewPass: true
            })
        }
    }
    
    render() {
        let { oldPass, newPass, confNewPass, success, error, difNewPass, seeOldPass, seeNewPass, seeConfNewPass} = this.state
        return (
        <>
            <Nav/>
            <React.Fragment >
                <Loading isOpen={this.state.loading}/>
                <Modal isOpen={success} toggle={() => this.setState({success: !success})} size='sm'>
                    <div style={{textAlign: 'center', padding: '2em'}}>
                        Password sudah berhasil dirubah!<br/>
                        Silahkan masuk menggunakan password baru anda
                    </div>
                </Modal>
                <Container>
                    <Row>
                        <Col sm='12'>
                            <div style={{ backgroundColor: '#EDEDED', backgroundImage: `url(${require('../assets/images/background.png')})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                                <Card style={{ borderRadius: '15px' }} className='bq-light'>
                                    <Row style={{margin: 0}}>
                                        <Menu/>
                                        <Col sm='8' className="text-left pt-3">
                                            <h3 style={{ color: '#4A4A4A' }}>Ubah Sandi</h3>
                                            {
                                                error ? (
                                                    <p style={{color: 'red'}}>Gagal ganti password</p>
                                                ) : null
                                            }
                                            <form onSubmit={this.changingPass}>
                                                <Row style={{ marginBottom: '20px' }}>
                                                    <Col lg="3"><span style={{ fontSize: '14px', color: '#4A4A4A' }}>Sandi</span></Col>
                                                    <Col lg="9">
                                                        <div className='change-password-input-wrapper'>
                                                            <Input 
                                                            type={seeOldPass ? 'text' : 'password'} 
                                                            name='oldPass'
                                                            value={oldPass}
                                                            onChange={this.onChangeInput}
                                                            style={{ 
                                                                width: '90%',
                                                                padding: '.2em 1em', 
                                                                border: 0}}/>
                                                            <img src={!seeOldPass ? eye_slash : eye} style={{width: '18px'}} onClick={() => this.setState({seeOldPass: !seeOldPass})}/>
                                                        </div>
                                                    </Col>
                                                </Row>  
                                                <Row style={{ marginBottom: '20px' }}>
                                                    <Col lg="3"><span style={{ fontSize: '14px', color: '#4A4A4A' }}>Sandi Baru</span></Col>
                                                    <Col lg="9">
                                                        <div className='change-password-input-wrapper'>
                                                            <Input 
                                                            type={seeNewPass ? 'text' : 'password'}
                                                            name='newPass'
                                                            value={newPass}
                                                            onChange={this.onChangeInput}
                                                            style={{ 
                                                                width: '90%',
                                                                padding: '.2em 1em', 
                                                                border: 0}}/>
                                                            <img src={!seeNewPass ? eye_slash : eye} style={{width: '18px'}} onClick={() => this.setState({seeNewPass: !seeNewPass})}/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: '20px' }}>
                                                    <Col lg="3"><span style={{ fontSize: '14px', color: '#4A4A4A' }}>Ulang Sandi Baru</span></Col>
                                                    <Col lg="9">
                                                    <div className='change-password-input-wrapper'>
                                                        <Input 
                                                        type={seeConfNewPass ? 'text' : 'password'}
                                                        name='confNewPass'
                                                        value={confNewPass}
                                                        onChange={this.onChangeInput}
                                                        style={{ 
                                                            width: '90%',
                                                            padding: '.2em 1em', 
                                                            border: 0}}/>
                                                        <img src={!seeConfNewPass ? eye_slash : eye} style={{width: '18px'}} onClick={() => this.setState({seeConfNewPass: !seeConfNewPass})}/>
                                                    </div>
                                                        {
                                                            difNewPass ? (
                                                                <p style={{fontSize: '.9em', color: 'red'}}>Silahkan masukan password yang sama untuk konfirmasi</p>
                                                            ) : null
                                                        }
                                                        
                                                    </Col>
                                                </Row>
                                                <Row className='my-3'>
                                                    <Col sm='3'></Col>
                                                    <Col lg="6" style={{ textAlign: 'left' }}>
                                                        <Button type="submit" style={{ borderRadius: '5px', width: '100%', backgroundColor: '#009194', border: 'none', fontSize: '.9em', color: '#fff', fontWeight: 'bold' }}>Ubah Sandi</Button>
                                                    </Col>
                                                    <Col sm='3'></Col>
                                                </Row>
                                            </form>
                                            
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <style jsx>{`
                    ::-webkit-input-placeholder {
                        font-size: 13px!important;
                    }
                    
                    :-moz-placeholder { /* Firefox 18- */
                        font-size: 13px!important;
                    }
                    ::-moz-placeholder {  /* Firefox 19+ */
                        font-size: 13px!important;
                    }
                `}</style>
            </React.Fragment>
        </>
        );
        
    }
}

const WrappedCookies = withCookies(ChangePassword)

const mapStateToProps = state => {
  return {
    Auth: state.Auth,
    Profile: state.Profile
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getProfile, authLogout, changePassword }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedCookies);
