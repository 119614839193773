import axios from "axios";

const api_url = process.env.REACT_APP_API_URL;

export const doAuth = reqBody => async dispatch => {
  dispatch(authOnRequest());

  try {
    const request = await axios.post(api_url + "/api/login", reqBody);
    // const stringResult = JSON.stringify(request.data);
    // console.log(request);
    
    return dispatch(authRequestSuccess(request.data));
  } catch (err) {
    return dispatch(authRequestFailed(err));
  }
};

export const signUp = reqBody => async dispatch => {
    dispatch(registOnRequest());
  
    try {
      const request = await axios.post(api_url + "/api/registration", reqBody);
      // const stringResult = JSON.stringify(request.data);
      // console.log(request);
      
      return dispatch(registRequestSuccess(request.data));
    } catch (err) {
      return dispatch(registRequestFailed(err));
    }
  };

export const setAuth = body => async dispatch => {
    dispatch(authRequestSuccess(body));
}

export const authLocalJWT = loginData => async dispatch => {
  dispatch(authOnRequest());
  try {
    if (loginData.token === undefined) {
      throw new Error("No token in local storage");
    }
    return dispatch(authRequestSuccess(loginData));
  } catch (err) {
    return dispatch(authRequestFailed(err));
  }
};

export const authLogout = (token) => async dispatch => {
  try {
    const request = await axios.post(api_url + "/api/logout", {}, {headers: {
        Authorization: `Bearer ${token}`
    }});
    
    return dispatch({
        type: "AUTH_LOGOUT",
    });
  } catch (err) {
    return dispatch({
        type: "AUTH_LOGOUT",
    });
  }
};

export const authOnRequest = () => {
  return {
    type: "AUTH_REQUEST",
  };
};

export const authRequestFailed = err => ({
  type: "AUTH_REQUEST_FAILED",
  payload: err,
});

export const authRequestSuccess = payload => ({
  type: "AUTH_REQUEST_SUCCESS",
  payload,
});

export const registOnRequest = () => {
    return {
    type: "REGIST_REQUEST",
    };
};
  
export const registRequestFailed = err => ({
    type: "REGIST_REQUEST_FAILED",
    payload: err,
});

export const registRequestSuccess = payload => ({
    type: "REGIST_REQUEST_SUCCESS",
    payload,
});