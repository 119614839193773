const defaultState = {
    isLoading: false,
    error: "",
  };
  
  const courseReducer = (state = defaultState, action) => {
    switch (action.type) {
      case "COURSE_REQUEST":
        return {
          isLoading: false,
          authRequest: true
        };
  
      case "COURSE_REQUEST_FAILED":
        return {
          isLoading: false,
          error: action.payload.message
        };
  
      case "COURSE_REQUEST_SUCCESS":
        return {
          isLoading: true,
          courses: action.payload.course,
          ...action.payload
        };
      break;
      case "AUTH_LOGOUT":
        return {
            defaultState
        };
      break;
      default:
        return state;
    }
  };
  
  export default courseReducer;
  