import React, { Component } from 'react'
import axios from 'axios'
import { Container, Row, Col, Input, FormGroup, Label, Spinner } from 'reactstrap';
import { Form } from 'react-bootstrap'
import swal from 'sweetalert';
import Loading from '../components/Modal/loading'
import whatsapp from '../assets/icon/wa-grey.png'
import Nav from '../components/nav'

let API_URL = process.env.REACT_APP_API_URL

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            category: [],
            nama: '',
            email: '',
            phone: '',
            kategori: '',
            subject: '',
            pesan: '',
            loading: false
         };
    }

    componentDidMount() {
        this.setState({loading: true})
        axios.get(API_URL + '/api/categories-ticket')
        .then(res => {
            this.setState({category: res.data.data, loading: false})
        })
        .catch(err => {
            this.setState({loading: false})
        })
    }

    sendMessage = async e => {
        e.preventDefault()
        let { nama, email, phone, kategori, subject, pesan} = this.state
        this.setState({loading: true})
        if(nama !== '' && email !== '' && phone !== '' && kategori !== '' && subject !== '' && pesan !== '') {
            await axios.post(API_URL + '/api/send-ticket', {
                name: nama,
                email,
                phone,
                subject,
                support_category_id: kategori,
                message: pesan
            })
            .then(res => {
                swal({
                    title: 'Sukses',
                    text: 'Pertanyaan anda berhasil di submit, silahkan tunggu email jawaban dari admin kami',
                    icon: 'success'
                })
                this.setState({loading: false})
            })
            .catch(err => {
                swal({
                    title: 'Error',
                    text: 'Periksa kembali jaringan internet anda dan coba beberapa saat lagi',
                    icon: 'error'
                })
                this.setState({loading: false})
            })
        } else {
            swal({
                title: 'Please fill all the field below',
                icon: 'warning'
            })
        }

        this.setState({loading: false})
        
    }
    render() {
        let {category, nama, email, phone, subject, kategori, pesan } = this.state
        
        return (
            <>
                <Nav/>
                <React.Fragment>
                    <Loading isOpen={this.state.loading}/>
                    <img className="d-none" alt="banner" src={require('../assets/images/contact-banner.png')} style={{width: '100%'}}/>
                    <Container>
                        <Row>
                            <Col xs='12' className="mb-4">
                                <Row style={{margin: 0}}>
                                    <Col sm='3' className="tab-contact py-3 text-center has-border-green-bottom">
                                        <span>Kontak Kami</span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm='8' className="text-left">
                                <h5 className="text-dark">Kontak Kami</h5>
                                <p className="text-gray">Apabila terdapat pertanyaan seputar Kerjah.com atau kerjasama dan hal lainnya, isi formulir di bawah ini.</p>

                                <br />
                                <form onSubmit={this.sendMessage} className="contact-form">
                                    <Row className="mb-3">
                                        <Col lg="3"><label>Nama <span className="text-danger">*</span></label></Col>
                                        <Col lg="7">
                                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', border: '1px solid #EAEAEA', borderRadius: '5px', marginBottom: '1.5em'}}>
                                                <Input 
                                                type={'text'} 
                                                name='nama'
                                                value={nama}
                                                onChange={e => this.setState({nama: e.target.value})}
                                                />
                                            </div>
                                        </Col>
                                    </Row>  
                                    <Row className="mb-3">
                                        <Col lg="3"><label>Email <span className="text-danger">*</span></label></Col>
                                        <Col lg="7">
                                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', border: '1px solid #EAEAEA', borderRadius: '5px', marginBottom: '1.5em'}}>
                                                <Input 
                                                type={'text'} 
                                                name='email'
                                                value={email}
                                                onChange={e => this.setState({email: e.target.value})}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col lg="3"><label>Nomor Telepon <span className="text-danger">*</span></label></Col>
                                        <Col lg="7">
                                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', border: '1px solid #EAEAEA', borderRadius: '5px', marginBottom: '1.5em'}}>
                                                <Input 
                                                type={'text'} 
                                                name='phone'
                                                value={phone}
                                                onChange={e => this.setState({phone: e.target.value})}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col lg="3"><label>Kategori <span className="text-danger">*</span></label></Col>
                                        <Col lg="7">
                                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', border: '1px solid #EAEAEA', borderRadius: '5px', marginBottom: '1.5em'}}>
                                            <Input 
                                                name='kategori'
                                                type='select'
                                                value={kategori}
                                                onChange={e => this.setState({kategori: e.target.value})}
                                                style={{ 
                                                    width: '100%',
                                                    padding: '.2em 1em', 
                                                    border: 0,
                                                    textAlign: 'left'
                                            }}>
                                                <option>Kategori</option>
                                                {
                                                    category.map((cat,idx) => (
                                                        <option value={cat._id}>{cat.name}</option>
                                                    ))
                                                }
                                                
                                                
                                            </Input>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col lg="3"><label>Subjek Pesan <span className="text-danger">*</span></label></Col>
                                        <Col lg="7">
                                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', border: '1px solid #EAEAEA', borderRadius: '5px', marginBottom: '1.5em'}}>
                                                <Input 
                                                type={'text'} 
                                                name='subjek'
                                                value={subject}
                                                onChange={e => this.setState({subject: e.target.value})}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col lg="3"><label>Pesan / Pertanyaan <span className="text-danger">*</span></label></Col>
                                        <Col lg="7">
                                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', border: '1px solid #EAEAEA', borderRadius: '5px', marginBottom: '1.5em'}}>
                                            <Form.Control 
                                                as="textarea" 
                                                name='pesan' 
                                                rows="6" 
                                                value={pesan}
                                                onChange={e => this.setState({pesan: e.target.value})}
                                                placeholder="Describe yourself" 
                                                style={{ 
                                                    width: '100%',
                                                    padding: '.2em 1em', 
                                                    border: 0}}
                                            />
                                                
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col lg="7"></Col>
                                        <Col lg="3">
                                        <button type='submit' style={{
                                            borderRadius: '3px',
                                            border: 0,
                                            textAlign: 'center',
                                            padding: '.5em 0',
                                            backgroundColor: '#009194',
                                            width: '100%',
                                            color: '#fff',
                                            marginBottom: '40px'
                                        }}>
                                            Kirim
                                        </button>
                                        </Col>
                                    </Row>
                                </form>
                            </Col>
                            <Col sm='4'>
                                <div style={{marginBottom: '2.5em'}}>
                                    <div style={{
                                        padding: '.5em 2em',
                                        backgroundColor: '#009194',
                                        color: '#fff',
                                        fontWeight: 'bold',
                                        textAlign: 'left'
                                    }}>
                                        Kontak Kami
                                    </div>
                                    <div style={{
                                        padding: '1.5em 2em',
                                        backgroundColor: '#F7F7F7',
                                        color: '#969696',
                                        textAlign: 'left',
                                        fontSize: '.9em'
                                    }}>
                                        <p>Jam Kerja</p>

                                        <div style={{marginBottom: '1.2em'}}>
                                            <p>09.00 - 17.00 WIB (Weekdays)</p>
                                            <span>10.00 - 15.00 WIB (Weekend)</span>
                                        </div>
                                        <p>Diluar jam kerja diatas, Anda dapat menghubungi kami melalu e-mail. Kami akan segera menghubungi anda kembali.</p>
                                        <hr/>

                                        {/* <span style={{verticalAlign: 'middle'}}><img src={whatsapp} style={{width: '5%', marginRight: '15px'}}/> +62 813-1775-5528 (WhatsApp)</span> */}
                                    </div>

                                </div>

                                <div style={{marginBottom: '2.5em'}}>
                                    <div style={{
                                        padding: '.5em 2em',
                                        backgroundColor: '#009194',
                                        color: '#fff',
                                        fontWeight: 'bold',
                                        textAlign: 'left'
                                    }}>
                                        Alamat
                                    </div>
                                    <div style={{
                                        padding: '1.5em 2em',
                                        backgroundColor: '#F7F7F7',
                                        color: '#969696',
                                        textAlign: 'left',
                                        fontSize: '.9em'
                                    }}>
                                        <p>Office : </p>
                                        <address>
                                            PT. Wilsum Kapital Global<br />
                                            Office Park Thamrin City<br /> 
                                            Jl. Thamrin Boulevard <br />
                                            Blok AB No. 09<br />
                                            Kebon Melati, Tanah Abang<br />
                                            Jakarta Pusat, DKI Jakarta
                                        </address>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </React.Fragment>
            </>
        );
    }
}

export default ContactUs;