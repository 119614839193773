import React, { useState } from 'react'
import { Image, Accordion, Card, Form } from 'react-bootstrap'
import ChevronUp from '../../../assets/icon/chevron-up-black.png'
import { Container, Row, Col, Input, FormGroup, Label, Spinner } from 'reactstrap'
import HttpModules from '../../../stores/modules/httpCall'

const ModuleItem =(props) => {
    const {data,id,details,activeModule} = props 
    const sec = data
    const [isFinished,setFinish] = useState()
    
    return (
        <Card style={{ border: 0, borderRadius: 0 }}>
            <Card.Header style={{ backgroundColor: '#CAEFF0', border: 0 }}>
                <Accordion.Toggle
                    variant="link"
                    eventKey={sec._id}
                    className="section-box"
                >
                    <div>
                        <p className="text-bold" style={{ fontSize: '.9em' }}>
                            Bagian {sec.section_order} : {sec.section_name}{' '} 
                            {!sec.is_free_access && '(Berbayar)'}
                        </p>
                        <span style={{ fontSize: '.85em' }}>
                            {id + 1} / {details.sections.length}
                        </span>
                    </div>
                    <img
                        src={ChevronUp}
                        style={{ width: '5%' }}
                    />
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={sec._id}>
                <Card.Body
                    style={{
                        backgroundColor: '#F7F7F7',
                        textAlign: 'left'
                    }}
                >
                    {sec.modules ? (
                        sec.modules.map((mod, idx) => (
                           <SectionModule detail={mod.details[0]} isActive={activeModule.modules_id == mod._id} sec={sec} id={id} mod={mod} {...props} key={idx} idx={idx} />
                        ))
                    ) : null}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

const SectionModule = (props) => {
    const {idx,mod,id,sec,isActive,detail} = props  
    return(
        <FormGroup
            key={idx}
            check
            style={{
                textAlign: 'left',
                fontSize: '.85em',
                marginBottom: '1.5em'
            }}
        >
            <Label check style={{ width: '100%' }}>
                <Input
                    // onChange={(datas) => httpCallFinishSection()}
                    type="checkbox"
                    checked={mod.is_finish}
                    disabled={true}
                />
                <div
                    style={{
                        textAlign: 'left',
                        marginLeft: '5px'
                    }}
                    onClick={() =>
                        props.getModules(
                            mod._id,
                            idx,
                            id,
                            sec._id,
                            detail
                        )}
                >
                    <p
                        style={{
                            fontSize: '1em',
                            marginBottom: 0,
                            fontWeight : isActive ? 400 : 200,
                            color:
                                mod.is_finish == true
                                    ? '#4A4A4A'
                                    : isActive ? '#019194' : 'grey'
                        }}
                    >
                        {mod.module_order}. {mod.module_name} 
                    </p>
                    {/* <span style={{color: '#969696'}}>4min</span> */}
                </div>
            </Label>
        </FormGroup>
    )
}

export default ModuleItem